// import Container from "react-bootstrap/Container";
import * as React from "react";
import {useState} from "react";

function FAQItem({ question, answer, isOpen, onClick }) {
    return (
        <div>
            <button
                className="flex items-center focus:outline-none"
                onClick={onClick}
            >
                <svg
                    className={`flex-shrink-0 w-6 h-6 text-teal-700 transition-transform transform ${
                        isOpen ? "rotate-180" : ""
                    }`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d={isOpen ? "M20 12H4" : "M12 4v16m8-8H4"}
                    />
                </svg>
                <h1 className="mx-4 text-xl text-left text-black dark:text-white">{question}</h1>
            </button>
            <div
                className={`overflow-hidden transition-all duration-300 ${
                    isOpen ? "max-h-40" : "max-h-0"
                }`}
            >
                <div className="flex mt-8 md:mx-10">
                    <span className="border border-teal-700"></span>
                    <p className="max-w-3xl px-4 text-gray-800 dark:text-gray-800">
                        {answer}
                    </p>
                </div>
            </div>
        </div>
    );
}

const faqs = [
    {
        question: "How do I get started with OneScript?",
        answer:
            "To get started with OneScript, simply fill out our user-friendly web form with your details and symptoms. " +
            "Our healthcare professionals will review your information and provide a prescription tailored to your needs.",
    },
    {
        question: "What kind of pain products can I buy from OneScript?",
        answer:
            "OneScript offers a variety of pain management products, including pain creams, gels, and patches. " +
            "All products are carefully selected to provide effective relief and are available for purchase once you have a prescription.",
    },
    {
        question: "How long does it take to receive my prescription?",
        answer:
            "After you submit the web form, our healthcare professionals will review your information and typically provide a prescription within 24 hours. " +
            "You will be notified via email once your prescription is ready.",
    },
    {
        question: "Is there a consultation fee?",
        answer:
            "No, there is no consultation fee for our services. We believe in making healthcare accessible and affordable for everyone.",
      },
      {
        question: "Can I use my insurance to pay for OneScript services?",
        answer:
            "Currently, OneScript operates on a pay-and-submit model. However, we are actively working on implementing direct insurance billing " +
            "with several insurance partners. Please check with your insurance provider regarding coverage details and reimbursement options. " +
            "We're committed to making our services more accessible through insurance partnerships.",
      },
    {
        question: "How will I receive my pain management products?",
        answer:
            "Once you have a prescription, you can purchase pain management products directly from OneScript. " +
            "Your products will be shipped to your doorstep within a few business days.",
    },
    {
        question: "Is a referral required to use OneScript?",
        answer:
            "No referral is required to use OneScript. You can directly fill out the web form and receive a prescription from our healthcare professionals.",
    },
    {
        question: "Can I get an old prescription refilled, renewed, or modified?",
        answer:
            "Yes, you can. Simply fill out the web form with the details of your previous prescription, " +
            "and our healthcare professionals will review your request and provide the necessary adjustments.",
    },
];

function FAQSection() {
    const [openIndex, setOpenIndex] = useState(null);
 


    const handleClick = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <section className="bg-gray-50 ">
            <div className="container px-6 py-10 mx-auto">
                <h1 className="text-2xl font-semibold text-black lg:text-3xl dark:text-white">
                    You Have Questions. We Have Answers
                </h1>
                <hr className="my-6 border-gray-300 dark:border-gray-700"/>
                <div>
                    {faqs.map((faq, index) => (
                        <div key={index}>
                            <FAQItem
                                question={faq.question}
                                answer={faq.answer}
                                isOpen={openIndex === index}
                                onClick={() => handleClick(index)}
                            />
                            <hr className="my-8 border-gray-300 dark:border-gray-700"/>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

function HomePage (props) {
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
    const [hoveredCard, setHoveredCard] = useState(null);

    const handleMouseMove = (e, cardElement) => {
        const rect = cardElement.getBoundingClientRect();
        const x = ((e.clientX - rect.left) / rect.width) * 100;
        const y = ((e.clientY - rect.top) / rect.height) * 100;
        setMousePosition({ x, y });
      };

    const scrollDown = (e) => {
        e.preventDefault();
        window.scrollBy({
            top: 700,
            behavior: 'smooth',
        });
    };
        return (
            <>
                <div className="mt-4 mb-3 relative flex flex-col-reverse py-16 lg:pt-0 lg:flex-col lg:pb-0">
                    <div
                        className="inset-y-0 top-0 right-0 z-0 w-full max-w-xl px-4 mx-auto md:px-0 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-7/12 lg:max-w-full lg:absolute xl:px-0">
                        <svg
                            className="absolute left-0 hidden h-full text-white transform -translate-x-1/2 lg:block"
                            viewBox="0 0 100 100"
                            fill="currentColor"
                            preserveAspectRatio="none slice"
                        >
                            <path d="M50 0H100L50 100H0L50 0Z"/>
                        </svg>
                        <img
                            className="object-cover w-full h-56 rounded shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
                            src="https://www.health.com/thmb/-QKcuFcvuCLf7DCywbCOTv20EIs=/5568x0/filters:no_upscale():max_bytes(150000):strip_icc()/GettyImages-1409664434-ae4362bcdf9041d08c62c9d9f1bae9cc.jpg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
                            alt=""
                        />
                    </div>
                    <div
                        className="relative flex flex-col items-start w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:max-w-screen-xl">
                        <div className="mb-16 lg:my-40 lg:max-w-lg lg:pr-5">
                            {/*<p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">*/}
                            {/*    Brand new*/}
                            {/*</p>*/}
                            <h2 className="mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                                Instant Relief
                                <br className="hidden md:block"/>
                                is Just a {' '}
                                <span className="bg-gradient-to-r from-teal-600 to-teal-800 bg-clip-text text-transparent cursor-pointer hover:opacity-80 transition-opacity" onClick={() => window.location.href='/submitRequest'}>
              Click Away
            </span>
                            </h2>
                            <p className="pr-5 mb-5 text-base text-gray-700 md:text-lg">
                                Experience immediate and personalized pain management from the comfort of your home. At
                                OneScript,
                                our dedicated healthcare professionals are here to diagnose
                                and treat your pain with the utmost care and precision.
                                Connect with us today and start your journey to a pain-free life.
                            </p>
                            <div className="flex items-center">
                                <a
                                    href="/submitRequest"
                                    className="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-white transition duration-300 rounded shadow-md bg-teal-700 hover:bg-teal-800 focus:shadow-outline focus:outline-none"
                                >
                                    Get started
                                </a>
                                <a
                                    href="/"
                                    onClick={scrollDown}
                                    aria-label=""
                                    className="inline-flex items-center font-semibold text-gray-800 transition-colors duration-300 hover:text-deep-purple-accent-700"
                                >
                                    Learn more
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="max-w-[70%] mx-auto mt-24 grid gap-12 row-gap-5 mb-8 md:grid-cols-2 lg:grid-cols-3">    
  
                    {/* Pain Card */}
                    <div 
                        className="relative overflow-hidden transform rounded-xl shadow-lg hover:scale-105 hover:shadow-xl flex flex-col justify-between"
                        onMouseMove={(e) => handleMouseMove(e, e.currentTarget)}
                        onMouseEnter={() => setHoveredCard('card1')}
                        onMouseLeave={() => setHoveredCard(null)}
                        style={{
                            background: hoveredCard === 'card1' 
                            ? `radial-gradient(circle at ${mousePosition.x}% ${mousePosition.y}%, rgb(13 148 136), rgb(15 118 110))`
                            : 'rgb(13 148 136)',
                            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                            willChange: 'transform, background'
                        }}
                        >
                        <div className="p-6">
                            <h6 className="font-semibold text-xl mb-3 text-white">Pain</h6>
                            <p className="text-sm text-white/90 mb-4">
                                Long-term pain management solutions for persistent conditions and recurring pain.
                            </p>
                        </div>
                        <div className="h-46 overflow-hidden flex items-center justify-center">
                            <img 
                                src="https://purepng.com/public/uploads/large/purepng.com-running-womenrunnerjoggingjumpingrunning-women-1421526964024eyghf.png"
                                alt="Pain management"
                                className="object-contain h-40 w-4/5 mx-auto"
                            />
                        </div>
                    </div>

                    {/* Beauty Card */}
                    <div 
                        className="relative overflow-hidden transform rounded-xl shadow-lg hover:scale-105 hover:shadow-xl flex flex-col justify-between"
                        onMouseMove={(e) => handleMouseMove(e, e.currentTarget)}
                        onMouseEnter={() => setHoveredCard('card2')}
                        onMouseLeave={() => setHoveredCard(null)}
                        style={{
                            background: hoveredCard === 'card2' 
                            ? `radial-gradient(circle at ${mousePosition.x}% ${mousePosition.y}%, rgb(13 148 136), rgb(15 118 110))`
                            : 'rgb(13 148 136)',
                            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                            willChange: 'transform, background'
                        }}
                        >
                        <div className="p-6">
                            <h6 className="font-semibold text-xl mb-3 text-white">Beauty</h6>
                            <p className="text-sm text-white/90 mb-4">
                                Prescription-grade skincare solutions for acne, anti-aging, and other dermatological concerns.
                            </p>
                        </div>
                        <div className="h-46 overflow-hidden flex items-center justify-center">
                            <img 
                                src="https://static.vecteezy.com/system/resources/previews/046/822/382/non_2x/a-professional-and-confident-business-woman-smiling-isolated-on-a-transparent-background-free-png.png"
                                alt="Beauty and skincare"
                                className="object-contain h-40 w-4/5 mx-auto"
                            />
                        </div>
                    </div>

                    {/* Sleep Card */}
                    <div 
                        className="relative overflow-hidden transform rounded-xl shadow-lg hover:scale-105 hover:shadow-xl flex flex-col justify-between"
                        onMouseMove={(e) => handleMouseMove(e, e.currentTarget)}
                        onMouseEnter={() => setHoveredCard('card3')}
                        onMouseLeave={() => setHoveredCard(null)}
                        style={{
                            background: hoveredCard === 'card3' 
                            ? `radial-gradient(circle at ${mousePosition.x}% ${mousePosition.y}%, rgb(13 148 136), rgb(15 118 110))`
                            : 'rgb(13 148 136)',
                            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                            willChange: 'transform, background'
                        }}
                        >
                        <div className="p-6">
                            <h6 className="font-semibold text-xl mb-3 text-white">Sleep</h6>
                            <p className="text-sm text-white/90 mb-4">
                                Expert solutions for better sleep quality, helping you overcome insomnia and maintain healthy sleep patterns.
                            </p>
                        </div>
                        <div className="h-46 overflow-hidden flex items-center justify-center">
                            <img 
                                src="https://www.sleephealthsolutionsohio.com/wp-content/uploads/banner-woman-happy-2.png"
                                alt="Sleep and rest"
                                className="object-contain h-40 w-4/5 mx-auto"
                            />
                        </div>
                    </div>

                    </div>


                <section className="py-12 mt-24 bg-gray-50 sm:py-16 lg:py-20">
                <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div className="flex flex-col items-center">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        Trusted by Patients Across Canada
                    </h2>
                    <p className="mt-3 text-xl text-gray-500 sm:mt-4">
                        Read what our patients say about their experience
                    </p>
                    </div>

                    <div className="grid grid-cols-1 gap-8 mt-12 sm:grid-cols-2 lg:grid-cols-3 sm:gap-6 lg:gap-8">
                    {/* Testimonial Card 1 */}
                    <div className="relative p-6 bg-white shadow-xl rounded-2xl">
                        <div className="flex items-center space-x-3">
                        <img
                            src="https://randomuser.me/api/portraits/women/32.jpg"
                            alt="Sarah M."
                            className="w-12 h-12 rounded-full"
                        />
                        <div>
                            <h3 className="text-lg font-medium text-gray-900">Sarah M.</h3>
                            <p className="text-base text-gray-500">Toronto, ON</p>
                        </div>
                        </div>
                        <p className="mt-4 text-base text-gray-500">
                        "Quick, efficient, and professional. The online assessment was thorough and I received my medication the next day. Highly recommend!"
                        </p>
                        <div className="flex mt-4 text-teal-500">
                        {"★".repeat(5)}
                        </div>
                    </div>

                    {/* Testimonial Card 2 */}
                    <div className="relative p-6 bg-white shadow-xl rounded-2xl">
                        <div className="flex items-center space-x-3">
                        <img
                            src="https://randomuser.me/api/portraits/men/44.jpg"
                            alt="Michael R."
                            className="w-12 h-12 rounded-full"
                        />
                        <div>
                            <h3 className="text-lg font-medium text-gray-900">Michael R.</h3>
                            <p className="text-base text-gray-500">Vancouver, BC</p>
                        </div>
                        </div>
                        <p className="mt-4 text-base text-gray-500">
                        "As someone with chronic back pain, finding OneScript was a game-changer. Their care team is responsive and understanding."
                        </p>
                        <div className="flex mt-4 text-teal-500">
                        {"★".repeat(5)}
                        </div>
                    </div>

                    {/* Testimonial Card 3 */}
                    <div className="relative p-6 bg-white shadow-xl rounded-2xl">
                        <div className="flex items-center space-x-3">
                        <img
                            src="https://randomuser.me/api/portraits/women/68.jpg"
                            alt="Emma L."
                            className="w-12 h-12 rounded-full"
                        />
                        <div>
                            <h3 className="text-lg font-medium text-gray-900">Emma L.</h3>
                            <p className="text-base text-gray-500">Montreal, QC</p>
                        </div>
                        </div>
                        <p className="mt-4 text-base text-gray-500">
                        "The entire process was seamless. From assessment to delivery, everything was handled professionally. Thank you OneScript!"
                        </p>
                        <div className="flex mt-4 text-teal-500">
                        {"★".repeat(5)}
                        </div>
                    </div>
                    </div>

                    <div className="mt-12 text-center">
                    <button 
                        onClick={() => window.location.href='/submitRequest'}
                        className="inline-flex items-center px-6 py-3 text-base font-medium text-white bg-teal-600 border border-transparent rounded-md shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                    >
                        Start Your Assessment
                    </button>
                    </div>

                </div>
                </section>

                {/* <div
                    className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                    <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                        <div>
                            <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                              
                            </p>
                        </div>
                        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                        <span className="relative inline-block">
                            <svg
                                viewBox="0 0 52 24"
                                fill="currentColor"
                                className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                            >
                            <defs>
                                <pattern
                                    id="b902cd03-49cc-4166-a0ae-4ca1c31cedba"
                                    x="0"
                                    y="0"
                                    width=".135"
                                    height=".30"
                                >
                                <circle cx="1" cy="1" r=".7"/>
                                </pattern>
                            </defs>
                            <rect
                                fill="url(#b902cd03-49cc-4166-a0ae-4ca1c31cedba)"
                                width="52"
                                height="24"
                            />
                            </svg>
                            <span className="relative">How it Works</span>
                        </span>{' '}
                        </h2>
                        <p className="text-base text-gray-700 md:text-lg">
                            Easy as 1, 2, 3.
                        </p>
                    </div>
                    <div className="grid gap-10 lg:grid-cols-4 sm:grid-cols-2">
                        <div>
                            <div className="flex items-center justify-between mb-6">
                                <p className="text-2xl font-bold">Step 1</p>
                                <svg
                                    className="w-6 text-gray-700 transform rotate-90 sm:rotate-0"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    viewBox="0 0 24 24"
                                >
                                    <line
                                        fill="none"
                                        strokeMiterlimit="10"
                                        x1="2"
                                        y1="12"
                                        x2="22"
                                        y2="12"
                                    />
                                    <polyline
                                        fill="none"
                                        strokeMiterlimit="10"
                                        points="15,5 22,12 15,19 "
                                    />
                                </svg>
                            </div>
                            <p className="text-gray-600">
                                Start by filling out our user-friendly web form with your personal details and symptoms.
                                The form is designed to be quick and easy to complete.
                            </p>
                        </div>
                        <div>
                            <div className="flex items-center justify-between mb-6">
                                <p className="text-2xl font-bold">Step 2</p>
                                <svg
                                    className="w-6 text-gray-700 transform rotate-90 sm:rotate-0"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    viewBox="0 0 24 24"
                                >
                                    <line
                                        fill="none"
                                        strokeMiterlimit="10"
                                        x1="2"
                                        y1="12"
                                        x2="22"
                                        y2="12"
                                    />
                                    <polyline
                                        fill="none"
                                        strokeMiterlimit="10"
                                        points="15,5 22,12 15,19 "
                                    />
                                </svg>
                            </div>
                            <p className="text-gray-600">
                                Our healthcare professionals will review your information and provide a personalized prescription within 24 hours.
                            </p>
                        </div>
                        <div>
                            <div className="flex items-center justify-between mb-6">
                                <p className="text-2xl font-bold">Step 3</p>
                                <svg
                                    className="w-6 text-gray-700 transform rotate-90 sm:rotate-0"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    viewBox="0 0 24 24"
                                >
                                    <line
                                        fill="none"
                                        strokeMiterlimit="10"
                                        x1="2"
                                        y1="12"
                                        x2="22"
                                        y2="12"
                                    />
                                    <polyline
                                        fill="none"
                                        strokeMiterlimit="10"
                                        points="15,5 22,12 15,19 "
                                    />
                                </svg>
                            </div>
                            <p className="text-gray-600">
                                With your prescription, you can purchase pain creams, gels, and patches directly from OneScript.
                                Our products are selected to offer effective pain relief.
                            </p>
                        </div>
                        <div>
                            <div className="flex items-center justify-between mb-6">
                                <p className="text-2xl font-bold">Success</p>
                                <svg
                                    className="w-8 text-gray-600"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                >
                                    <polyline
                                        fill="none"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeMiterlimit="10"
                                        points="6,12 10,16 18,8"
                                    />
                                </svg>
                            </div>
                            <p className="text-gray-600">
                                Tada! Your pain management products will be delivered to your doorstep within a few business days,
                                allowing you to manage your pain from the comfort of your home.
                            </p>
                        </div>
                    </div>
                </div> */}
                <FAQSection/>
            </>
        );
}

export default HomePage;