// components/shared/StatusLabel.jsx
export function StatusLabel({ status }) {
    const statusStyles = {
      SEE_DOCTOR: {
        bgColor: "bg-red-100 dark:bg-red-600",
        textColor: "text-white",
        icon: (
          <svg
            className="mr-1 h-3 w-3"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        ),
      },
      PENDING_REVIEW: {
        bgColor: "bg-yellow-100 dark:bg-yellow-400",
        textColor: "text-white",
        icon: (
          <svg
            className="mr-1 h-3 w-3"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 8v4l3 3M12 2a9 9 0 100 18 9 9 0 000-18z"
            />
          </svg>
        ),
      },
      PAID: {
        bgColor: "bg-green-100 dark:bg-green-500",
        textColor: "text-white",
        icon: (
          <svg
            className="mr-1 h-3 w-3"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 11.917 9.724 16.5 19 7.5" />
          </svg>
        ),
      },
      SHIPPED: {
        bgColor: "bg-blue-100 dark:bg-blue-700",
        textColor: "text-white",
        icon: (
          <svg
            className="mr-1 h-3 w-3"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M13 7h6l2 4m-8-4v8m0-8V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v9h2m8 0H9m4 0h2m4 0h2v-4m0 0h-5m3.5 5.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm-10 0a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"
            />
          </svg>
        ),
      },
      UNPAID: {
        bgColor: "bg-orange-700 dark:bg-orange-500",
        textColor: "text-white",
        icon: (
          <svg
            className="mr-1 h-3 w-3"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <rect x="2" y="3" width="20" height="18" rx="2" ry="2"></rect>
            <line x1="2" y1="7" x2="22" y2="7"></line>
            <line x1="6" y1="11" x2="9" y2="11"></line>
            <line x1="6" y1="15" x2="9" y2="15"></line>
            <line x1="6" y1="19" x2="9" y2="19"></line>
            <line x1="13" y1="11" x2="18" y2="11"></line>
            <line x1="13" y1="15" x2="18" y2="15"></line>
            <line x1="13" y1="19" x2="18" y2="19"></line>
          </svg>
        ),
      },
    };
  
    const defaultStyle = {
      bgColor: "bg-gray-100 dark:bg-gray-700",
      textColor: "text-gray-800 dark:text-gray-300",
      icon: null,
    };
  
    const { bgColor, textColor, icon } = statusStyles[status] || defaultStyle;
  
    function formatField(input) {
      if (!input) return "";
      return input
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(" ");
    }
  
    return (
      <span
        // className={`inline-flex items-center rounded px-2.5 py-0.5 ${bgColor} ${textColor}`}
        className={`inline-flex items-center rounded text-sm sm:text-base px-2 sm:px-2.5 py-0.5 ${bgColor} ${textColor}`}
      >
        {icon}
        {formatField(status)}
      </span>
    );
  }