import * as React from "react";
import { useRef, useState, useEffect } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate, useLocation } from "react-router-dom";

function NavBar() {
    const location = useLocation();
    const isBeautyRoute = location.pathname === '/beauty';
    const { user, signOut } = useAuthenticator((context) => [context.user]);
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = React.useState(false);

    // Function to handle navigation
    const handleNavigation = (path) => {
        navigate(path);
        setIsOpen(false); // Close the menu after navigation
        setIsDropdownOpen(false);
    };

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
          }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
      }, []);

    const ProfileIcon = ({ onClick, isLoggedIn }) => (
        <div className="relative" ref={dropdownRef}>
        <button 
            onClick={() => isLoggedIn ? setIsDropdownOpen(!isDropdownOpen) : handleNavigation('/login')}
            className="flex items-center gap-2 text-gray-800 hover:text-teal-700 transition-colors duration-200"
            title={isLoggedIn ? "Profile" : "Login"}
        >
            <svg 
            width="24" 
            height="24" 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke={isBeautyRoute ? 'gold' : 'currentColor'} 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round"
            className="w-6 h-6"
            >
            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
            <circle cx="12" cy="7" r="4" />
            </svg>
        </button>
        
        {isDropdownOpen && (
            <div className="absolute right-0 mt-3 w-56 bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 py-1 z-10">
            {isLoggedIn ? (
                <>
                <button
                    onClick={() => {
                    handleNavigation('/dashboard');
                    setIsDropdownOpen(false);
                    }}
                    className="block w-full text-left px-4 py-2.5 text-sm text-gray-700 hover:bg-gray-50 transition-colors duration-150"
                >
                    Dashboard
                </button>
                {/* <button
                    onClick={() => {
                    handleNavigation('/storefront');
                    setIsDropdownOpen(false);
                    }}
                    className="block w-full text-left px-4 py-2.5 text-sm text-gray-700 hover:bg-gray-50 transition-colors duration-150"
                >
                    Catalogue
                </button> */}
                <button
                    onClick={() => {
                    handleNavigation('/submitRequest');
                    setIsDropdownOpen(false);
                    }}
                    className="block w-full text-left px-4 py-2.5 text-sm text-gray-700 hover:bg-gray-50 transition-colors duration-150"
                >
                    Get Medication
                </button>
                <div className="border-t border-gray-100 my-1"></div>
                <button
                    onClick={() => {
                    signOut();
                    setIsDropdownOpen(false);
                    }}
                    className="block w-full text-left px-4 py-2.5 text-sm text-red-600 hover:bg-red-50 transition-colors duration-150"
                >
                    Sign out
                </button>
                </>
            ) : (
                <>
                <button
                    onClick={() => {
                    handleNavigation('/');
                    setIsDropdownOpen(false);
                    }}
                    className="block w-full text-left px-4 py-2.5 text-sm text-gray-700 hover:bg-gray-50 transition-colors duration-150"
                >
                    Home
                </button>
                <button
                    onClick={() => {
                    handleNavigation('/about');
                    setIsDropdownOpen(false);
                    }}
                    className="block w-full text-left px-4 py-2.5 text-sm text-gray-700 hover:bg-gray-50 transition-colors duration-150"
                >
                    About
                </button>
                <button
                    onClick={() => {
                    handleNavigation('/contact');
                    setIsDropdownOpen(false);
                    }}
                    className="block w-full text-left px-4 py-2.5 text-sm text-gray-700 hover:bg-gray-50 transition-colors duration-150"
                >
                    Contact
                </button>
                <button
                    onClick={() => {
                    handleNavigation('/submitRequest');
                    setIsDropdownOpen(false);
                    }}
                    className="block w-full text-left px-4 py-2.5 text-sm text-gray-700 hover:bg-gray-50 transition-colors duration-150"
                >
                    Get Medication
                </button>
                </>
            )}
            </div>
        )}
        </div>
    );

    return (
        <div className={`NavBar shadow-md py-4 px-4 md:px-8 ${
            isBeautyRoute ? 'bg-black' : 'bg-white'
          }`}>
            {/* Update text colors */}
            <div className={`text-base leading-6 ${
              isBeautyRoute ? 'text-yellow-400' : 'text-gray-800'
            }`}>
                {/* <div className="flex justify-between items-center">
                    <div className="flex items-center gap-4 md:gap-8">
                        <div className="text-2xl md:text-3xl font-extrabold tracking-tight">
                            <button 
                                onClick={() => handleNavigation('/')}
                                className="bg-gradient-to-r mr-4 from-teal-600 to-teal-800 bg-clip-text text-transparent font-mono cursor-pointer hover:opacity-80 transition-opacity"
                            >
                                OneScript
                            </button>
                        </div>
                
                        <div className="hidden md:flex items-center justify-center absolute left-1/2 transform -translate-x-1/2 gap-8 md:gap-8">
                            {user ? (
                                <>
                                </>
                            ) : (
                                <>
                                    <button onClick={() => handleNavigation('/about')}
                                            className="text-lg font-sans leading-6">Pain
                                    </button>
                                    <button onClick={() => handleNavigation('/beauty')}
                                            className="text-lg font-sans leading-6">Beauty
                                    </button>
                                    <button onClick={() => handleNavigation('/submitRequest')}
                                            className="text-lg font-sans leading-6">Sleep
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="flex gap-4">
                        <div className="md:hidden">
                            <button onClick={toggleMenu} className="text-gray-800 focus:outline-none">
                            <svg className={`w-6 h-6 transition-transform transform ${isOpen ? 'rotate-90' : 'rotate-0'}`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}></path>
                            </svg>
                            </button>
                        </div>
                        <ProfileIcon 
                            onClick={user ? signOut : () => handleNavigation('/login')}
                            isLoggedIn={!!user}
                        />
                        </div>
                    </div> */}

                    <div className="flex justify-between items-center max-w-7xl mx-auto px-4">

                    <div className="md:hidden">
                            {user ? (<></>): (
                                <>
                                <button onClick={toggleMenu} className="text-gray-800 focus:outline-none">
                                <svg className={`w-6 h-6 transition-transform transform ${isOpen ? 'rotate-90' : 'rotate-0'}`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}></path>
                                </svg>
                                </button>
                                </>
                            )}
                                            </div>
                        {/* Logo Section - Fixed width */}
                        <div className="w-48 absolute left-1/2 transform -translate-x-1/2 flex justify-center md:static md:transform-none md:justify-start">
                            <button 
                                onClick={() => handleNavigation('/')}
                                className="bg-gradient-to-r from-teal-600 to-teal-800 bg-clip-text text-transparent font-mono cursor-pointer hover:opacity-80 transition-opacity text-2xl md:text-3xl font-extrabold tracking-tight"
                            >
                                OneScript
                            </button>
                        </div>

                        {/* Centered Nav Links */}
                        <div className="hidden md:flex items-center justify-center gap-8 md:gap-8">
                            {user ? (
                                <>
                                   <button onClick={() => handleNavigation('/pain')}
                                            className="text-lg font-heading leading-6">Pain
                                    </button>
                                    <button onClick={() => handleNavigation('/beauty')}
                                            className="text-lg font-heading leading-6">Beauty
                                    </button>
                                </>
                            ) : (
                                <>
                                    <button onClick={() => handleNavigation('/pain')}
                                            className="text-lg font-heading leading-6">Pain
                                    </button>
                                    <button onClick={() => handleNavigation('/beauty')}
                                            className="text-lg font-heading leading-6">Beauty
                                    </button>
                                    <button onClick={() => handleNavigation('/submitRequest')}
                                            className="text-lg font-heading leading-6">Get Medication
                                    </button>
                                </>
                            )}
                        </div>

                        {/* Profile Section - Fixed width */}
                        <div className="w-48 flex gap-4 justify-end">
                 
                                            <ProfileIcon 
                                                onClick={user ? signOut : () => handleNavigation('/login')}
                                                isLoggedIn={!!user}
                                            />
                        </div>
                    </div>
                {isOpen && (
                    <div className="flex flex-col items-center mt-4 md:hidden">
                        {user ? (
                            <>
                         
                            </>
                        ) : (
                            <>
                                <button onClick={() => handleNavigation('/pain')}
                                        className="text-gray-800 text-heading leading-6 mb-2 text-center">Pain
                                </button>
                                <button onClick={() => handleNavigation('/beauty')}
                                        className="text-gray-800 text-heading leading-6 mb-2 text-center">Beauty
                                </button>
                                {/* <button onClick={() => handleNavigation('/sleep')}
                                        className="text-gray-800 text-heading leading-6 mb-2 text-center">Sleep
                                </button> */}
                            </>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

export default NavBar;
