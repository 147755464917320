import React, { useEffect, useState, useRef } from "react";
import { DataStore } from "aws-amplify/datastore";
import {
  PainAssessment,
  BeautyAssessment,
  PainTreatmentPlan,
  BeautyTreatmentPlan,
  Patient,
} from "../../models";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import { fetchAuthSession } from "aws-amplify/auth";

import { TypeToggle } from './components/shared/TypeToggle.jsx';
import { StatusLabel } from './components/shared/StatusLabel.jsx';
import { formatField, formatDate } from './components/shared/utils.js';
import { Pagination, usePagination } from './components/shared/Pagination.jsx';
import PaymentRouter from '../../utils/PaymentRouter.js';
import ContentRenderer from "./components/ContentRenderer.js";
import TableLoadingSkeleton from './components/shared/TableSkeleton'

const handlePaymentError = (error) => {
  // Handle payment errors (you can add your own error handling logic here)
  console.error('Payment error:', error);
  // Optionally show an error message to the user
};

const RefillButton = () => {
  const navigate = useNavigate();
  return (
    <button
      onClick={() => navigate('/prescription-renewal')}
      className="flex items-center space-x-2 bg-teal-700 hover:bg-teal-800 text-white font-medium py-2 px-4 rounded-lg transition-colors duration-200"
    >
      <svg 
        className="w-6 h-6" 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 24 24" 
        fill="currentColor"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <path d="M20.207 3.793a5.95 5.95 0 0 1 0 8.414l-8 8a5.95 5.95 0 0 1 -8.414 -8.414l8 -8a5.95 5.95 0 0 1 8.414 0m-7 1.414l-4.294 4.293l5.586 5.586l4.294 -4.292a3.95 3.95 0 1 0 -5.586 -5.586" />
      </svg>
      <span>Refill Prescription</span>
    </button>
  );
};

/** Check the role of the authenticated user */
async function checkRole() {
  try {
    const { tokens } = await fetchAuthSession();
    const groups = tokens?.accessToken?.payload?.["cognito:groups"] || [];
    if (groups.includes("HCPs")) return "doc";
    if (groups.includes("Admin")) return "admin";
  } catch (err) {
    console.error("Error checking user role:", err);
  }
  return "user";
}

async function fetchUserData(userId) {
  try {
    // 1. Clear existing DataStore data
    // await DataStore.clear();
    // await DataStore.start();

    // 2. Get patients from DataStore
    const patients = await DataStore.query(Patient, 
      p => p.userPatientsId.eq(userId)
    );
    console.log('DataStore patients:', patients);

    const allAssessments = [];

    // 3. Get assessments for each patient
    for (const patient of patients) {
      // Get beauty assessments
      const beautyAssessments = await DataStore.query(
        BeautyAssessment,
        ba => ba.patientId.eq(patient.id)
      );

      // Get pain assessments
      const painAssessments = await DataStore.query(
        PainAssessment,
        pa => pa.patientId.eq(patient.id)
      );

      // Get treatment plans and combine data
      for (const assessment of beautyAssessments) {
        const treatmentPlans = await DataStore.query(
          BeautyTreatmentPlan,
          plan => plan.beautyAssessmentId.eq(assessment.id)
        );

        if (treatmentPlans.length > 0) {
          allAssessments.push({
            ...assessment,
            type: 'BEAUTY',
            patient,
            treatmentPlans
          });
        }
      }

      for (const assessment of painAssessments) {
        const treatmentPlans = await DataStore.query(
          PainTreatmentPlan,
          plan => plan.painAssessmentId.eq(assessment.id)
        );

        if (treatmentPlans.length > 0) {
          allAssessments.push({
            ...assessment,
            type: 'PAIN',
            patient,
            treatmentPlans
          });
        }
      }
    }

    console.log('All assessments:', allAssessments);
    return allAssessments;

  } catch (error) {
    console.error('DataStore error:', error);
    throw error;
  }
}

/* ------------------------
   Icons & Sub-Components
------------------------ */

const ChevronDownIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M6 9l6 6 6-6" />
  </svg>
);

const ChevronUpIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M18 15l-6-6-6 6" />
  </svg>
);

/* ------------------------
   Expanded Row Animations
------------------------ */

function ExpandedRow({ item, selectedType, isExpanded }) {
  const plan = item.treatmentPlans?.[0];

  const contentRef = useRef(null);
  const [height, setHeight] = useState(0);
  const [shouldRender, setShouldRender] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (isExpanded) {
      setShouldRender(true);
      setIsAnimating(true);
    } else {
      setIsAnimating(false);
      // Delay unmounting until animation completes
      const timer = setTimeout(() => {
        setShouldRender(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [isExpanded]);

  useEffect(() => {
    if (!contentRef.current) return;

    const updateHeight = () => {
      const h = contentRef.current?.scrollHeight || 0;
      setHeight(isAnimating ? h : 0);
    };

    updateHeight();
    requestAnimationFrame(updateHeight);
  }, [isAnimating, shouldRender]);

  return (
    <tr>
      <td colSpan="5" className="p-0">
        <div
          style={{ height: `${height}px` }}
          className="transition-all duration-300 ease-in-out overflow-hidden"
        >
          {shouldRender && (
            <div
              ref={contentRef}
              className={`p-4 bg-gray-100 transition-all duration-300 ease-in-out transform ${
                isAnimating ? "translate-y-0 opacity-100" : "translate-y-8 opacity-0"
              }`}
            >
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {/* Patient Info */}
                <div
                  className={`transition-all duration-300 delay-[50ms] transform ${
                    isAnimating ? "translate-x-0 opacity-100" : "-translate-x-8 opacity-0"
                  }`}
                >
                  <h4 className="font-semibold mb-2">Patient Information</h4>
                  <p>
                    <strong>Name:</strong> {item.patient?.name}
                  </p>
                  <p>
                    <strong>Sex:</strong> {item.patient?.sex || "N/A"}
                  </p>
                  <p>
                    <strong>Date of Birth:</strong> {item.patient?.dateOfBirth || "N/A"}
                  </p>
                </div>

                {/* Assessment Fields */}
                <div
                  className={`transition-all duration-300 delay-[100ms] transform ${
                    isAnimating ? "translate-y-0 opacity-100" : "translate-y-8 opacity-0"
                  }`}
                >
                  {selectedType === "PAIN" ? (
                    <>
                      <h4 className="font-semibold mb-2">Pain Assessment</h4>
                      <p>
                        <strong>Assessment Date:</strong> {formatDate(plan?.createdAt)}
                      </p>
                      <p>
                        <strong>Severity:</strong>{" "}
                        {formatField(item.severityScore) ?? "N/A"}
                      </p>
                      <p>
                        <strong>Onset:</strong> {formatField(item.onset) || "Unknown"}
                      </p>
                      <p>
                        <strong>Symptoms:</strong>{" "}
                        {formatField(item.presentingSymptoms?.join(", ")) || "None reported"}
                      </p>
                      <p>
                        <strong>Additional Notes:</strong>{" "}
                        {item.additionalNotes || "N/A"}
                      </p>
                    </>
                  ) : (
                    <>
                      <h4 className="font-semibold mb-2">Beauty Assessment</h4>
                      <p>
                        <strong>Assessment Date:</strong> {formatDate(plan?.createdAt)}
                      </p>
                      <p>
                        <strong>Areas of Concern:</strong>{" "}
                        {formatField(item.areaOfConcern?.join(", ")) || "N/A"}
                      </p>
                      <p>
                        <strong>Anti-Aging Concerns:</strong>{" "}
                        {formatField(item.antiAgingConcern?.join(", ")) || "N/A"}
                      </p>
                    </>
                  )}
                </div>

                {/* Treatment Plan */}
                <div
                  className={`transition-all duration-300 delay-[150ms] transform ${
                    isAnimating ? "translate-x-0 opacity-100" : "translate-x-8 opacity-0"
                  }`}
                >
                  <h4 className="font-semibold mb-2">Treatment Plan</h4>
                  {plan ? (
                    <>
                      <p>
                        <strong>Status:</strong> <StatusLabel status={item.treatmentPlans[0].status} />
                      </p>
                      <p>
                        <strong>Recommendation:</strong>{" "}
                        {plan.recommendedTreatment || "N/A"}
                      </p>
                      <p>
                        <strong>Comments:</strong>{" "}
                        {plan.additionalComments || "No comments"}
                      </p>
                      {plan.status === "UNPAID" && (
                         <PaymentRouter 
                                    recommendedTreatment={plan.recommendedTreatment}
                                    treatmentPlanId={plan.id}
                                    onError={handlePaymentError}
                                  />
                      )}
                    </>
                  ) : (
                    <p>No treatment plan available</p>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </td>
    </tr>
  );
}

/* ------------------------
   Main Dashboard Component
------------------------ */

export default function Dashboard() {
  const { user } = useAuthenticator((context) => [context.user]);
  const navigate = useNavigate();

  const [treatmentPlans, setTreatmentPlans] = useState([]);
  const [filteredTreatmentPlans, setFilteredTreatmentPlans] = useState([]);
  // const [itemsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [statusFilter, setStatusFilter] = useState("All");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [expandedRows, setExpandedRows] = useState({});
  const [isCheckingRole, setIsCheckingRole] = useState(true);
  const [selectedType, setSelectedType] = useState("PAIN");
  const [filteredAssessments, setFilteredAssessments] = useState([]);

  useEffect(() => {
    const filtered = filterTreatmentPlans(treatmentPlans);
    setFilteredAssessments(filtered);
  }, [treatmentPlans, searchTerm, statusFilter, selectedType]);

  /* Check user role and possibly redirect */
  useEffect(() => {
    (async () => {
      if (!user) {
        navigate("/login");
        return;
      }
      try {
        const role = await checkRole();
        if (role === "doc") {
          navigate("/docdashboard");
        } else if (role === "admin") {
          navigate("/admin");
        } else {
          navigate("/dashboard");
        }
      } catch (err) {
        console.error("Error checking role:", err);
        setError("Failed to verify user access");
      } finally {
        setIsCheckingRole(false);
      }
    })();
  }, [user, navigate]);

  const {
    currentPage,
    pageCount,
    indexOfFirstItem,
    indexOfLastItem,
    currentItems,
    handlePageChange
  } = usePagination(filteredAssessments);

  /* Fetch user data once user is confirmed */
  useEffect(() => {
    if (user) {
      fetchUserData(user.userId)
        .then((data) => {
          setTreatmentPlans(data);
          setLoading(false);
        })
        .catch((err) => {
          setError("Failed to fetch data");
          console.error(err);
          setLoading(false);
        });
    }
  }, [user]);

  /* Filter by status each time status changes */
  useEffect(() => {
    if (statusFilter === "All") {
      setFilteredTreatmentPlans(treatmentPlans);
    } else {
      const filtered = treatmentPlans.filter(
        (plan) =>
          plan.treatmentPlans.length > 0 &&
          plan.treatmentPlans[0].status === statusFilter
      );
      setFilteredTreatmentPlans(filtered);
    }
  }, [treatmentPlans, statusFilter]);

  if (loading) return <TableLoadingSkeleton />;
  if (error) return <p>Error: {error}</p>;
  if (isCheckingRole) return <div>Verifying access...</div>;

  /* ------------------------
     Handler: Expand/Collapse
  ------------------------ */
  const toggleDetails = (index) => {
    setExpandedRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  /* ------------------------
     Helper: Filter Data
  ------------------------ */
  function filterTreatmentPlans(data) {
    return data
      .filter((item) => {
        const matchesSearch =
          !searchTerm ||
          item.patient?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.patient?.email?.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesStatus =
          statusFilter === "All" ||
          item.treatmentPlans[0].status === statusFilter;
        const matchesType = item.type === selectedType;
        return matchesSearch && matchesStatus && matchesType;
      })
      .sort((a, b) => {
        // Get the creation dates from the first treatment plan of each assessment
        const dateA = new Date(a.treatmentPlans[0]?.createdAt || 0);
        const dateB = new Date(b.treatmentPlans[0]?.createdAt || 0);
        // Sort in descending order (most recent first)
        return dateB - dateA;
      });
    }

  /* ------------------------
     Rendering Table Rows
  ------------------------ */
  function renderTableRows() {

    return currentItems.map((item, idx) => {
      const globalIndex = indexOfFirstItem + idx; // stable index across pages
      const firstPlan = item.treatmentPlans?.[0];

      return (
        <React.Fragment key={globalIndex}>
          <tr
            className={`${expandedRows[globalIndex] ? "bg-gray-50" : "bg-white"} hover:bg-gray-50`}
          >
            <td className="px-4 py-4 text-base text-gray-600 whitespace-nowrap">
              {firstPlan?.createdAt ? formatDate(firstPlan.createdAt) : "N/A"}
            </td>
            <td className="px-4 py-4 text-base text-gray-600 whitespace-nowrap">
              {formatField(item.patient?.name ?? "Unknown")}
            </td>
            <td className="px-4 py-4 text-base text-gray-600 whitespace-nowrap">
              {firstPlan?.status ?<StatusLabel status={item.treatmentPlans[0].status} /> : "No Plan Status"}
            </td>
            <td className="px-4 py-4 text-base text-gray-600 whitespace-nowrap">
              {/* For the main column, show first presenting symptom or area of concern */}
              {item.type === "PAIN"
                ? item.presentingSymptoms?.length
                  ? formatField(item.presentingSymptoms[0])
                  : "No Symptoms Reported"
                : item.areaOfConcern?.length
                ? formatField(item.areaOfConcern[0])
                : "No Areas Specified"}
            </td>
            <td className="py-4 px-4">
              <button
                onClick={() => toggleDetails(globalIndex)}
                className="flex items-center justify-end w-full text-teal-700 hover:text-teal-900"
              >
                {expandedRows[globalIndex] ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </button>
            </td>
          </tr>

          {/* Expanded details row, only rendered if expandedRows[globalIndex] is true */}
          {expandedRows[globalIndex] && (
            <ExpandedRow
              item={item}
              selectedType={item.type}
              isExpanded={expandedRows[globalIndex]}
            />
          )}
        </React.Fragment>
      );
    });
  }

  /* ------------------------
     RENDER
  ------------------------ */
  return (
    <div className="bg-gray-50">
      {/* <h1 className="py-5 mx-auto">Assessment History</h1> */}
      <div className="flex justify-between items-center md:px-16 lg:px-[16rem]">
      <h1 className="py-12 mt-6 text-3xl text-gray-900">
        Assessment History
      </h1>
      <div className="py-12 mt-6">
        <RefillButton />
      </div>
    </div>
      <section className="container px-4 mb-12 mx-auto">
        <TypeToggle selectedType={selectedType} onSelectType={setSelectedType} />
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block mb-24 min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="mx-auto max-w-screen-xl px-4 lg:px-12">
              <div className="bg-white dark:bg-gray-800 relative shadow-md rounded-lg overflow-hidden">
                {/* Filter UI */}
                <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
                  {/* Search bar */}
                  <form className="flex items-center w-full md:w-1/2">
                    <label htmlFor="simple-search" className="sr-only">
                      Search
                    </label>
                    <div className="relative w-full">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg
                          aria-hidden="true"
                          className="w-5 h-5 text-gray-500 dark:text-gray-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 4a4 4 0 100 8 
                               4 4 0 000-8zM2 8a6 6 0 1110.89 
                               3.476l4.817 4.817a1 1 0 
                               01-1.414 1.414l-4.816-4.816A6 
                               6 0 012 8z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </div>
                      <input
                        type="text"
                        id="simple-search"
                        className="bg-gray-700 border border-gray-600 text-black text-sm 
                                   rounded-lg focus:ring-primary-500 focus:border-primary-500 
                                   block w-full pl-10 p-2.5 
                                   dark:bg-gray-50 dark:border-gray-300 dark:text-gray-900 
                                   dark:placeholder-gray-400 dark:focus:ring-primary-500 
                                   dark:focus:border-primary-500"
                        placeholder="Search Patients"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                  </form>
                  {/* Status Filter */}
                  <div className="relative">
                    <button
                      onClick={() => setDropdownOpen((prev) => !prev)}
                      className="inline-flex justify-center w-full rounded-md border shadow-sm px-4 py-2 
                                 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 
                                 focus:outline-none"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        className="h-4 w-4 mr-2 text-gray-400"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M3 3a1 1 0 011-1h12a1 1 
                             0 011 1v3a1 1 0 01-.293.707L12 
                             11.414V15a1 1 0 01-.293.707l-2 
                             2A1 1 0 018 17v-5.586L3.293 
                             6.707A1 1 0 013 6V3z"
                          clipRule="evenodd"
                        />
                      </svg>
                      Filter
                      <svg
                        className="ml-1.5 w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 
                             0 011.414 0L10 10.586l3.293-3.293
                             a1 1 0 111.414 1.414l-4 4a1 1 
                             0 01-1.414 0l-4-4a1 1 0 
                             010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                    {/* Dropdown */}
                    {dropdownOpen && (
                      <div
                        style={{
                          position: "absolute",
                          top: "100%",
                          left: 0,
                          transform: "translateX(0%)",
                          width: "100%",
                          maxHeight: "125px",
                          overflow: "auto",
                        }}
                        className="origin-top-right mt-1 rounded-md shadow-lg 
                                   bg-white ring-1 ring-black ring-opacity-5 
                                   divide-y divide-gray-100 focus:outline-none"
                      >
                        <ul className="py-1 text-sm text-gray-700 dark:text-gray-700">
                          <li>
                            <button
                              onClick={() => {
                                setStatusFilter("All");
                                setDropdownOpen(false);
                              }}
                              className="block w-full text-left py-2 px-4 hover:bg-gray-100 
                                         dark:hover:bg-gray-600 dark:hover:text-white"
                            >
                              All
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() => {
                                setStatusFilter("SEE_DOCTOR");
                                setDropdownOpen(false);
                              }}
                              className="block w-full text-left py-2 px-4 hover:bg-gray-100 
                                         dark:hover:bg-gray-600 dark:hover:text-white"
                            >
                              See Doctor
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() => {
                                setStatusFilter("PENDING_REVIEW");
                                setDropdownOpen(false);
                              }}
                              className="block w-full text-left py-2 px-4 hover:bg-gray-100 
                                         dark:hover:bg-gray-600 dark:hover:text-white"
                            >
                              Pending Review
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() => {
                                setStatusFilter("UNPAID");
                                setDropdownOpen(false);
                              }}
                              className="block w-full text-left py-2 px-4 hover:bg-gray-100 
                                         dark:hover:bg-gray-600 dark:hover:text-white"
                            >
                              Unpaid
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() => {
                                setStatusFilter("PAID");
                                setDropdownOpen(false);
                              }}
                              className="block w-full text-left py-2 px-4 hover:bg-gray-100 
                                         dark:hover:bg-gray-600 dark:hover:text-white"
                            >
                              Paid
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() => {
                                setStatusFilter("SHIPPED");
                                setDropdownOpen(false);
                              }}
                              className="block w-full text-left py-2 px-4 hover:bg-gray-100 
                                         dark:hover:bg-gray-600 dark:hover:text-white"
                            >
                              Shipped
                            </button>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>

                {/* Main Table */}
                {/* <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-teal-700">
                    <tr>
                      <th className="px-4 py-3.5 text-lg font-bold text-left text-white">
                        Date
                      </th>
                      <th className="px-4 py-3.5 text-lg font-bold text-left text-white">
                        Patient
                      </th>
                      <th className="px-4 py-3.5 text-lg font-bold text-left text-white">
                        Status
                      </th>
                      <th className="px-4 py-3.5 text-lg font-bold text-left text-white">
                        {selectedType === "PAIN" ? "Presenting Symptoms" : "Area of Concern"}
                      </th>
                      <th className="py-3.5 px-4">
                        <span className="sr-only">View</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-300">
                    {currentItems.length > 0 ? (
                      renderTableRows()
                    ) : (
                      <tr>
                        <td colSpan="5" className="px-4 py-8 text-center text-gray-500">
                          No {selectedType.toLowerCase()} treatment plans found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table> */}
                <ContentRenderer
                  currentItems={currentItems}
                  expandedRows={expandedRows}
                  toggleDetails={toggleDetails}
                  indexOfFirstItem={indexOfFirstItem}
                  selectedType={selectedType}
                  renderTableRows={renderTableRows}
                />
                <Pagination
                  currentPage={currentPage}
                  pageCount={pageCount}
                  indexOfFirstItem={indexOfFirstItem}
                  indexOfLastItem={indexOfLastItem}
                  totalItems={filteredAssessments.length}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}




