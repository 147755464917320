import '@aws-amplify/ui-react/styles.css';
import { Authenticator, View, useTheme, Text, Heading, Button, useAuthenticator } from '@aws-amplify/ui-react';
import React, { useEffect, useState } from 'react';
import { User } from '../../models';
import { DataStore } from "aws-amplify/datastore";
import { useNavigate } from "react-router-dom";
import { fetchAuthSession } from "aws-amplify/auth";

function LoginPage() {
  const { user } = useAuthenticator((context) => [context.user]);
  const navigate = useNavigate();
  const [isRedirecting, setIsRedirecting] = useState(false);

  const AuthLoadingScreen = () => {
    return (
      <div className="fixed inset-0 bg-white z-50 flex items-center justify-center">
        <div className="flex flex-col items-center space-y-6">
          {/* Logo placeholder animation */}
          <button                
            className="bg-gradient-to-r from-teal-600 to-teal-800 bg-clip-text text-transparent font-mono cursor-pointer hover:opacity-80 transition-opacity text-2xl md:text-3xl font-extrabold tracking-tight"
        >
            OneScript
        </button>
          
          {/* Loading text with shimmer effect */}
          <div className="relative">
            <div className="text-lg font-medium text-gray-600">
              Preparing your dashboard...
            </div>
            {/* Shimmer overlay */}
            <div className="absolute inset-0 w-full bg-gradient-to-r from-transparent via-white to-transparent animate-shimmer"></div>
          </div>
          
          {/* Loading bar */}
          <div className="w-48 h-1 bg-gray-200 rounded-full overflow-hidden">
            <div className="w-full h-full bg-teal-600 animate-loading-bar"></div>
          </div>
        </div>
      </div>
    );
  };
  
  // Define custom animations in the style tag
  const style = document.createElement('style');
  style.textContent = `
    @keyframes shimmer {
      0% { transform: translateX(-100%); }
      100% { transform: translateX(100%); }
    }
    
    @keyframes loading-bar {
      0% { transform: translateX(-100%); }
      100% { transform: translateX(100%); }
    }
    
    .animate-shimmer {
      animation: shimmer 2s infinite;
    }
    
    .animate-loading-bar {
      animation: loading-bar 1.5s infinite;
    }
  `;
  document.head.appendChild(style);

  // Function to check if user belongs to 'Doctors' group
  const checkRole = async () => {
    try {
      const { tokens } = await fetchAuthSession();
      const group = tokens?.accessToken?.payload?.["cognito:groups"] || [];
      if (group.includes('HCPs')) {
        console.log("User belongs to 'Doctors' group.");
        return 'doc';
      }
      else if (group.includes('Admin')) {
        console.log("User belongs to 'Admin' group.");
        return 'admin';
      }
    } catch (err) {
      console.error("User does not belong to a group:", err);
      return 'user';
    }
  };

  React.useEffect(() => {
    const redirectUser = async () =>  {
      if (user) {
        setIsRedirecting(true); 
        try {
        const role = await checkRole();
        if (role === 'doc') {
          navigate('/docdashboard');
          console.log(role);
        } else if (role === 'admin') {
          navigate('/admin');
          console.log(role);
        } else {
          navigate('/dashboard');
        }
      } catch (error) {
          console.error("Error during redirect:", error);
          setIsRedirecting(false); // Hide loading on error
        }
     }
    }

    redirectUser();
  }, [navigate, user]);

  useEffect(() => {
    // Function to check and add a new user to DataStore
    const checkAndAddUser = async () => {
      if (user) {      
        console.log("Checking for existing user in DataStore...");
        try {
          const existingUser = await DataStore.query(User, c => c.id.eq(user.id));
          if (existingUser.length === 0) {  // Check if the user is not already in the DataStore
            console.log("User not found in DataStore, adding...");
            await DataStore.save(new User({
              name: user.attributes?.name || '',
              username: user.username,
              email: user.signInDetails.loginId,
              id: user.userId
            }));
            console.log("User successfully added to DataStore.");
          } else {
            console.log("User already exists in DataStore.");
          }
        } catch (error) {
          console.error("Error adding user to DataStore:", error);
        }
      } else {
        console.log("No user information available, skipping DataStore update.");
      }
    };

    checkAndAddUser();
  }, [user]);

  const components = {
    Header() {
      // const { tokens } = useTheme();
  
      return (
        // <View textAlign="center" padding={tokens.space.large}>
        //   <Image
        //     alt="Amplify logo"
        //     src="https://docs.amplify.aws/assets/logo-dark.svg"
        //   />
        // </View>
        <div className='mt-24' />

      );
    },
  
    Footer() {
      const { tokens } = useTheme();
  
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Text color={tokens.colors.neutral[80]}>
            &copy; 2024 OuchRx
          </Text>
        </View>
      );
    },
  
    SignIn: {
      Header() {
        const { tokens } = useTheme();
  
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Sign in to your account
          </Heading>
        );
      },
      Footer() {
        const { toForgotPassword } = useAuthenticator();
  
        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toForgotPassword}
              size="small"
              variation="link"
            >
              Reset Password
            </Button>
          </View>
        );
      },
    },
  
    SignUp: {
      Header() {
        const { tokens } = useTheme();
  
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Create a new account
          </Heading>
        );
      },
      Footer() {
        const { toSignIn } = useAuthenticator();
  
        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toSignIn}
              size="small"
              variation="link"
            >
              Back to Sign In
            </Button>
          </View>
        );
      },
    },
    ConfirmSignUp: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      },
    },
    SetupTotp: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      },
    },
    ConfirmSignIn: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      },
    },
    ForgotPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      },
    },
    ConfirmResetPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      },
    },
  };
  
  const formFields = {
    signIn: {
      username: {
        placeholder: 'Enter your email',
      },
    },
    signUp: {
      name: {
        label: 'Full Name',
        placeholder: 'Enter your full name',
        order: 1,
        isRequired: true,
      },
      email: {
        order: 2, // Ensure the email field is first
      },
      password: {
        order: 3, // Ensure the password field is second
      },
      confirm_password: {
        order: 4, // Ensure the confirm password field is third
      },
    },
    forceNewPassword: {
      password: {
        placeholder: 'Enter your Password:',
      },
    },
    forgotPassword: {
      username: {
        placeholder: 'Enter your email:',
      },
    },
    confirmResetPassword: {
      confirmation_code: {
        placeholder: 'Enter your Confirmation Code:',
        label: 'New Label',
        isRequired: false,
      },
      confirm_password: {
        placeholder: 'Enter your Password Please:',
      },
    },
    setupTotp: {
      QR: {
        totpIssuer: 'test issuer',
        totpUsername: 'amplify_qr_test_user',
      },
      confirmation_code: {
        label: 'New Label',
        placeholder: 'Enter your Confirmation Code:',
        isRequired: false,
      },
    },
    confirmSignIn: {
      confirmation_code: {
        label: 'New Label',
        placeholder: 'Enter your Confirmation Code:',
        isRequired: false,
      },
    },
  };
  
  // return (
  //   <Authenticator socialProviders={['apple', 'google']} formFields={formFields} components={components} loginMechanisms={['email']}>
  //     {({ signOut, user }) => (
  //       <div>
  //         {/* Redirect or show user info */}
  //         <button onClick={signOut}>Sign Out</button>
  //       </div>
  //     )}
  //   </Authenticator>
  // );
  return (
    <>
      {isRedirecting && <AuthLoadingScreen />}
      <Authenticator 
        socialProviders={['apple', 'google']} 
        formFields={formFields} 
        components={components} 
        loginMechanisms={['email']}
      >
        {({ signOut, user }) => (
          <div>
            <button onClick={signOut}>Sign Out</button>
          </div>
        )}
      </Authenticator>
    </>
  );
}

export default LoginPage;
