import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import PatientCreateForm from "../forms/createPatient";
import { AssessmentResult } from "./AssessmentResult";
import PainAssessmentForm from "../forms/createPainAssessment";
import {
  PainAssessment,
  BeautyAssessment,
  PainTreatmentPlan,
  BeautyTreatmentPlan,
  Patient,
} from "../../models";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import { DataStore } from "aws-amplify/datastore";
import { useLocation, useNavigate } from "react-router-dom";
import CreateBeautyAssessment from "../forms/createBeautyAssessment";
import { generateClient } from 'aws-amplify/api';
import { createAssessmentAndTreatmentPlan } from '../../graphql/mutations'; 
import { handleTreatmentPlanNotification } from "../../utils/emailService";

const LoadingSpinner = ({ type }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-xl flex flex-col items-center">
        <div className="relative">
          {/* Outer circle */}
          <div className="w-16 h-16 border-4 border-teal-200 rounded-full animate-pulse"></div>
          
          {/* Inner spinning circle */}
          <div className="absolute top-0 left-0 w-16 h-16">
            <div className="w-16 h-16 border-4 border-teal-500 border-t-transparent rounded-full animate-spin"></div>
          </div>
        </div>
        
        <div className="mt-4 text-center">
          <p className="text-lg font-semibold text-gray-700">Processing Your {type.toLowerCase()} Assessment</p>
          <p className="text-sm text-gray-500 mt-2">Please wait while we prepare your results...</p>
        </div>
      </div>
    </div>
  );
};

const PatientSelector = ({ onSelect, onCreateNew, existingPatients }) => (
  <div className="min-h-screen bg-gray-50 py-12">
    <h2 className="text-2xl font-semibold text-center mb-8">Select Patient</h2>
    <div className="w-4/5 md:w-1/2 mx-auto space-y-4">
      {existingPatients.map((patient) => (
        <button
          key={patient.id}
          onClick={() => onSelect(patient)}
          className="w-full p-4 text-left border rounded-lg hover:bg-teal-50 hover:border-teal-600"
        >
          <div className="font-medium">{patient.name}</div>
          <div className="text-sm text-gray-600">{patient.email}</div>
        </button>
      ))}
      <button
        onClick={onCreateNew}
        className="w-full p-4 text-center border-2 border-dashed border-teal-600 rounded-lg hover:bg-teal-50"
      >
        + Add New Patient
      </button>
    </div>
  </div>
);

function SubmitRequest() {
  const location = useLocation();
  const navigate = useNavigate();

  const [assessmentType, setAssessmentType] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [readyToProceed, setReadyToProceed] = useState(false);
  const { user } = useAuthenticator((context) => [context.user]);
  const [existingPatients, setExistingPatients] = useState([]);
  const [showNewPatientForm, setShowNewPatientForm] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [treatmentPlan, setTreatmentPlan] = useState(null);

  const [formData, setFormData] = useState({
    patientDetails: null,
    assessment: null,
    userId: null,
    assessmentType: null,
  });

  /**
   * Capture ?type=pain or ?type=beauty from URL on mount
   */
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const type = params.get("type");
    
    // Set initial states based on URL parameter
    if (type) {
        const upperType = type.toUpperCase();
        setAssessmentType(upperType);
        setFormData(prev => ({ 
            ...prev, 
            assessmentType: upperType,
            assessment: null // Reset assessment when type changes
        }));
        setCurrentStep(1);
    } else {
        // If no type parameter, start at step 0
        setAssessmentType(null);
        setFormData(prev => ({
            ...prev,
            assessmentType: null,
            assessment: null
        }));
        setCurrentStep(0);
    }
}, [location.search]); 

  /**
   * Capture the user's ID if logged in
   */
  useEffect(() => {
    if (user) {
      setFormData((prev) => ({ ...prev, userId: user.userId }));
    } else {
      setFormData((prev) => ({ ...prev, userId: null }));
      console.log("No user logged in");
    }
  }, [user]);

  /**
   * Fetch existing patients for this user (if any)
   */
  useEffect(() => {
    if (user) {
      fetchUserPatients();
    }
  }, [user]);

  const fetchUserPatients = async () => {
    try {
      const patients = await DataStore.query(Patient, (p) =>
        p.userPatientsId.eq(user.userId)
      );
      console.log("Patients for user:", patients);
      setExistingPatients(patients);
    } catch (error) {
      console.error("Error fetching patients:", error);
    }
  };

  /**
   * Step 0: Assessment type selection
   */
  const AssessmentTypeSelector = () => (
    <div className="max-w-2xl mx-auto p-6">
      <h2 className="text-2xl font-bold mt-48 text-center mb-8">
        What brings you here today?
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <button
          onClick={() => handleTypeSelection("PAIN")}
          className="p-6 text-center rounded-xl border-2 hover:border-teal-600 hover:bg-teal-50 transition-all"
        >
          <h3 className="text-xl font-semibold mb-2">Pain Management</h3>
          <p className="text-gray-600">Get relief from muscle and joint pain</p>
        </button>

        <button
          onClick={() => handleTypeSelection("BEAUTY")}
          className="p-6 text-center rounded-xl border-2 hover:border-yellow-600 hover:bg-yellow-50 transition-all"
        >
          <h3 className="text-xl font-semibold mb-2">Beauty & Anti-Aging</h3>
          <p className="text-gray-600">Advanced skincare solutions</p>
        </button>
      </div>
    </div>
  );

  const handleTypeSelection = (type) => {
    setAssessmentType(type);
    setFormData((prev) => ({ ...prev, assessmentType: type }));
    setCurrentStep(1);
    navigate(`/submitRequest?type=${type.toLowerCase()}`);
  };

  /**
   * Called from PainAssessmentForm or BeautyAssessmentForm when user completes
   * Step 1 => Move to Step 2 (patient info)
   * Step 2 => Mark ready, then we actually save
   */
  const handleSuccess = (data) => {
    if (currentStep === 1) {
      // Saving the assessment data
      setFormData((prev) => ({
        ...prev,
        assessment: { ...data },
      }));
      handleNextStep(); // proceed to patient info
    } else if (currentStep === 2 && !formSubmitting) {
      // user just created or selected patient
      setFormData((prev) => ({
        ...prev,
        patientDetails: { ...data },
      }));
      setReadyToProceed(true);
    }
  };

  /**
   * If user is ready to proceed at step 2, we save to DataStore
   */
  useEffect(() => {
    if (readyToProceed && currentStep === 2) {
      saveData();
      setReadyToProceed(false); // reset
    }
  }, [readyToProceed, currentStep]);

  const saveData = async () => {
    try {
      console.log('Starting saveData, formSubmitting:', formSubmitting);
      setFormSubmitting(true);

      const client = generateClient({
        authMode: 'userPool',
      });

      // Prepare assessment data based on type
      const assessmentData = {
        assessmentType: formData.assessmentType,
        patientId: formData.patientDetails.id,
        
        // Pain-specific fields
        ...(formData.assessmentType === 'PAIN' && {
          currentMedication: formData.assessment.currentMedication,
          currentMedicationY: formData.assessment.currentMedicationY,
          healthHistory: formData.assessment.healthHistory,
          allergies: formData.assessment.allergies,
          redFlagSigns: formData.assessment.redFlagSigns,
          redFlagSignsOther: formData.assessment.redFlagSignsOther,
          presentingSymptoms: formData.assessment.presentingSymptoms,
          presentingSymptomsOther: formData.assessment.presentingSymptomsOther,
          severityScore: formData.assessment.severityScore,
          onset: formData.assessment.onset,
          onsetOther: formData.assessment.onsetOther,
          location: formData.assessment.location,
          locationOther: formData.assessment.locationOther,
          bodySide: formData.assessment.bodySide,
          bodySideOther: formData.assessment.bodySideOther,
          additionalNotes: formData.assessment.additionalNotes
        }),
  
        // // Beauty-specific fields
    ...(formData.assessmentType === 'BEAUTY' && {
        pregnantOrBreastfeeding: formData.assessment.pregnantOrBreastfeeding === 'Yes',
        skinType: formData.assessment.skinType || [],
        areaOfConcern: formData.assessment.areaOfConcern || [],
        // New importance-based fields
        smootherSkinTone: [formData.assessment.smootherSkinTone],
        improvedSkinTone: [formData.assessment.improvedSkinTone],
        increasedHydrationAndPlumpness: [formData.assessment.increasedHydrationAndPlumpness],
        reducedAppearanceOfFineLinesAndWrinkles: [formData.assessment.reducedAppearanceOfFineLinesAndWrinkles],
        firmerAppearance: [formData.assessment.firmerAppearance],
        // Product usage and allergies
        currentBeautyProducts: formData.assessment.currentBeautyProducts === 'Yes',
        currentBeautyProductsY: formData.assessment.currentBeautyProductsY || "",
        beautyAllergies: formData.assessment.beautyAllergies === 'Yes'
      })
    };

      console.log(' Assessment Data:', JSON.stringify(assessmentData, null, 2));
  
      const response = await client.graphql({
        query: createAssessmentAndTreatmentPlan,
        variables: {
          input: assessmentData,
        },
      });
  
      console.log('Server response:', response.data.createAssessmentAndTreatmentPlan);

      const result = response.data.createAssessmentAndTreatmentPlan;
  
      // Save data to DataStore explicitly
      if (formData.assessmentType === 'PAIN') {
        if (result.painAssessment) {
          await DataStore.save(
            new PainAssessment({
              ...result.painAssessment,
              patientId: formData.patientDetails.id, // Ensure patientId is set
            })
          );
        }
  
        if (result.painTreatmentPlan) {
          await DataStore.save(
            new PainTreatmentPlan({
              ...result.painTreatmentPlan,
              painAssessmentId: result.painAssessment?.id, // Link the assessment ID
            })
          );
        }
      } else if (formData.assessmentType === 'BEAUTY') {
        if (result.beautyAssessment) {
          await DataStore.save(
            new BeautyAssessment({
              ...result.beautyAssessment,
              patientId: formData.patientDetails.id, // Ensure patientId is set
            })
          );
        }
  
        if (result.beautyTreatmentPlan) {
          await DataStore.save(
            new BeautyTreatmentPlan({
              ...result.beautyTreatmentPlan,
              beautyAssessmentId: result.beautyAssessment?.id, // Link the assessment ID
            })
          );
        }
      }
  
      // Set the treatment plan state
      const plan =
        formData.assessmentType === 'PAIN'
          ? result.painTreatmentPlan
          : result.beautyTreatmentPlan;
  
      setTreatmentPlan({
        id: plan.id,
        status: plan.status,
        recommendations: plan.recommendedTreatment,
        type: formData.assessmentType,
        prescriptionFile: plan.prescriptionFile,
      });

       // Send email notification
      await handleTreatmentPlanNotification({
        patient: formData.patientDetails,
        treatmentPlan: plan,
        assessmentType: formData.assessmentType
      });
  
      // Move to the next step in your workflow
      setCurrentStep((prev) => prev + 1);
      setFormSubmitting(false);
    } catch (err) {
      console.error('Error saving assessment:', err);
      throw err;
    } finally {
      setFormSubmitting(false);
    }
  };

  useEffect(() => {
    console.log("Current Form Data:", formData);
  }, [formData]);

  /**
   * Simple step navigation
   */
  const handleNextStep = () => {
    // If user isn't logged in but tries to proceed to step 3
    if (currentStep === 3 && !user) {
      alert("Please sign in to continue");
      return <Authenticator />;
    }
    setCurrentStep((prev) => prev + 1);
  };

  /**
   * Render the final Assessment Page (step 3)
   */
  const renderAssessmentPage = () => {
    const data = formData.assessment;
    
    if (!data) return <div>Loading assessment data...</div>;
    
    if (!assessmentType) {
      return <AssessmentTypeSelector onSelect={(type) => {
        setAssessmentType(type);
        setFormData((prev) => ({ ...prev, assessmentType: type }));
        setCurrentStep(1);
      }} />;
    }
  
    return (
      <AssessmentResult 
        assessment={data}
        type={assessmentType}
        recommendedTreatment={treatmentPlan?.recommendations}
        status={treatmentPlan?.status}
        treatmentPlanId={treatmentPlan?.id}
        prescriptionFile={treatmentPlan?.prescriptionFile}
      />
    );
  };

  /**
   * MAIN RENDER
   */
  return (
    <div className="min-h-screen bg-gray-50">
      {formSubmitting && <LoadingSpinner type={assessmentType} />}
      {/*
        Step 0 - Select the type of assessment
      */}
      {currentStep === 0 && <AssessmentTypeSelector />}

      {/*
        Step 1 - Pain or Beauty Assessment form
      */}
      {currentStep === 1 && assessmentType === "PAIN" && (
        <div className="py-12">
        <PainAssessmentForm
          onSuccess={handleSuccess}
          patientDetails={formData.patientDetails}
        />
        </div>
      )}
      {currentStep === 1 && assessmentType === "BEAUTY" && (
         <div className="py-12">
        <CreateBeautyAssessment
          onSuccess={handleSuccess}
          patientDetails={formData.patientDetails}
        />
         </div>
      )}

      {/*
        Step 2 - Patient information
        If user is signed in, show the list of existing patients or the form if they choose "Add New".
      */}
 
      {currentStep === 2 && (
        <>
          {user && !showNewPatientForm ? (
            <PatientSelector
              existingPatients={existingPatients}
              onSelect={(patient) => {
                handleSuccess(patient);
              }}
              onCreateNew={() => setShowNewPatientForm(true)}
            />
          ) : (
            <div className="min-h-screen bg-gray-50 py-12">
              {/* Back Arrow at the Top */}
              {user && (
                <div className="flex justify-start px-4 mb-4">
                  <div
                    onClick={() => setShowNewPatientForm(false)}
                    className="inline-flex items-center text-gray-700 cursor-pointer hover:text-gray-900"
                  >
                    <svg
                      className="w-5 h-5 mr-1"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M15 18l-6-6 6-6" />
                    </svg>
                    <span>Back</span>
                  </div>
                </div>
              )}

              <h2 className="mb-12 px-12 text-center">
                {user
                  ? "Create New Patient"
                  : "Before we proceed, we need to know a bit more about you"}
              </h2>

              <PatientCreateForm
                onSuccess={handleSuccess}
                className="w-4/5 md:w-1/2 mx-auto"
                userId={user?.username}
              />
            </div>
          )}
        </>
      )}


      {/*
        Step 3 - Display the appropriate "Green/Amber/Red" or Beauty results
      */}
      {currentStep === 3 && (
        <div className="container mx-auto px-4 py-8">{renderAssessmentPage()}</div>
      )}
      {/*
        Step 4 - Confirmation
      */}
      {currentStep === 4 && (
        <div className="m-5">
          <h2>Done</h2>
          <p>Your request has been submitted successfully.</p>
          <Button onClick={() => setCurrentStep(0)} className="bg-teal-700 mt-3">
            Start New Request
          </Button>
        </div>
      )}
    </div>
  );
}

export default SubmitRequest;
