import * as React from "react";
import { useState } from "react";
import posh_model from "../../assets/posh_woman.png";

function HomePagePoshRx(props) {
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
    const [hoveredCard, setHoveredCard] = useState(null);

    const handleMouseMove = (e, cardElement, cardId) => {
        const rect = cardElement.getBoundingClientRect();
        const x = ((e.clientX - rect.left) / rect.width) * 100;
        const y = ((e.clientY - rect.top) / rect.height) * 100;
        setMousePosition({ x, y });
      };

    const scrollDown = (e) => {
        e.preventDefault();
        window.scrollBy({
            top: 700,
            behavior: 'smooth',
        });
    };

    function FAQSectionPoshRx() {
        const [openIndex, setOpenIndex] = React.useState(null);

        const faqs = [
            {
                question: "What makes PoshRx formulations unique?",
                answer: "PoshRx formulations are crafted using cutting-edge ingredients and advanced delivery systems to ensure optimal absorption and efficacy. Each product is tailored to address specific skin concerns, providing professional-grade results."
            },
            {
                question: "Are PoshRx products suitable for all skin types?",
                answer: "Yes, PoshRx products are designed with lightweight, non-greasy bases that are gentle on all skin types, including sensitive skin. Our formulations prioritize skin health and comfort."
            },
            {
                question: "Can I customize a formulation for my needs?",
                answer: "Our formulations are already specifically tailored based on your questionnaire responses to ensure optimal results for your unique concerns and skin type. Each formula is customized using our advanced assessment system."
              },
              {
                question: "Do I need a prescription for PoshRx products?",
                answer: "Yes, all PoshRx products require a prescription as they contain clinical-grade active ingredients. Our healthcare professionals will review your assessment and prescribe the appropriate formulation for your needs."
              },
            {
                question: "How do I incorporate PoshRx products into my skincare routine?",
                answer: "Each product includes detailed usage instructions. For a personalized skincare regimen, consult a skincare professional who can recommend the best combination of products based on your skin type and goals."
            },
            {
                question: "Can PoshRx help with specific concerns like acne, rosacea, or hyperpigmentation?",
                answer: "Yes, PoshRx offers targeted solutions for a wide range of skin concerns, including acne, rosacea, hyperpigmentation, and more. Explore our product lines to find the right solution for you."
            },
            {
                question: "How long does it take to see results with PoshRx products?",
                answer: "Results vary based on the product and individual skin type. Many users notice improvements within a few weeks of consistent use, with optimal results seen over time."
            },
        ];

        const handleClick = (index) => {
            setOpenIndex(openIndex === index ? null : index);
        };

        return (
            <section className="bg-black text-yellow-500 py-10">
                <div className="container px-6 py-10 mx-auto">
                    <h1 className="text-2xl font-semibold lg:text-3xl">
                    You Have Questions. We Have Answers
                    </h1>
                    <hr className="my-6 border-yellow-400"/>
                    <div>
                        {faqs.map((faq, index) => (
                            <div key={index}>
                                <div
                                    className="flex items-center cursor-pointer mb-4"
                                    onClick={() => handleClick(index)}
                                >
                                    <svg
                                        className={`w-6 h-6 mr-4 transition-transform transform ${
                                            openIndex === index ? "rotate-180" : ""
                                        }`}
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d={openIndex === index ? "M20 12H4" : "M12 4v16m8-8H4"}
                                        />
                                    </svg>
                                    <h2 className="text-xl">{faq.question}</h2>
                                </div>
                                <div
                                    className={`overflow-hidden transition-all duration-300 ${
                                        openIndex === index ? "max-h-40" : "max-h-0"
                                    }`}
                                >
                                    <p className="ml-10 text-base text-yellow-300">
                                        {faq.answer}
                                    </p>
                                </div>
                                <hr className="my-4 border-yellow-400"/>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        );
    }

    return (
        <>
            <div className="mb-3 relative flex flex-col-reverse py-16 lg:pt-0 lg:flex-col lg:pb-0 bg-black text-yellow-500">
                <div
                    className="inset-y-0 top-0 right-0 z-0 w-full max-w-xl px-4 mx-auto md:px-0 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-7/12 lg:max-w-full lg:absolute xl:px-0">
                    {/* <svg
                        className="absolute left-96 hidden h-full text-yellow-500 transform -translate-x-1/2 lg:block"
                        viewBox="0 0 100 100"
                        fill="currentColor"
                        preserveAspectRatio="none slice"
                    >
                        <path d="M50 0H100L50 100H0L50 0Z"/>
                    </svg> */}
                    <img
                        className="object-cover mx-auto md:ml-24 rounded shadow-lg lg:rounded-none lg:shadow-none h-42 md:h-96 lg:h-full"
                        // src='https://mariamarlowe.com/wp-content/uploads/2022/02/banner.png'
                        src={posh_model}
                        alt="Luxurious beauty treatments"                       
                    />
                </div>
                
                <div
                    className="relative flex flex-col items-start w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:max-w-screen-xl">
                    <div className="mb-16 lg:my-40 lg:max-w-lg lg:pr-5">
                        <h2 className="mb-5 font-sans text-3xl font-bold tracking-tight text-yellow-500 sm:text-4xl sm:leading-none">
                            Personalized Skincare,
                            <br className="hidden md:block"/>
                            Perfected for <span className="bg-gradient-to-r from-yellow-400 to-yellow-800 bg-clip-text text-transparent cursor-pointer hover:opacity-80 transition-opacity" onClick={() => window.location.href='/submitRequest'}>
                            You
                        </span>
                        </h2>
                        <p className="pr-5 mb-5 text-base text-yellow-300 md:text-lg">
                        Discover breakthrough skincare formulations. Our advanced anti-aging treatments are crafted for precision and efficacy, helping you achieve timeless beauty.
                        </p>
                        <div className="flex items-center">
                            <a
                                href="/submitRequest"
                                className="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-black transition duration-300 rounded shadow-md bg-yellow-500 hover:bg-yellow-600 focus:shadow-outline focus:outline-none"
                            >
                                Get Started
                            </a>
                            <a
                                href="/"
                                onClick={scrollDown}
                                aria-label=""
                                className="inline-flex items-center font-semibold text-yellow-400 transition-colors duration-300 hover:text-yellow-600"
                            >
                                Learn More
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="max-w-[70%] mx-auto mt-24 grid gap-12 row-gap-5 mb-8 md:grid-cols-2 lg:grid-cols-3">    
       
                <div className="relative overflow-hidden transition duration-300 transform rounded-xl shadow-lg hover:scale-105 hover:shadow-xl bg-yellow-600 flex flex-col justify-between">
                    <div className="p-6">
                        <h6 className="font-semibold text-xl mb-3 text-black">Glow</h6>
                        <p className="text-sm text-black/90 mb-4">
                            Advanced solutions for anti-aging, brightening, and enhancing your skin's natural glow.
                        </p>
                    </div>
                    <div className="h-46 overflow-hidden flex items-center justify-center">
                        <img 
                            src="https://example.com/glow.jpg"
                            alt="Glow products"
                            className="object-contain h-40 w-4/5 mx-auto"
                        />
                    </div>
                </div>

            </div> */}
            
            <div className="max-w-[70%] mx-auto mt-24 grid gap-12 row-gap-5 mb-8 md:grid-cols-2 lg:grid-cols-3">    
            {/* Glow&Plump Card */}
            <div 
            className="relative overflow-hidden transform rounded-xl shadow-lg hover:scale-105 hover:shadow-xl flex flex-col justify-between"
            onMouseMove={(e) => handleMouseMove(e, e.currentTarget)}
            onMouseEnter={() => setHoveredCard('card1')}
            onMouseLeave={() => setHoveredCard(null)}
            style={{
                background: hoveredCard === 'card1' 
                ? `radial-gradient(circle at ${mousePosition.x}% ${mousePosition.y}%, rgb(250 204 21), rgb(234 179 8))`
                : 'rgb(234 179 8)',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                willChange: 'transform, background'
            }}
            >
                <div className="p-6">
                <h6 className="font-semibold text-xl mb-3 text-black">Glow&Plump</h6>
                <p className="text-sm text-black/90 mb-4">
                Premium anti-aging formula to improve fine lines and enhance hydration for radiant, plump skin.
                </p>
                </div>
                <div className="h-46 overflow-hidden flex items-center justify-center">
                <img 
                    src="https://static.vecteezy.com/system/resources/previews/036/095/098/non_2x/ai-generated-white-cosmetic-face-creme-body-lotion-bb-cc-cream-texture-material-on-transparent-background-free-png.png" 
                    alt="Glow&Plump"
                    className="object-contain h-40 w-4/5 mx-auto"
                />
                </div>
            </div>

            {/* Glow&Hydrate Card */}
            <div 
            className="relative overflow-hidden transform rounded-xl shadow-lg hover:scale-105 hover:shadow-xl flex flex-col justify-between"
            onMouseMove={(e) => handleMouseMove(e, e.currentTarget)}
            onMouseEnter={() => setHoveredCard('card2')}
            onMouseLeave={() => setHoveredCard(null)}
            style={{
                background: hoveredCard === 'card2' 
                ? `radial-gradient(circle at ${mousePosition.x}% ${mousePosition.y}%, rgb(250 204 21), rgb(234 179 8))`
                : 'rgb(234 179 8)',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                willChange: 'transform, background'
            }}
            >
                <div className="p-6">
                <h6 className="font-semibold text-xl mb-3 text-black">Glow&Hydrate</h6>
                <p className="text-sm text-black/90 mb-4">
                Deep hydrating and brightening formula for a naturally radiant complexion.
                </p>
                </div>
                <div className="h-46 overflow-hidden flex items-center justify-center">
                <img 
                    src="https://static.vecteezy.com/system/resources/thumbnails/022/152/926/small_2x/white-cream-isolated-for-element-png.png"
                    alt="Glow&Hydrate"
                    className="object-contain h-40 w-4/5 mx-auto"
                />
                </div>
            </div>

            {/* Glow&Bright Card */}
            <div 
            className="relative overflow-hidden transform rounded-xl shadow-lg hover:scale-105 hover:shadow-xl flex flex-col justify-between"
            onMouseMove={(e) => handleMouseMove(e, e.currentTarget)}
            onMouseEnter={() => setHoveredCard('card3')}
            onMouseLeave={() => setHoveredCard(null)}
            style={{
                background: hoveredCard === 'card3' 
                ? `radial-gradient(circle at ${mousePosition.x}% ${mousePosition.y}%, rgb(250 204 21), rgb(234 179 8))`
                : 'rgb(234 179 8)',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                willChange: 'transform, background'
            }}
            >
                <div className="p-6">
                <h6 className="font-semibold text-xl mb-3 text-black">Glow&Bright</h6>
                <p className="text-sm text-black/90 mb-4">
                Advanced brightening treatment for antioxidant protection and glowing skin.
                </p>
                </div>
                <div className="h-46 overflow-hidden flex items-center justify-center">
                <img 
                    src="https://png.pngtree.com/png-vector/20220527/ourmid/pngtree-cosmetic-lotion-texture-cream-smear-png-image_4747983.png"
                    alt="Glow&Bright"
                    className="object-contain h-40 w-4/5 mx-auto"
                />
                </div>
            </div>
            {/* Glow&Smooth Card */}
            <div 
            className="relative overflow-hidden transform rounded-xl shadow-lg hover:scale-105 hover:shadow-xl flex flex-col justify-between"
            onMouseMove={(e) => handleMouseMove(e, e.currentTarget)}
            onMouseEnter={() => setHoveredCard('card4')}
            onMouseLeave={() => setHoveredCard(null)}
            style={{
                background: hoveredCard === 'card4' 
                ? `radial-gradient(circle at ${mousePosition.x}% ${mousePosition.y}%, rgb(250 204 21), rgb(234 179 8))`
                : 'rgb(234 179 8)',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                willChange: 'transform, background'
            }}
            >
            <div className="p-6">
                <h6 className="font-semibold text-xl mb-3 text-black">Glow&Smooth</h6>
                <p className="text-sm text-black/90 mb-4">
                Targeted treatment to reduce the appearance of fine lines and wrinkles.
                </p>
            </div>
            <div className="h-46 overflow-hidden flex items-center justify-center">
                <img 
                src="https://png.pngtree.com/png-vector/20220723/ourmid/pngtree-cream-texture-creme-vector-shaving-png-image_6047661.png"
                alt="Glow&Smooth"
                className="object-contain h-40 w-4/5 mx-auto"
                />
            </div>
            </div>

            {/* Glow&Plump-Eyes Card */}
            <div 
            className="relative overflow-hidden transform rounded-xl shadow-lg hover:scale-105 hover:shadow-xl flex flex-col justify-between"
            onMouseMove={(e) => handleMouseMove(e, e.currentTarget)}
            onMouseEnter={() => setHoveredCard('card5')}
            onMouseLeave={() => setHoveredCard(null)}
            style={{
                background: hoveredCard === 'card5' 
                ? `radial-gradient(circle at ${mousePosition.x}% ${mousePosition.y}%, rgb(250 204 21), rgb(234 179 8))`
                : 'rgb(234 179 8)',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                willChange: 'transform, background'
            }}
            >
            <div className="p-6">
                <h6 className="font-semibold text-xl mb-3 text-black">Glow&Plump-Eyes</h6>
                <p className="text-sm text-black/90 mb-4">
                Delicate eye treatment specially formulated for a more youthful appearance.
                </p>
            </div>
            <div className="h-46 overflow-hidden flex items-center justify-center">
                <img 
                src="https://png.pngtree.com/png-vector/20220525/ourmid/pngtree-cream-texture-cosmetic-smear-swatch-png-image_4734310.png"
                alt="Glow&Plump-Eyes"
                className="object-contain h-40 w-4/5 mx-auto"
                />
            </div>
            </div>
            </div>

            <section className="py-12 mt-24 bg-black text-yellow-500 sm:py-16 lg:py-20">
                <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div className="flex flex-col items-center">
                        <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">
                            Trusted by Skincare Enthusiasts
                        </h2>
                        <p className="mt-3 text-xl text-yellow-300 sm:mt-4">
                            Hear what our clients say about their journey with PoshRx.
                        </p>
                    </div>

                    {/* Testimonial Cards */}
                    <div className="grid grid-cols-1 gap-8 mt-12 sm:grid-cols-2 lg:grid-cols-3">
                    {/* Review Card 1 */}
                    <div className="relative p-6 bg-black border border-yellow-500 shadow-xl rounded-2xl">
                        <div className="flex items-center space-x-3">
                        <img
                            src="https://randomuser.me/api/portraits/women/32.jpg"
                            alt="Sarah M."
                            className="w-12 h-12 rounded-full"
                        />
                        <div>
                            <h3 className="text-lg font-medium text-yellow-400">Sarah M.</h3>
                            <p className="text-sm text-yellow-300">Toronto, ON</p>
                        </div>
                        </div>
                        <div className="flex mt-4 text-yellow-400">
                        {"★".repeat(5)}
                        </div>
                        <p className="mt-4 text-base text-yellow-300">
                        "The Glow&Plump serum has completely transformed my skincare routine. My skin feels more hydrated and looks visibly younger!"
                        </p>
                    </div>

                    {/* Review Card 2 */}
                    <div className="relative p-6 bg-black border border-yellow-500 shadow-xl rounded-2xl">
                        <div className="flex items-center space-x-3">
                        <img
                            src="https://randomuser.me/api/portraits/women/44.jpg"
                            alt="Emily R."
                            className="w-12 h-12 rounded-full"
                        />
                        <div>
                            <h3 className="text-lg font-medium text-yellow-400">Emily R.</h3>
                            <p className="text-sm text-yellow-300">Vancouver, BC</p>
                        </div>
                        </div>
                        <div className="flex mt-4 text-yellow-400">
                        {"★".repeat(5)}
                        </div>
                        <p className="mt-4 text-base text-yellow-300">
                        "I've tried many eye creams, but Glow&Plump-Eyes is in a league of its own. The results were noticeable within weeks!"
                        </p>
                    </div>

                    {/* Review Card 3 */}
                    <div className="relative p-6 bg-black border border-yellow-500 shadow-xl rounded-2xl">
                        <div className="flex items-center space-x-3">
                        <img
                            src="https://randomuser.me/api/portraits/women/68.jpg"
                            alt="Michelle L."
                            className="w-12 h-12 rounded-full"
                        />
                        <div>
                            <h3 className="text-lg font-medium text-yellow-400">Michelle L.</h3>
                            <p className="text-sm text-yellow-300">Montreal, QC</p>
                        </div>
                        </div>
                        <div className="flex mt-4 text-yellow-400">
                        {"★".repeat(5)}
                        </div>
                        <p className="mt-4 text-base text-yellow-300">
                        "The entire PoshRx line has elevated my skincare game. My complexion is brighter and more radiant than ever!"
                        </p>
                    </div>
                    </div>
                    <div className="mt-12 text-center">
                    <button 
                        onClick={() => window.location.href='/submitRequest'}
                        className="inline-flex items-center px-6 py-3 text-base font-medium text-white bg-yellow-500 border-transparent rounded-md hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 transition-all duration-300"
                        style={{
                            boxShadow: '0 0 20px rgba(255, 255, 255, 0.4), 0 0 40px rgba(255, 255, 255, 0.2), 0 0 60px rgba(255, 255, 255, 0.1)'
                        }}
                        >   
                        Find your Glow
                    </button>
                    </div>
                </div>
            </section>

            {/* FAQ Section */}
            <FAQSectionPoshRx />
        </>
    );
}

export default HomePagePoshRx;
