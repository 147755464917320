import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataStore } from "aws-amplify/datastore";
import {
  BeautyAssessment,
  BeautyTreatmentPlan,
  PainAssessment,
  PainTreatmentPlan,
  Patient,
} from "../../models";
import { formatField, formatDate } from './components/shared/utils.js';
import { StatusLabel } from './components/shared/StatusLabel.jsx';
import { TypeToggle } from './components/shared/TypeToggle.jsx';
import { Pagination, usePagination } from './components/shared/Pagination.jsx';
import TableLoadingSkeleton from './components/shared/TableSkeleton'

const HCPRefillButton = () => {
  const navigate = useNavigate();
  return (
    <button
      onClick={() => navigate('/prescription-managment')}
      className="flex items-center space-x-2 bg-teal-700 hover:bg-teal-800 text-white font-medium py-2 px-4 rounded-lg transition-colors duration-200"
    >
      <svg 
        className="w-6 h-6" 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 24 24" 
        fill="currentColor"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <path d="M20.207 3.793a5.95 5.95 0 0 1 0 8.414l-8 8a5.95 5.95 0 0 1 -8.414 -8.414l8 -8a5.95 5.95 0 0 1 8.414 0m-7 1.414l-4.294 4.293l5.586 5.586l4.294 -4.292a3.95 3.95 0 1 0 -5.586 -5.586" />
      </svg>
      <span>Check Prescription Refill Requests</span>
    </button>
  );
};

const Admin = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [statusFilter, setStatusFilter] = useState("All");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [expandedRows, setExpandedRows] = useState({
    PAIN: {},
    BEAUTY: {}
  });
  const [selectedType, setSelectedType] = useState("PAIN");
  const [painAssessments, setPainAssessments] = useState([]);
  const [filteredPainAssessments, setFilteredPainAssessments] = useState([]);
  const [beautyAssessments, setBeautyAssessments] = useState([]);
  const [filteredBeautyAssessments, setFilteredBeautyAssessments] = useState([]);
  const [filteredAssessments, setFilteredAssessments] = useState([]);
  const [comments, setComments] = useState({});
  

  useEffect(() => {
    setExpandedRows({
      PAIN: {},
      BEAUTY: {}
    });
  }, [selectedType]);

  const {
    currentPage,
    pageCount,
    indexOfFirstItem,
    indexOfLastItem,
    currentItems,
    handlePageChange
  } = usePagination(filteredAssessments);
  

  const toggleDetails = (index) => {
    setExpandedRows(prevState => ({
      ...prevState,
      [selectedType]: {
        ...prevState[selectedType],
        [index]: !prevState[selectedType][index]
      }
    }));
  };

  const fetchAssessments = async () => {
    try {
      setLoading(true);
      
      // Fetch Pain Assessments
      const painAssessmentsData = await DataStore.query(PainAssessment);
      const painWithDetails = await Promise.all(
        painAssessmentsData.map(async (assessment) => {
          const patient = await DataStore.query(Patient, assessment.patientId);
          const treatmentPlans = await DataStore.query(
            PainTreatmentPlan,
            plan => plan.painAssessmentId.eq(assessment.id)
          );
          return { ...assessment, patient, treatmentPlans };
        })
      );
      setPainAssessments(painWithDetails);
      setFilteredPainAssessments(painWithDetails);
  
      // Fetch Beauty Assessments
      const beautyAssessmentsData = await DataStore.query(BeautyAssessment);
      const beautyWithDetails = await Promise.all(
        beautyAssessmentsData.map(async (assessment) => {
          const patient = await DataStore.query(Patient, assessment.patientId);
          const treatmentPlans = await DataStore.query(
            BeautyTreatmentPlan,
            plan => plan.beautyAssessmentId.eq(assessment.id)
          );
          return { ...assessment, patient, treatmentPlans };
        })
      );
      setBeautyAssessments(beautyWithDetails);
      setFilteredBeautyAssessments(beautyWithDetails);
  
      setLoading(false);
    } catch (error) {
      console.error('Error fetching assessments:', error);
      setError(error.message);
      setLoading(false);
    }
  };

  // Fetch assessments on component mount
  useEffect(() => {
    fetchAssessments();
  }, []);

  // Filter assessments based on status
  useEffect(() => {
    const assessments = selectedType === 'PAIN' ? painAssessments : beautyAssessments;
    const filtered = statusFilter === 'All'
      ? assessments
      : assessments.filter(assessment => 
          assessment.treatmentPlans.some(plan => plan.status === statusFilter)
        );
        
    if (selectedType === 'PAIN') {
      setFilteredPainAssessments(filtered);
    } else {
      setFilteredBeautyAssessments(filtered);
    }
  }, [statusFilter, painAssessments, beautyAssessments, selectedType]);

  // Update filtered assessments when type changes
  useEffect(() => {
    const current = selectedType === 'PAIN' ? filteredPainAssessments : filteredBeautyAssessments;
    setFilteredAssessments(current);
  }, [selectedType, filteredPainAssessments, filteredBeautyAssessments]);

  // Search functionality
  const filterAssessments = (data) => {
    // First filter by search term
    const filtered = !searchTerm ? data : data.filter(item => 
      item.patient?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.patient?.email?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    
    // Then sort by creation date
    return filtered.sort((a, b) => {
      const dateA = new Date(a.treatmentPlans[0]?.createdAt || 0);
      const dateB = new Date(b.treatmentPlans[0]?.createdAt || 0);
      return dateB - dateA; // Descending order (most recent first)
    });
  }

  const deleteAssessment = async (assessmentId, type = 'PAIN') => {
    try {
      const AssessmentModel = type === 'PAIN' ? PainAssessment : BeautyAssessment;
      const TreatmentModel = type === 'PAIN' ? PainTreatmentPlan : BeautyTreatmentPlan;

      // First delete associated treatment plans
      const treatmentPlans = await DataStore.query(TreatmentModel, 
        plan => type === 'PAIN' 
          ? plan.painAssessmentId.eq(assessmentId)
          : plan.beautyAssessmentId.eq(assessmentId)
      );

      for (const plan of treatmentPlans) {
        await DataStore.delete(TreatmentModel, plan.id);
      }

      // Then delete the assessment
      const assessment = await DataStore.query(AssessmentModel, assessmentId);
      if (assessment) {
        await DataStore.delete(AssessmentModel, assessmentId);
        await fetchAssessments(); // Refresh the data
        alert('Assessment deleted successfully!');
      }
    } catch (error) {
      console.error("Error deleting assessment:", error);
      alert('Error deleting assessment. Please try again.');
    }
  };

  const AVAILABLE_STATUSES = [
    'SEE_DOCTOR',
    'PENDING_REVIEW',
    'UNPAID',
    'PAID',
    'SHIPPED'
  ];

  // Update filtered assessments whenever searchTerm changes
  useEffect(() => {
    const assessments = selectedType === 'PAIN' ? painAssessments : beautyAssessments;
    const filteredData = filterAssessments(assessments);
    
    if (selectedType === 'PAIN') {
      setFilteredPainAssessments(filteredData);
    } else {
      setFilteredBeautyAssessments(filteredData);
    }
  }, [searchTerm, painAssessments, beautyAssessments, selectedType]);

  if (loading) return <TableLoadingSkeleton />;
  if (error) return <p>Error: {error}</p>;

  const updateTreatmentStatus = async (id, newStatus, type = 'PAIN') => {
    try {
      const TreatmentModel = type === 'PAIN' ? PainTreatmentPlan : BeautyTreatmentPlan;
      const treatmentPlan = await DataStore.query(TreatmentModel, id);
      
      if (treatmentPlan) {
        await DataStore.save(
          TreatmentModel.copyOf(treatmentPlan, updated => {
            updated.status = newStatus;
          })
        );
        await fetchAssessments();
        alert('Status updated successfully!');
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleCommentChange = (assessmentId, newComment) => {
    setComments(prev => ({
      ...prev,
      [assessmentId]: newComment
    }));
  };

  const saveComments = async (id, updatedComment, type) => {
    try {
      const TreatmentModel = type === 'PAIN' ? PainTreatmentPlan : BeautyTreatmentPlan;
      const treatmentPlan = await DataStore.query(TreatmentModel, id);
      
      if (treatmentPlan) {
        await DataStore.save(
          TreatmentModel.copyOf(treatmentPlan, updated => {
            updated.additionalComments = updatedComment;
          })
        );
        alert('Comments updated successfully!');
      }
    } catch (error) {
      console.error("Error saving comments:", error);
    }
  };

  const renderPatientInfo = (item) => (
    <div>
      <h4 className="font-semibold">Patient Information</h4>
      <p><strong>Name:</strong> {formatField(item.patient.name)}</p>
      <p><strong>Email:</strong> {item.patient.email}</p>
      <p><strong>Phone:</strong> {item.patient.phone}</p>
      <p><strong>Address:</strong> {item.patient.address}</p>
      <p><strong>Date of Birth:</strong> {formatDate(item.patient.dateOfBirth)}</p>
      <p><strong>Sex:</strong> {item.patient.sex || 'Not Specified'}</p>
    </div>
  );

  const renderPainAssessment = (item) => (
    <div>
      <h4 className="font-semibold">Pain Assessment</h4>
      <p><strong>Severity Score:</strong> {formatField(item.severityScore)}</p>
      <p><strong>Location:</strong> {formatField(item.location?.join(', '))}</p>
      <p><strong>Symptoms:</strong> {formatField(item.presentingSymptoms?.join(', '))}</p>
      <p><strong>Current Medication:</strong> {formatField(item.currentMedication)}</p>
      {item.currentMedication === 'Yes' && (
        <p><strong>Medication Details:</strong> {formatField(item.currentMedicationY)}</p>
      )}
      <p><strong>Allergies:</strong> {formatField(item.allergies?.join(', '))}</p>
      <p><strong>Health History:</strong> {formatField(item.healthHistory?.join(', '))}</p>
    </div>
  );

  const renderBeautyAssessment = (item) => (
    <div>
      <h4 className="font-semibold">Beauty Assessment</h4>
      <p>
        <strong>Areas of Concern:</strong>{' '}
        {item.areaOfConcern?.length > 0 
          ? formatField(item.areaOfConcern.join(', ')) 
          : 'None reported'}
      </p>
      <p>
        <strong>Anti-Aging Concerns:</strong>{' '}
        {item.antiAgingConcern?.length > 0 
          ? formatField(item.antiAgingConcern.join(', ')) 
          : 'None reported'}
      </p>
      <p>
        <strong>Desired Outcomes:</strong>{' '}
        {item.desiredOutcomes?.length > 0 
          ? formatField(item.desiredOutcomes.join(', ')) 
          : 'None reported'}
      </p>
      <p>
        <strong>Current Beauty Products:</strong>{' '}
        {item.currentBeautyProducts 
          ? `Yes - ${item.currentBeautyProductsY || 'No details provided'}`
          : 'No'}
      </p>
      <p>
        <strong>Beauty Allergies:</strong>{' '}
        {item.beautyAllergies 
          ? 'Yes'
          : 'No'}
      </p>
      <p>
        <strong>Skin Type:</strong>{' '}
        {item.skinType?.length > 0 
          ? formatField(item.skinType.join(', ')) 
          : 'Not specified'}
      </p>
      <p>
        <strong>Pregnant or Breastfeeding:</strong>{' '}
        {item.pregnantOrBreastfeeding ? 'Yes' : 'No'}
      </p>
    </div>
  );

  return (
    <div className='bg-gray-50'>
    <div className="flex justify-between items-center md:px-16 lg:px-[16rem]">
    <h1 className="py-12 mt-6 text-3xl text-gray-900">
      Hello Admin!
    </h1>
    <div className="py-12 mt-6">
      <HCPRefillButton />
    </div>
  </div>
      <TypeToggle selectedType={selectedType} onSelectType={setSelectedType} />
      <section className="container px-4 my-16 mx-auto">
        <div className="flex flex-col">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="mx-auto max-w-screen-xl px-4 lg:px-12">
                <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
                  {/* Search and Filter Section */}
                  <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
                    <form className="flex items-center w-full md:w-1/2">
                      <label htmlFor="simple-search" className="sr-only">Search</label>
                      <div className="relative w-full">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                          <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"/>
                          </svg>
                        </div>
                        <input
                          type="text"
                          id="simple-search"
                          className="bg-gray-700 border border-gray-600 text-black text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2.5 dark:bg-gray-50 dark:border-gray-300 dark:text-gray-900 dark:placeholder-gray-400"
                          placeholder="Search Patients"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                      </div>
                    </form>
                    {/* Status Filter Dropdown */}
                    <div className="relative">
                      <button
                        onClick={() => setDropdownOpen(!dropdownOpen)}
                        className="inline-flex justify-center w-full rounded-md border shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" className="h-4 w-4 mr-2 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z" clipRule="evenodd"/>
                        </svg>
                        Filter
                        <svg className="ml-1.5 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"/>
                        </svg>
                      </button>
                      <div className={`${dropdownOpen ? 'block' : 'hidden'} absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none`}>
                        <div className="py-1">
                          {['All', 'SEE_DOCTOR', 'PENDING_REVIEW', 'UNPAID', 'PAID', 'SHIPPED'].map((status) => (
                            <button
                              key={status}
                              onClick={() => {
                                setStatusFilter(status);
                                setDropdownOpen(false);
                              }}
                              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            >
                              {status.split('_').map(word => word.charAt(0) + word.slice(1).toLowerCase()).join(' ')}
                            </button>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Table */}
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-teal-700">
                      <tr>
                        <th scope="col" className="px-4 py-3.5 text-lg font-bold text-left rtl:text-right text-white">Date</th>
                        <th scope="col" className="px-4 py-3.5 text-lg font-bold text-left rtl:text-right text-white">Patient</th>
                        <th scope="col" className="px-4 py-3.5 text-lg font-bold text-left rtl:text-right text-white">Status</th>
                        <th scope="col" className="px-4 py-3.5 text-lg font-bold text-left rtl:text-right text-white">
                          {selectedType === 'PAIN' ? 'Presenting Symptoms' : 'Areas of Concern'}
                        </th>
                        <th scope="col" className="relative py-3.5 px-4"><span className="sr-only">Actions</span></th>
                      </tr>
                    </thead>

                    <tbody className="bg-white divide-y divide-gray-300">
                      {currentItems.length > 0 ? (
                        currentItems.map((item, index) => (
                          <React.Fragment key={index}>
                            <tr>
                              <td className="px-4 py-4 text-base text-gray-600 whitespace-nowrap">
                                {item.treatmentPlans[0]?.createdAt ? formatDate(item.treatmentPlans[0].createdAt) : 'No date'}
                              </td>
                              <td className="px-4 py-4 text-base text-gray-600 whitespace-nowrap">
                                {formatField(item.patient.name)}
                              </td>
                              <td className="px-4 py-4 text-base text-gray-600 whitespace-nowrap">
                                {item.treatmentPlans && item.treatmentPlans[0] ? (
                                  <StatusLabel status={item.treatmentPlans[0].status} />
                                ) : 'No Status'}
                              </td>
                              <td className="px-4 py-4 text-base text-gray-600 whitespace-nowrap">
                                {selectedType === 'PAIN' 
                                  ? (item.presentingSymptoms && item.presentingSymptoms.length > 0 ? formatField(item.presentingSymptoms[0]) : 'No Symptoms Reported')
                                  : (item.concernAreas && item.concernAreas.length > 0 ? formatField(item.concernAreas[0]) : 'No Concerns Reported')}
                              </td>
                              <td className="py-4 flex justify-end px-4 text-base whitespace-nowrap space-x-4">
                                <button
                                  onClick={() => toggleDetails(index)}
                                  className="text-teal-700 transition-colors duration-200 hover:text-teal-900 focus:outline-none"
                                >
                                  {expandedRows[selectedType][index] ? 'Collapse' : 'View'}
                                </button>
                                <button
                                  onClick={() => {
                                    if (window.confirm('Are you sure you want to delete this assessment? This action cannot be undone.')) {
                                      deleteAssessment(item.id, selectedType);
                                    }
                                  }}
                                  className="text-red-600 hover:text-red-800 focus:outline-none"
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                            
                            {/* Expanded Details Row */}
                            <tr>
                              <td colSpan="5" className="p-0">
                                <div className={`overflow-hidden transition-all duration-500 ${expandedRows[selectedType][index] ? 'max-h-[2000px] opacity-100' : 'max-h-0 opacity-0'}`}>
                                  <div className="p-4 bg-gray-100">
                                    <h3 className="text-lg font-semibold">
                                      Patient and {selectedType === 'PAIN' ? 'Pain' : 'Beauty'} Assessment Details
                                    </h3>
                                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 text-sm">
                                      {renderPatientInfo(item)}
                                      {selectedType === 'PAIN' ? renderPainAssessment(item) : renderBeautyAssessment(item)}
                                      
                                      {/* Treatment Plan Section */}
                                      <div>
                                        <h4 className="font-semibold">Treatment Plan</h4>
                                        <div className="flex items-center gap-4">
                                          <p><strong>Status:</strong></p>
                                          {item.treatmentPlans && item.treatmentPlans[0] && (
                                            <select
                                              value={item.treatmentPlans[0].status}
                                              onChange={(e) => updateTreatmentStatus(
                                                item.treatmentPlans[0].id,
                                                e.target.value,
                                                selectedType
                                              )}
                                              className="border rounded p-1 bg-white"
                                            >
                                              {AVAILABLE_STATUSES.map(status => (
                                                <option key={status} value={status}>
                                                  {status.split('_').map(word => 
                                                    word.charAt(0) + word.slice(1).toLowerCase()
                                                  ).join(' ')}
                                                </option>
                                              ))}
                                            </select>
                                          )}
                                        </div>
                                        <p><strong>Recommendation:</strong> {item.treatmentPlans?.[0]?.recommendedTreatment || 'N/A'}</p>

                                        {/* Comments Section */}
                                        <div className="mt-4">
                                          <label className="block">
                                            <span className="font-semibold">Comments:</span>
                                            <div className="mt-2 flex flex-col space-y-2">
                                              <input
                                                type="text"
                                                value={comments[item.id] || item.treatmentPlans[0]?.additionalComments || ''}
                                                onChange={(e) => handleCommentChange(item.id, e.target.value)}
                                                className="border rounded p-1 w-full"
                                                placeholder="Add comments..."
                                              />
                                              <button
                                                onClick={() => {
                                                  if (item.treatmentPlans[0]?.id) {
                                                    saveComments(
                                                      item.treatmentPlans[0].id,
                                                      comments[item.id] || '',
                                                      selectedType
                                                    );
                                                  }
                                                }}
                                                className="bg-teal-600 text-white px-2 py-1 rounded w-fit"
                                                disabled={!item.treatmentPlans[0]?.id}
                                              >
                                                Save Comments
                                              </button>
                                            </div>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </React.Fragment>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5" className="text-center py-4">
                            No {selectedType === 'PAIN' ? 'Pain' : 'Beauty'} Assessments found.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <Pagination
                    currentPage={currentPage}
                    pageCount={pageCount}
                    indexOfFirstItem={indexOfFirstItem}
                    indexOfLastItem={indexOfLastItem}
                    totalItems={filteredAssessments.length}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Admin;