// components/shared/Pagination.jsx
import React from 'react';
import { useState } from 'react';

export function Pagination({
  currentPage,
  pageCount,
  indexOfFirstItem,
  indexOfLastItem,
  totalItems,
  onPageChange
}) {
  const pageNumbers = [];
  const maxPageNumberLimit = 5;
  const minPageNumberLimit = 0;

  for (let i = 1; i <= pageCount; i++) {
    if (i > minPageNumberLimit && i < maxPageNumberLimit + 1) {
      pageNumbers.push(i);
    }
  }

  return (
    <nav 
      className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4" 
      aria-label="Table navigation"
    >
      <span className="text-sm font-normal text-gray-400 dark:text-gray-500">
        Showing
        <span className="font-semibold mx-1 text-gray-700 dark:text-gray-700">
          {indexOfFirstItem + 1}-{Math.min(indexOfLastItem, totalItems)}
        </span>
        of
        <span className="font-semibold mx-1 text-gray-700 dark:text-gray-700">
          {totalItems}
        </span>
      </span>
      
      <ul className="inline-flex items-stretch -space-x-px">
        {currentPage > 1 && (
          <li>
            <button
              onClick={() => onPageChange(currentPage - 1)}
              className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-400 
                       bg-gray-800 rounded-l-lg border border-gray-700 hover:bg-gray-700 
                       hover:text-white dark:text-gray-500 dark:bg-white dark:border-gray-300 
                       dark:hover:bg-gray-100 dark:hover:text-gray-700"
            >
              <span className="sr-only">Previous</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </li>
        )}
        
        {pageNumbers.map((number) => (
          <li key={number}>
            <button
              onClick={() => onPageChange(number)}
              className={`flex items-center justify-center text-sm py-2 px-3 leading-tight 
                       ${currentPage === number
                         ? 'text-primary-700 bg-primary-100 border-primary-300 hover:bg-primary-50 hover:text-primary-600'
                         : 'text-gray-400 bg-gray-800 border-gray-700 hover:bg-gray-700 hover:text-white'
                       } dark:text-gray-500 dark:bg-white dark:border-gray-300 dark:hover:bg-gray-100 dark:hover:text-gray-700`}
            >
              {number}
            </button>
          </li>
        ))}
        
        {currentPage < pageCount && (
          <li>
            <button
              onClick={() => onPageChange(currentPage + 1)}
              className="flex items-center justify-center h-full py-1.5 px-3 leading-tight 
                       text-gray-400 bg-gray-800 rounded-r-lg border border-gray-700 
                       hover:bg-gray-700 hover:text-white dark:text-gray-500 dark:bg-white 
                       dark:border-gray-300 dark:hover:bg-gray-100 dark:hover:text-gray-700"
            >
              <span className="sr-only">Next</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </li>
        )}
      </ul>
    </nav>
  );
}

// components/shared/hooks/usePagination.js
export function usePagination(items, itemsPerPage = 10) {
  const [currentPage, setCurrentPage] = useState(1);
  
  const pageCount = Math.ceil(items.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return {
    currentPage,
    pageCount,
    indexOfFirstItem,
    indexOfLastItem,
    currentItems,
    handlePageChange
  };
}