import { useState } from 'react';

const CreateBeautyAssessment = ({ onChange, patientDetails = {}, onSuccess }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 4; // Reduced from 6 to 4 steps

  const [formState, setFormState] = useState({
    pregnantOrBreastfeeding: "",
    skinType: [],
    areaOfConcern: [],
    smootherSkinTone: "",
    improvedSkinTone: "",
    increasedHydrationAndPlumpness: "",
    reducedAppearanceOfFineLinesAndWrinkles: "",
    firmerAppearance: "",
    currentBeautyProducts: "",
    currentBeautyProductsY: "",
    beautyAllergies: "",
  });

  const handleInputChange = (event) => {
    const { name, value, type } = event.target;
    
    if (type === "checkbox") {
      const newArray = formState[name].includes(value)
        ? formState[name].filter((item) => item !== value)
        : [...formState[name], value];

      setFormState(prev => ({
        ...prev,
        [name]: newArray,
      }));
    } else {
      setFormState(prev => ({
        ...prev,
        [name]: value,
        ...(value === 'No' && name === 'currentBeautyProducts' ? { currentBeautyProductsY: '' } : {})
      }));
    }
    
    const schemaFormattedData = {
      ...formState,
      currentBeautyProducts: formState.currentBeautyProducts === 'Yes',
      beautyAllergies: formState.beautyAllergies === 'Yes'
    };
    
    onChange?.(schemaFormattedData);
  };

  const isValidText = (text) => typeof text === 'string' && text.trim() !== '';

  const isStepComplete = (step) => {
    switch (step) {
      case 1:
        return formState.pregnantOrBreastfeeding !== '';
      case 2:
        return formState.skinType.length > 0 && formState.areaOfConcern.length > 0;
      case 3:
        return formState.smootherSkinTone !== "" &&
               formState.improvedSkinTone !== "" &&
               formState.increasedHydrationAndPlumpness !== "" &&
               formState.reducedAppearanceOfFineLinesAndWrinkles !== "" &&
               formState.firmerAppearance !== "";
      case 4:
        return formState.currentBeautyProducts !== "" && formState.beautyAllergies !== "" &&
               (formState.currentBeautyProducts === "No" || 
                (formState.currentBeautyProducts === "Yes" && isValidText(formState.currentBeautyProductsY)));
      default:
        return false;
    }
  };

  const nextStep = () => currentStep < totalSteps && setCurrentStep(prev => prev + 1);
  const prevStep = () => currentStep > 1 && setCurrentStep(prev => prev - 1);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (onSuccess) {
      onSuccess(formState);
    }
  };

  const ImportanceSelector = ({ title, name }) => (
    <div className="space-y-4 mb-6">
      <h3 className="text-lg font-medium text-gray-900">{title}</h3>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {[
          { label: "Not Very Important", value: "NOT" },
          { label: "Somewhat Important", value: "SOMEWHAT" },
          { label: "Very Important", value: "VERY" }
        ].map(({ label, value }) => (
          <label
            key={value}
            className={`
              flex items-center justify-center p-4 rounded-lg border-2 cursor-pointer
              ${formState[name] === value
                ? 'border-teal-600 bg-teal-50 text-teal-700'
                : 'border-gray-200 hover:bg-gray-50'
              }
            `}
          >
            <input
              type="radio"
              name={name}
              value={value}
              checked={formState[name] === value}
              onChange={handleInputChange}
              className="sr-only"
            />
            <span className="text-lg">{label}</span>
          </label>
        ))}
      </div>
    </div>
  );

  const CheckboxGroup = ({ title, name, options }) => (
    <div className="space-y-4">
      <h2 className="text-xl font-medium text-gray-900">{title}</h2>
      <div className="space-y-3">
        {options.map(({ label, value }) => (
          <label
            key={value}
            className={`
              flex items-center p-3 rounded-lg border-2 cursor-pointer
              ${formState[name].includes(value)
                ? 'border-teal-600 bg-teal-50'
                : 'border-gray-200 hover:bg-gray-50'
              }
            `}
          >
            <input
              type="checkbox"
              name={name}
              value={value}
              checked={formState[name].includes(value)}
              onChange={handleInputChange}
              className="sr-only"
            />
            <span className="text-lg">{label}</span>
          </label>
        ))}
      </div>
    </div>
  );

  return (
    <div className="max-w-2xl mx-auto p-6">
      <form onSubmit={handleSubmit} className="space-y-8">
        <div className="bg-white rounded-lg shadow-sm p-6">
          {/* Progress bar */}
          <div className="mb-8">
            <div className="h-2 bg-gray-200 rounded">
              <div 
                className="h-full bg-teal-600 rounded transition-all duration-300 ease-in-out"
                style={{ width: `${(currentStep / totalSteps) * 100}%` }}
              />
            </div>
            <div className="mt-2 text-sm text-gray-600">
              Step {currentStep} of {totalSteps}
            </div>
          </div>

          {/* Form content */}
          <div className="min-h-[400px]">
            {/* Step 1: Pregnancy Status */}
            {currentStep === 1 && (
              <div className="space-y-4">
                <h2 className="text-xl font-medium text-gray-900">
                  Are you currently pregnant or breastfeeding?
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {["Yes", "No"].map((option) => (
                    <label
                      key={option}
                      className={`
                        flex items-center justify-center p-4 rounded-lg border-2 cursor-pointer
                        ${formState.pregnantOrBreastfeeding === option 
                          ? 'border-teal-600 bg-teal-50 text-teal-700' 
                          : 'border-gray-200 hover:bg-gray-50'
                        }
                      `}
                    >
                      <input
                        type="radio"
                        name="pregnantOrBreastfeeding"
                        value={option}
                        checked={formState.pregnantOrBreastfeeding === option}
                        onChange={handleInputChange}
                        className="sr-only"
                      />
                      <span className="text-lg">{option}</span>
                    </label>
                  ))}
                </div>
              </div>
            )}

            {/* Step 2: Skin Type and Area of Concern */}
            {currentStep === 2 && (
              <div className="space-y-8">
                <CheckboxGroup
                  title="What is your skin type?"
                  name="skinType"
                  options={[
                    { label: "Normal", value: "NORMAL" },
                    { label: "Oily", value: "OILY" },
                    { label: "Dry", value: "DRY" },
                    { label: "Combination", value: "COMBINATION" },
                    { label: "Sensitive", value: "SENSITIVE" },
                  ]}
                />
                <CheckboxGroup
                  title="What are your areas of concern?"
                  name="areaOfConcern"
                  options={[
                    { label: "Eyes", value: "EYES" },
                    { label: "Face", value: "FACE" },
                  ]}
                />
              </div>
            )}

            {/* Step 3: Importance Ratings */}
            {currentStep === 3 && (
              <div className="space-y-6">
                <h2 className="text-xl font-medium text-gray-900 mb-6">
                  How important are these outcomes to you?
                </h2>
                <ImportanceSelector 
                  title="Smoother Skin Tone" 
                  name="smootherSkinTone"
                />
                <ImportanceSelector 
                  title="Improved Skin Tone" 
                  name="improvedSkinTone"
                />
                <ImportanceSelector 
                  title="Increased Hydration and Plumpness" 
                  name="increasedHydrationAndPlumpness"
                />
                <ImportanceSelector 
                  title="Reduced Appearance of Fine Lines and Wrinkles" 
                  name="reducedAppearanceOfFineLinesAndWrinkles"
                />
                <ImportanceSelector 
                  title="Firmer Appearance" 
                  name="firmerAppearance"
                />
              </div>
            )}

            {/* Step 4: Product History & Sensitivities */}
            {currentStep === 4 && (
              <div className="space-y-8">
                <div className="space-y-4">
                  <h2 className="text-xl font-medium text-gray-900">
                    Have you used any anti-aging products before?
                  </h2>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {["Yes", "No"].map((option) => (
                      <label
                        key={option}
                        className={`
                          flex items-center justify-center p-4 rounded-lg border-2 cursor-pointer
                          ${formState.currentBeautyProducts === option 
                            ? 'border-teal-600 bg-teal-50 text-teal-700' 
                            : 'border-gray-200 hover:bg-gray-50'
                          }
                        `}
                      >
                        <input
                          type="radio"
                          name="currentBeautyProducts"
                          value={option}
                          checked={formState.currentBeautyProducts === option}
                          onChange={handleInputChange}
                          className="sr-only"
                        />
                        <span className="text-lg">{option}</span>
                      </label>
                    ))}
                  </div>
                  {formState.currentBeautyProducts === "Yes" && (
                    <input
                      type="text"
                      name="currentBeautyProductsY"
                      value={formState.currentBeautyProductsY}
                      onChange={handleInputChange}
                      placeholder="Please specify which products"
                      className="w-full p-3 mt-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                    />
                  )}
                </div>

                <div className="space-y-4">
                  <h2 className="text-xl font-medium text-gray-900">
                    Any known sensitivities to ingredients like Retinol, Peptides, or Hyaluronic Acid?
                  </h2>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {["Yes", "No"].map((option) => (
                      <label
                        key={option}
                        className={`
                          flex items-center justify-center p-4 rounded-lg border-2 cursor-pointer
                          ${formState.beautyAllergies === option 
                            ? 'border-teal-600 bg-teal-50 text-teal-700' 
                            : 'border-gray-200 hover:bg-gray-50'
                          }
                        `}
                      >
                        <input
                          type="radio"
                          name="beautyAllergies"
                          value={option}
                          checked={formState.beautyAllergies === option}
                          onChange={handleInputChange}
                          className="sr-only"
                        />
                        <span className="text-lg">{option}</span>
                      </label>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Navigation */}
          <div className="flex justify-between mt-8 pt-4 border-t">
            <button
              type="button"
              onClick={prevStep}
              className={`
                px-6 py-2 rounded-lg text-gray-600 hover:bg-gray-100
                ${currentStep === 1 ? 'invisible' : ''}
              `}
            >
              Back
            </button>
            
            <button
              type={currentStep === totalSteps ? 'submit' : 'button'}
              onClick={currentStep === totalSteps ? undefined : nextStep}
              disabled={!isStepComplete(currentStep)}
              className={`
                px-6 py-2 rounded-lg text-white
                ${isStepComplete(currentStep)
                  ? 'bg-teal-600 hover:bg-teal-700'
                  : 'bg-gray-300 cursor-not-allowed'
                }
              `}
            >
              {currentStep === totalSteps ? 'Submit' : 'Continue'}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateBeautyAssessment;