import React, { useState, useEffect } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { DataStore } from 'aws-amplify/datastore';
import { uploadData } from 'aws-amplify/storage';
import { Alert, Button, Card, Form } from 'react-bootstrap';
import { Patient, PrescriptionRenewal } from '../../models';
import PatientCreateForm from '../forms/createPatient';

const PrescriptionRenewalForm = () => {
  const [existingPatients, setExistingPatients] = useState([]);
  const [showNewPatientForm, setShowNewPatientForm] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [prescriptionFile, setPrescriptionFile] = useState(null);
  const [notes, setNotes] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  
  const { user } = useAuthenticator((context) => [context.user]);

  useEffect(() => {
    if (user) {
      fetchUserPatients();
    }
  }, [user]);

  const fetchUserPatients = async () => {
    try {
      const patients = await DataStore.query(Patient, (p) => 
        p.userPatientsId.eq(user.userId)
      );
      setExistingPatients(patients);
    } catch (error) {
      console.error('Error fetching patients:', error);
      setError('Failed to load patients');
    }
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      // Validate file type
      if (!file.type.match('application/pdf|image/*')) {
        setError('Please upload a PDF or image file');
        return;
      }
      setPrescriptionFile(file);
    }
  };

  const handleSubmit = async () => {
    if (!selectedPatient || !prescriptionFile) {
      setError('Please select a patient and upload a prescription');
      return;
    }

    setIsSubmitting(true);
    setError(null);

    try {
      // Upload file to S3
      const path = `prescription-renewals/${selectedPatient.name}/${Date.now()}-${prescriptionFile.name}`.replace(/\s+/g, '-');
      await uploadData(
        {
          data: prescriptionFile,
          path: path,
          options: {
            contentType: prescriptionFile.type
          }
        }
      );

      // Create prescription renewal record directly with DataStore
      await DataStore.save(
        new PrescriptionRenewal({
          patientId: selectedPatient.id,
          prescriptionFile: path,
          notes: notes || null,
          status: 'PENDING_REVIEW'
        })
      );

      setSuccess(true);
      // Reset form
      setPrescriptionFile(null);
      setNotes('');
      setSelectedPatient(null);
    } catch (error) {
      console.error('Error submitting prescription renewal:', error);
      setError('Failed to submit prescription renewal');
    } finally {
      setIsSubmitting(false);
    }
  };

  const PatientSelector = () => (
    <div className="min-h-screen py-12">
      {/* <h2 className="text-2xl font-semibold text-center mb-8">Select Patient</h2> */}
      <div className="w-6/7 md:w-2/3 mx-auto space-y-4">
        {existingPatients.map((patient) => (
          <button
            key={patient.id}
            onClick={() => setSelectedPatient(patient)}
            className="w-full p-4 text-left border rounded-lg hover:bg-teal-50 hover:border-teal-600"
          >
            <div className="font-medium">{patient.name}</div>
            <div className="text-sm text-gray-600">{patient.email}</div>
          </button>
        ))}
        <button
          onClick={() => setShowNewPatientForm(true)}
          className="w-full p-4 text-center border-2 border-dashed border-teal-600 rounded-lg hover:bg-teal-50"
        >
          + Add New Patient
        </button>
      </div>
    </div>
  );

  return (
    <div className="container py-5">
      <Card className="shadow">
        <Card.Header>
          <h4 className="mb-0 py-2.5">Prescription Renewal Request</h4>
        </Card.Header>
        <Card.Body className='p-5'>
          {error && (
            <Alert variant="danger" className="mb-4">
              {error}
            </Alert>
          )}

          {success ? (
            <div className="text-center">
              <h4 className="text-success mb-3">
                Prescription Renewal Request Submitted
              </h4>
              <p className="text-muted mb-4">
                We'll review your request and get back to you soon.
              </p>
              <button
                 className="w-full md:w-1/2 bg-teal-600 hover:bg-teal-700 text-white font-medium py-3 px-4 rounded-md transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
                onClick={() => {
                  setSuccess(false);
                }}
              >
                Submit Another Request
              </button>
            </div>
          ) : (
            <div>
              <div className="mb-4 ">
                <h5>Select Patient</h5>
                {user && !showNewPatientForm ? (
                  <PatientSelector />
                ) : (
                  <div>
                    {user && (
                      <Button 
                        variant="link" 
                        onClick={() => setShowNewPatientForm(false)}
                        className="mb-3"
                      >
                        ← Back to patient selection
                      </Button>
                    )}
                    <PatientCreateForm
                      onSuccess={(patient) => {
                        setSelectedPatient(patient);
                        setShowNewPatientForm(false);
                      }}
                      userId={user?.username}
                    />
                  </div>
                )}
              </div>

              {selectedPatient && (
                <>
                  <div className="mb-4">
                    <h5>Upload Prescription</h5>
                    <Form.Group>
                      <div className="p-4 border mt-4 w-6/7 md:w-2/3 mx-auto rounded text-center">
                        <input
                          type="file"
                          accept="application/pdf,image/*"
                          onChange={handleFileUpload}
                          className="d-none"
                          id="prescription-upload"
                        />
                        <label
                          htmlFor="prescription-upload"
                          className="mb-0 cursor-pointer"
                        >
                          <i className="bi bi-upload mb-2 d-block" style={{ fontSize: '2rem' }}></i>
                          <span className="text-muted">
                            Click to upload prescription (PDF or Image)
                          </span>
                        </label>
                        {prescriptionFile && (
                          <div className="mt-2 text-success">
                            Selected: {prescriptionFile.name}
                          </div>
                        )}
                      </div>
                    </Form.Group>
                  </div>

                  <div className="mb-4">
                    <h5>Additional Notes</h5>
                    <div className=" mt-4 w-6/7 md:w-2/3 mx-auto">
                    <Form.Control
                      as="textarea"
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                      rows={4}
                      placeholder="Any additional information for the healthcare provider..."
                    />
                    </div>
                  </div>

                  <div className="flex justify-center w-full mb-4">
                  <button
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                    className="w-full md:w-2/3 bg-teal-600 hover:bg-teal-700 text-white font-medium py-3 px-4 rounded-md transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    {isSubmitting ? 'Submitting...' : 'Submit Renewal Request'}
                  </button>
                </div>
                </>
              )}
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default PrescriptionRenewalForm;