// components/shared/TypeToggle.jsx
import React from 'react';
import { motion } from "framer-motion";

export function TypeToggle({ selectedType, onSelectType }) {
  return (
    <div className="flex justify-center mb-8">
      <div className="relative flex p-1.5 w-[400px] bg-gradient-to-r from-teal-600/20 to-yellow-600/20 rounded-full backdrop-blur-sm shadow-lg">
        <motion.div
          className={`absolute inset-y-[2px] rounded-full shadow-lg ${
            selectedType === "PAIN"
              ? "bg-gradient-to-r from-teal-600 to-teal-700"
              : "bg-gradient-to-r from-yellow-500 to-yellow-600"
          }`}
          initial={{ x: 0, width: "48%" }}
          animate={{
            x: selectedType === "PAIN" ? "-2%" : "96%",
            width: "50%",
          }}
          transition={{
            type: "spring",
            stiffness: 300,
            damping: 30,
          }}
        />
        <button
          onClick={() => onSelectType("PAIN")}
          className={`relative z-10 px-8 ml-2 py-1 text-sm font-medium transition-colors duration-200 ${
            selectedType === "PAIN" ? "text-white" : "text-gray-600"
          }`}
        >
          Pain Management
        </button>
        <button
          onClick={() => onSelectType("BEAUTY")}
          className={`relative z-10 px-8 ml-2 py-1 text-sm font-medium transition-colors duration-200 ${
            selectedType === "BEAUTY" ? "text-white" : "text-gray-600"
          }`}
        >
          Beauty & Anti-Aging
        </button>
      </div>
    </div>
  );
}
