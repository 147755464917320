import React, { useEffect, useState } from 'react';
import { useNavigate } from'react-router-dom';
import { DataStore } from "aws-amplify/datastore";
import { 
  PainAssessment, 
  BeautyAssessment,
  PainTreatmentPlan,
  BeautyTreatmentPlan,
  Patient 
} from "../../models";
import { TypeToggle } from './components/shared/TypeToggle.jsx';
import { StatusLabel } from './components/shared/StatusLabel.jsx';
import { formatField, formatDate } from './components/shared/utils.js';
import { Pagination, usePagination } from './components/shared/Pagination.jsx';
import TableLoadingSkeleton from './components/shared/TableSkeleton'

const HCPRefillButton = () => {
  const navigate = useNavigate();
  return (
    <button
      onClick={() => navigate('/prescription-managment')}
      className="flex items-center space-x-2 bg-teal-700 hover:bg-teal-800 text-white font-medium py-2 px-4 rounded-lg transition-colors duration-200"
    >
      <svg 
        className="w-6 h-6" 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 24 24" 
        fill="currentColor"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <path d="M20.207 3.793a5.95 5.95 0 0 1 0 8.414l-8 8a5.95 5.95 0 0 1 -8.414 -8.414l8 -8a5.95 5.95 0 0 1 8.414 0m-7 1.414l-4.294 4.293l5.586 5.586l4.294 -4.292a3.95 3.95 0 1 0 -5.586 -5.586" />
      </svg>
      <span>Check Prescription Refill Requests</span>
    </button>
  );
};

const DocDashboard = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [statusFilter, setStatusFilter] = useState('All');
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [expandedRows, setExpandedRows] = useState({
      PAIN: {},
      BEAUTY: {}
    });
    const [selectedType, setSelectedType] = useState("PAIN");
    const [painAssessments, setPainAssessments] = useState([]);
    const [beautyAssessments, setBeautyAssessments] = useState([]);
    const [filteredPainAssessments, setFilteredPainAssessments] = useState([]);
    const [filteredBeautyAssessments, setFilteredBeautyAssessments] = useState([]);
    const [filteredAssessments, setFilteredAssessments] = useState([]);
    const [comments, setComments] = useState({});
    const {
      currentPage,
      pageCount,
      indexOfFirstItem,
      indexOfLastItem,
      currentItems,
      handlePageChange
    } = usePagination(filteredAssessments);

  const toggleDetails = (index) => {
    setExpandedRows(prevState => ({
      ...prevState,
      [selectedType]: {
        ...prevState[selectedType],
        [index]: !prevState[selectedType][index]
      }
    }));
  };

    const fetchAssessments = async () => {
      try {
        setLoading(true);
        
        // Fetch Pain Assessments
        const painAssessmentsData = await DataStore.query(PainAssessment);
        const painWithDetails = await Promise.all(
          painAssessmentsData.map(async (assessment) => {
            const patient = await DataStore.query(Patient, assessment.patientId);
            const treatmentPlans = await DataStore.query(
              PainTreatmentPlan,
              plan => plan.painAssessmentId.eq(assessment.id)
            );
            return { ...assessment, patient, treatmentPlans };
          })
        );
        setPainAssessments(painWithDetails);
        setFilteredPainAssessments(painWithDetails);
    
        // Fetch Beauty Assessments
        const beautyAssessmentsData = await DataStore.query(BeautyAssessment);
        const beautyWithDetails = await Promise.all(
          beautyAssessmentsData.map(async (assessment) => {
            const patient = await DataStore.query(Patient, assessment.patientId);
            const treatmentPlans = await DataStore.query(
              BeautyTreatmentPlan,
              plan => plan.beautyAssessmentId.eq(assessment.id)
            );
            return { ...assessment, patient, treatmentPlans };
          })
        );
        setBeautyAssessments(beautyWithDetails);
        setFilteredBeautyAssessments(beautyWithDetails);
    
        setLoading(false);
      } catch (error) {
        console.error('Error fetching assessments:', error);
        setError(error.message);
        setLoading(false);
      }
    };
  
    useEffect(() => {
      setExpandedRows({
        PAIN: {},
        BEAUTY: {}
      });
    }, [selectedType]);

    // Fetch pain assessments with associated Patient and TreatmentPlan
    useEffect(() => {
        fetchAssessments();
    }, []);

    // Filter assessments based on status
    useEffect(() => {
      const assessments = selectedType === 'PAIN' ? painAssessments : beautyAssessments;
      const filtered = statusFilter === 'All'
        ? assessments
        : assessments.filter(assessment => 
            assessment.treatmentPlans.some(plan => plan.status === statusFilter)
          );
          
      if (selectedType === 'PAIN') {
        setFilteredPainAssessments(filtered);
      } else {
        setFilteredBeautyAssessments(filtered);
      }
    }, [statusFilter, painAssessments, beautyAssessments, selectedType]);
    
    useEffect(() => {
      const current = selectedType === 'PAIN' ? filteredPainAssessments : filteredBeautyAssessments;
      setFilteredAssessments(current);
    }, [selectedType, filteredPainAssessments, filteredBeautyAssessments]);
    

    // Search functionality
    const filterAssessments = (data) => {
      // First filter by search term
      const filtered = !searchTerm ? data : data.filter(item => 
        item.patient?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.patient?.email?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      
      // Then sort by creation date
      return filtered.sort((a, b) => {
        const dateA = new Date(a.treatmentPlans[0]?.createdAt || 0);
        const dateB = new Date(b.treatmentPlans[0]?.createdAt || 0);
        return dateB - dateA; // Descending order (most recent first)
      });
    };

    // Update filtered assessments whenever searchTerm changes
    useEffect(() => {
      const assessments = selectedType === 'PAIN' ? painAssessments : beautyAssessments;
      const filteredData = filterAssessments(assessments);
      
      if (selectedType === 'PAIN') {
        setFilteredPainAssessments(filteredData);
      } else {
        setFilteredBeautyAssessments(filteredData);
      }
    }, [searchTerm, painAssessments, beautyAssessments, selectedType]);
    

    if (loading) return <TableLoadingSkeleton />;
    if (error) return <p>Error: {error}</p>;

    const updateTreatmentStatus = async (id, newStatus, type = 'PAIN') => {
      try {
        const TreatmentModel = type === 'PAIN' ? PainTreatmentPlan : BeautyTreatmentPlan;
        const treatmentPlan = await DataStore.query(TreatmentModel, id);
        
        if (treatmentPlan) {
          await DataStore.save(
            TreatmentModel.copyOf(treatmentPlan, updated => {
              updated.status = newStatus;
            })
          );
          await fetchAssessments(); // Refresh data
          alert('Status updated successfully!');
        }
      } catch (error) {
        console.error("Error updating status:", error);
      }
    };
    
    const handleCommentChange = (assessmentId, newComment, type) => {
      setComments(prev => ({
        ...prev,
        [assessmentId]: newComment
      }));
    };
    const saveComments = async (id, updatedComment, type) => {
      try {
        const TreatmentModel = type === 'PAIN' ? PainTreatmentPlan : BeautyTreatmentPlan;
        const treatmentPlan = await DataStore.query(TreatmentModel, id);
        
        if (treatmentPlan) {
          await DataStore.save(
            TreatmentModel.copyOf(treatmentPlan, updated => {
              updated.additionalComments = updatedComment;
            })
          );
          alert('Comments updated successfully!');
        }
      } catch (error) {
        console.error("Error saving comments:", error);
      }
    };
    
    return (
        <div className='bg-gray-50'>
            <div className="flex justify-between items-center md:px-16 lg:px-[16rem]">
            <h1 className="py-12 mt-6 text-3xl text-gray-900">
              Hello Doctor!
            </h1>
            <div className="py-12 mt-6">
              <HCPRefillButton />
            </div>
          </div>
            <TypeToggle selectedType={selectedType} onSelectType={setSelectedType} />
            <section className="container px-4 my-16 mx-auto">
                <div className="flex flex-col">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div className="mx-auto max-w-screen-xl px-4 lg:px-12">
                                <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
                                    <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
                                        <form className="flex items-center w-full md:w-1/2">
                                            <label form="simple-search" className="sr-only">Search</label>
                                            <div className="relative w-full">
                                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                                    <svg aria-hidden="true"
                                                         className="w-5 h-5 text-gray-500 dark:text-gray-400"
                                                         fill="currentColor" viewBox="0 0 20 20"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd"
                                                              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                                              clipRule="evenodd"></path>
                                                    </svg>
                                                </div>
                                                <input
                                                    type="text"
                                                    id="simple-search"
                                                    className="bg-gray-700 border border-gray-600 text-black text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2.5 dark:bg-gray-50 dark:border-gray-300 dark:text-gray-900 dark:placeholder-gray-400 dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                                    placeholder="Search Patients"
                                                    value={searchTerm}
                                                    onChange={(e) => setSearchTerm(e.target.value)}
                                                />
                                            </div>
                                        </form>
                                        <div className="relative">
                                            <button
                                                onClick={() => setDropdownOpen(!dropdownOpen)}
                                                className="inline-flex justify-center w-full rounded-md border shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none">
                                                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true"
                                                     className="h-4 w-4 mr-2 text-gray-400" viewBox="0 0 20 20"
                                                     fill="currentColor">
                                                    <path fillRule="evenodd"
                                                          d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                                                          clipRule="evenodd"/>
                                                </svg>
                                                Filter
                                                <svg className="ml-1.5 w-5 h-5" fill="currentColor"
                                                     viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"
                                                     aria-hidden="true">
                                                    <path fillRule="evenodd"
                                                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                          clipRule="evenodd"></path>
                                                </svg>
                                            </button>
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: '100%',
                                                    left: 0,
                                                    transform: 'translateX(0%)',
                                                    width: '100%',
                                                    maxHeight: '125px',
                                                    overflow: 'auto'
                                                }}
                                                className={`${dropdownOpen ? 'block' : 'hidden'} origin-top-right absolute mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none`}>
                                                <ul className="py-1 text-sm text-gray-700 dark:text-gray-700">
                                                    <li>
                                                        <button onClick={() => setStatusFilter('All')}
                                                                className="block w-full text-left py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">All
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <button onClick={() => setStatusFilter('SEE_DOCTOR')}
                                                                className="block w-full text-left py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">See
                                                            Doctor
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <button
                                                            onClick={() => setStatusFilter('PENDING_REVIEW')}
                                                            className="block w-full text-left py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Pending
                                                            Review
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <button onClick={() => setStatusFilter('UNPAID')}
                                                                className="block w-full text-left py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Unpaid
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <button onClick={() => setStatusFilter('PAID')}
                                                                className="block w-full text-left py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Paid
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <button onClick={() => setStatusFilter('SHIPPED')}
                                                                className="block w-full text-left py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Shipped
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead className="bg-teal-700 ">
                                        <tr>
                                            <th scope="col"
                                                className="px-4 py-3.5 text-lg font-bold text-left rtl:text-right text-white">
                                                Date
                                            </th>
                                            <th scope="col"
                                                className="px-4 py-3.5 text-lg font-bold text-left rtl:text-right text-white">
                                                Patient
                                            </th>
                                            <th scope="col"
                                                className="px-4 py-3.5 text-lg font-bold text-left rtl:text-right text-white">
                                                Status
                                            </th>
                                            <th scope="col"
                                                className="px-4 py-3.5 text-lg font-bold text-left rtl:text-right text-white">
                                                Presenting Symptoms
                                            </th>
                                            <th scope="col" className="relative py-3.5 px-4">
                                                <span className="sr-only">Edit</span>
                                            </th>
                                        </tr>
                                        </thead>

                                        <tbody className="bg-white divide-y divide-gray-300">
                                          {selectedType === 'PAIN' ? 
                                            (currentItems.length > 0 ? 
                                              currentItems.map((item, index) => (
                                                <React.Fragment key={index}>
                                                  <tr>
                                                    <td className="px-4 py-4 text-base text-gray-600 whitespace-nowrap">
                                                      {item.treatmentPlans[0].createdAt
                                                        ? formatDate(item.treatmentPlans[0].createdAt)
                                                        : 'No date'}
                                                    </td>
                                                    <td className="px-4 py-4 text-base text-gray-600 whitespace-nowrap">
                                                      {formatField(item.patient.name)
                                                        .split(' ')
                                                        .map(
                                                          (word) =>
                                                            word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                                                        )
                                                        .join(' ')}
                                                    </td>
                                                    <td className="px-4 py-4 text-base text-gray-600 whitespace-nowrap">
                                                      {item.treatmentPlans && item.treatmentPlans[0]
                                                        ?  <StatusLabel status={item.treatmentPlans[0].status} />
                                                        : 'No Status'}
                                                    </td>
                                                    <td className="px-4 py-4 text-base text-gray-600 whitespace-nowrap">
                                                      {item.presentingSymptoms && item.presentingSymptoms.length > 0
                                                        ? formatField(item.presentingSymptoms[0])
                                                        : 'No Symptoms Reported'}
                                                    </td>
                                                    <td className="py-4 flex justify-end px-4 text-base whitespace-nowrap">
                                                      <button
                                                        onClick={() => toggleDetails(index)}
                                                        className="text-teal-700 transition-colors duration-200 hover:text-teal-900 focus:outline-none"
                                                      >
                                                        {expandedRows[selectedType][index] ? 'Collapse' : 'View'}
                                                      </button>
                                                    </td>
                                                  </tr>
                                                  {/* Always render the details row */}
                                                  <tr>
                                                    <td colSpan="5" className="p-0">
                                                      <div
                                                       className={`overflow-hidden transition-all duration-500 ${
                                                        expandedRows[selectedType][index] 
                                                        ? 'max-h-[2000px] opacity-100'
                                                        : 'max-h-0 opacity-0'
                                                      }`}
                                                      >
                                                        <div className="p-4 bg-gray-100">
                                                          <h3 className="text-lg font-semibold">
                                                            Patient and Pain Assessment Details
                                                          </h3>
                                                          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 text-sm">
                                                            {/* Patient Information */}
                                                            <div>
                                                              <h4 className="font-semibold">Patient Information</h4>
                                                              <p>
                                                                <strong>Name:</strong>{' '}
                                                                {formatField(item.patient.name)
                                                                  .split(' ')
                                                                  .map(
                                                                    (word) =>
                                                                      word.charAt(0).toUpperCase() +
                                                                      word.slice(1).toLowerCase()
                                                                  )
                                                                  .join(' ')}
                                                              </p>
                                                              <p>
                                                                <strong>Email:</strong> {item.patient.email}
                                                              </p>
                                                              <p>
                                                                <strong>Phone:</strong> {item.patient.phone}
                                                              </p>
                                                              <p>
                                                                <strong>Address:</strong> {item.patient.address}
                                                              </p>
                                                              <p>
                                                                <strong>Date of Birth:</strong>{' '}
                                                                {formatDate(item.patient.dateOfBirth)}
                                                              </p>
                                                              <p>
                                                                <strong>Sex:</strong>{' '}
                                                                {item.patient.sex
                                                                  ? item.patient.sex
                                                                      .split(' ')
                                                                      .map(
                                                                        (word) =>
                                                                          word.charAt(0).toUpperCase() +
                                                                          word.slice(1).toLowerCase()
                                                                      )
                                                                      .join(' ')
                                                                  : 'Not Specified'}
                                                              </p>
                                                            </div>

                                                            {/* Pain Assessment */}
                                                            <div>
                                                              <h4 className="font-semibold">Pain Assessment</h4>
                                                              <p>
                                                                <strong>Assessment Date:</strong>{' '}
                                                                {item.treatmentPlans &&
                                                                item.treatmentPlans[0]?.createdAt
                                                                  ? formatDate(item.treatmentPlans[0].createdAt)
                                                                  : 'No Date'}
                                                              </p>
                                                              <p>
                                                                <strong>Current Medication:</strong>{' '}
                                                                {item.currentMedication}
                                                              </p>
                                                              {item.currentMedication === 'Yes' && (
                                                                <p>
                                                                  <strong>Current Medication Details:</strong>{' '}
                                                                  {item.currentMedicationY}
                                                                </p>
                                                              )}
                                                              <p>
                                                                <strong>Severity Score:</strong>{' '}
                                                                {item.severityScore
                                                                  ? formatField(item.severityScore)
                                                                  : 'Not Specified'}
                                                              </p>
                                                              <p>
                                                                <strong>Onset:</strong>{' '}
                                                                {item.onset
                                                                  ? formatField(item.onset)
                                                                  : 'Custom Duration Reported'}
                                                              </p>
                                                              {item.onsetOther && (
                                                                <p>
                                                                  <strong>Onset Other:</strong> {item.onsetOther}
                                                                </p>
                                                              )}
                                                              <p>
                                                                <strong>Location:</strong>{' '}
                                                                {item.location
                                                                  ? formatField(item.location.join(', '))
                                                                  : 'No Location Reported'}
                                                              </p>
                                                              {item.locationOther && (
                                                                <p>
                                                                  <strong>Location Other:</strong> {item.locationOther}
                                                                </p>
                                                              )}
                                                              <p>
                                                                <strong>Body Side:</strong>{' '}
                                                                {item.bodySide
                                                                  ? formatField(item.bodySide.join(', '))
                                                                  : 'No Body Side Reported'}
                                                              </p>
                                                              {item.bodySideOther && (
                                                                <p>
                                                                  <strong>Body Side Other:</strong> {item.bodySideOther}
                                                                </p>
                                                              )}
                                                              <p>
                                                                <strong>Symptoms:</strong>{' '}
                                                                {item.presentingSymptoms
                                                                  ? formatField(item.presentingSymptoms.join(', '))
                                                                  : 'No Symptoms Reported'}
                                                              </p>
                                                              {item.presentingSymptomsOther && (
                                                                <p>
                                                                  <strong>Symptoms Other:</strong>{' '}
                                                                  {item.presentingSymptomsOther}
                                                                </p>
                                                              )}
                                                              <p>
                                                                <strong>Allergies:</strong>{' '}
                                                                {item.allergies
                                                                  ? formatField(item.allergies.join(', '))
                                                                  : 'No Allergies Reported'}
                                                              </p>
                                                              <p>
                                                                <strong>Health History:</strong>{' '}
                                                                {item.healthHistory
                                                                  ? formatField(item.healthHistory.join(', '))
                                                                  : 'No Health History Reported'}
                                                              </p>
                                                              <p>
                                                                <strong>Red Flag Signs:</strong>{' '}
                                                                {item.redFlagSigns
                                                                  ? formatField(item.redFlagSigns.join(', '))
                                                                  : 'No Red Flag Signs'}
                                                              </p>
                                                              {item.redFlagSignsOther && (
                                                                <p>
                                                                  <strong>Red Flag Signs Other:</strong>{' '}
                                                                  {item.redFlagSignsOther}
                                                                </p>
                                                              )}
                                                              {item.traumaticOther && (
                                                                <p>
                                                                  <strong>Traumatic Other:</strong>{' '}
                                                                  {item.traumaticOther}
                                                                </p>
                                                              )}
                                                              {item.additionalNotes && (
                                                                <p>
                                                                  <strong>Additional Notes:</strong>{' '}
                                                                  {item.additionalNotes}
                                                                </p>
                                                              )}
                                                            </div>

                                                            {/* Treatment Plan */}
                                                            <div>
                                                              <h4 className="font-semibold">Treatment Plan</h4>
                                                              <p>
                                                                <strong>Status:</strong>{' '}
                                                                {item.treatmentPlans && item.treatmentPlans[0]
                                                                  ?  <StatusLabel status={item.treatmentPlans[0].status} />
                                                                  : 'No Status'}
                                                              </p>
                                                              <p>
                                                                <strong>Recommendation:</strong>{' '}
                                                                {(item.treatmentPlans &&
                                                                  item.treatmentPlans[0]?.recommendedTreatment) ||
                                                                  'N/A'}
                                                              </p>

                                                              {/* Approve/Deny buttons for PENDING_REVIEW status */}
                                                              {item.treatmentPlans &&
                                                                item.treatmentPlans[0]?.status === 'PENDING_REVIEW' && (
                                                                  <div className="mb-4">
                                                                    <button
                                                                      onClick={() =>
                                                                        updateTreatmentStatus(
                                                                          item.treatmentPlans[0].id,
                                                                          'UNPAID'
                                                                        )
                                                                      }
                                                                      className="bg-green-500 text-white py-1 px-3 rounded mr-2"
                                                                    >
                                                                      Approve
                                                                    </button>
                                                                    <button
                                                                      onClick={() =>
                                                                        updateTreatmentStatus(
                                                                          item.treatmentPlans[0].id,
                                                                          'SEE_DOCTOR'
                                                                        )
                                                                      }
                                                                      className="bg-red-500 text-white py-1 px-3 rounded"
                                                                    >
                                                                      Deny
                                                                    </button>
                                                                  </div>
                                                                )}

                                                              {/* Comments input and Save button */}
                                                              <div className="mt-4">
                                                                <label className="block">
                                                                  <span className="font-semibold">Comments:</span>
                                                                  <div className="mt-2 flex flex-col space-y-2">
                                                                    <input
                                                                      type="text"
                                                                      value={comments[item.id] || item.treatmentPlans[0]?.additionalComments || ''}
                                                                      onChange={(e) => handleCommentChange(item.id, e.target.value, selectedType)}
                                                                      className="border rounded p-1 w-full"
                                                                      placeholder="Add comments..."
                                                                    />
                                                                    <button
                                                                      onClick={() => {
                                                                        if (item.treatmentPlans[0]?.id) {
                                                                          saveComments(
                                                                            item.treatmentPlans[0].id,
                                                                            comments[item.id] || '',
                                                                            selectedType
                                                                          );
                                                                        }
                                                                      }}
                                                                      className="bg-teal-600 text-white px-2 py-1 rounded w-fit"
                                                                      disabled={!item.treatmentPlans[0]?.id}
                                                                    >
                                                                      Save Comments
                                                                    </button>
                                                                  </div>
                                                                </label>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                </React.Fragment>
                                              ))
                                           : 
                                            <tr>
                                              <td colSpan="5" className="text-center py-4">
                                                No Pain Assessments found.
                                              </td>
                                            </tr>
                                        ) 
                                        : 
                                        (currentItems.length > 0 ? 
                                          currentItems.map((item, index) => (
                                              <React.Fragment key={index}>
                                                <tr>
                                                  <td className="px-4 py-4 text-base text-gray-600 whitespace-nowrap">
                                                    {item.treatmentPlans[0]?.createdAt
                                                      ? formatDate(item.treatmentPlans[0].createdAt)
                                                      : 'No date'}
                                                  </td>
                                                  <td className="px-4 py-4 text-base text-gray-600 whitespace-nowrap">
                                                    {formatField(item.patient.name)
                                                      .split(' ')
                                                      .map(
                                                        (word) =>
                                                          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                                                      )
                                                      .join(' ')}
                                                  </td>
                                                  <td className="px-4 py-4 text-base text-gray-600 whitespace-nowrap">
                                                    {item.treatmentPlans && item.treatmentPlans[0]
                                                      ?  <StatusLabel status={item.treatmentPlans[0].status} />
                                                      : 'No Status'}
                                                  </td>
                                                  <td className="px-4 py-4 text-base text-gray-600 whitespace-nowrap">
                                                    {item.areaOfConcern && item.areaOfConcern.length > 0
                                                      ? formatField(item.areaOfConcern[0])
                                                      : 'No Concerns Reported'}
                                                  </td>
                                                  <td className="py-4 flex justify-end px-4 text-base whitespace-nowrap">
                                                    <button
                                                      onClick={() => toggleDetails(index)}
                                                      className="text-teal-700 transition-colors duration-200 hover:text-teal-900 focus:outline-none"
                                                    >
                                                      {expandedRows[selectedType][index] ? 'Collapse' : 'View'}
                                                    </button>
                                                  </td>
                                                </tr>
                                                {/* Details row */}
                                                <tr>
                                                  <td colSpan="5" className="p-0">
                                                    <div
                                                    className={`overflow-hidden transition-all duration-500 ${
                                                      expandedRows[selectedType][index] 
                                                      ? 'max-h-[2000px] opacity-100'
                                                      : 'max-h-0 opacity-0'
                                                    }`}
                                                    >
                                                      <div className="p-4 bg-gray-100">
                                                        <h3 className="text-lg font-semibold">
                                                          Patient and Beauty Assessment Details
                                                        </h3>
                                                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 text-sm">
                                                          {/* Patient Information */}
                                                          <div>
                                                            <h4 className="font-semibold">Patient Information</h4>
                                                            <p>
                                                              <strong>Name:</strong>{' '}
                                                              {formatField(item.patient.name)
                                                                .split(' ')
                                                                .map(
                                                                  (word) =>
                                                                    word.charAt(0).toUpperCase() +
                                                                    word.slice(1).toLowerCase()
                                                                )
                                                                .join(' ')}
                                                            </p>
                                                            <p>
                                                              <strong>Email:</strong> {item.patient.email}
                                                            </p>
                                                            <p>
                                                              <strong>Phone:</strong> {item.patient.phone}
                                                            </p>
                                                            <p>
                                                              <strong>Address:</strong> {item.patient.address}
                                                            </p>
                                                            <p>
                                                              <strong>Date of Birth:</strong>{' '}
                                                              {formatDate(item.patient.dateOfBirth)}
                                                            </p>
                                                            <p>
                                                              <strong>Sex:</strong>{' '}
                                                              {item.patient.sex
                                                                ? item.patient.sex
                                                                    .split(' ')
                                                                    .map(
                                                                      (word) =>
                                                                        word.charAt(0).toUpperCase() +
                                                                        word.slice(1).toLowerCase()
                                                                    )
                                                                    .join(' ')
                                                                : 'Not Specified'}
                                                            </p>
                                                          </div>

                                                          {/* Beauty Assessment */}
                                                          <div>
                                                            <h4 className="font-semibold">Beauty Assessment</h4>
                                                            <p>
                                                              <strong>Assessment Date:</strong>{' '}
                                                              {item.treatmentPlans &&
                                                              item.treatmentPlans[0]?.createdAt
                                                                ? formatDate(item.treatmentPlans[0].createdAt)
                                                                : 'No Date'}
                                                            </p>
                                                            <p>
                                                              <strong>Areas of Concern:</strong>{' '}
                                                              {item.concernAreas
                                                                ? formatField(item.concernAreas.join(', '))
                                                                : 'No Areas Specified'}
                                                            </p>
                                                            {item.concernAreasOther && (
                                                              <p>
                                                                <strong>Other Concerns:</strong>{' '}
                                                                {item.concernAreasOther}
                                                              </p>
                                                            )}
                                                            <p>
                                                              <strong>Previous Treatments:</strong>{' '}
                                                              {item.previousTreatments
                                                                ? formatField(item.previousTreatments.join(', '))
                                                                : 'No Previous Treatments'}
                                                            </p>
                                                            <p>
                                                              <strong>Current Skincare:</strong>{' '}
                                                              {item.currentSkincare || 'None specified'}
                                                            </p>
                                                            <p>
                                                              <strong>Allergies:</strong>{' '}
                                                              {item.allergies
                                                                ? formatField(item.allergies.join(', '))
                                                                : 'No Allergies Reported'}
                                                            </p>
                                                            <p>
                                                              <strong>Medical History:</strong>{' '}
                                                              {item.medicalHistory
                                                                ? formatField(item.medicalHistory.join(', '))
                                                                : 'No Medical History Reported'}
                                                            </p>
                                                            {item.additionalNotes && (
                                                              <p>
                                                                <strong>Additional Notes:</strong>{' '}
                                                                {item.additionalNotes}
                                                              </p>
                                                            )}
                                                          </div>

                                                          {/* Treatment Plan */}
                                                          <div>
                                                            <h4 className="font-semibold">Treatment Plan</h4>
                                                            <p>
                                                              <strong>Status:</strong>{' '}
                                                              {item.treatmentPlans && item.treatmentPlans[0]
                                                                ?  <StatusLabel status={item.treatmentPlans[0].status} />
                                                                : 'No Status'}
                                                            </p>
                                                            <p>
                                                              <strong>Recommendation:</strong>{' '}
                                                              {(item.treatmentPlans &&
                                                                item.treatmentPlans[0]?.recommendedTreatment) ||
                                                                'N/A'}
                                                            </p>

                                                            {/* Approve/Deny buttons for PENDING_REVIEW status */}
                                                            {item.treatmentPlans &&
                                                              item.treatmentPlans[0]?.status === 'PENDING_REVIEW' && (
                                                                <div className="mb-4">
                                                                  <button
                                                                    onClick={() =>
                                                                      updateTreatmentStatus(
                                                                        item.treatmentPlans[0].id,
                                                                        'UNPAID',
                                                                        'BEAUTY'
                                                                      )
                                                                    }
                                                                    className="bg-green-500 text-white py-1 px-3 rounded mr-2"
                                                                  >
                                                                    Approve
                                                                  </button>
                                                                  <button
                                                                    onClick={() =>
                                                                      updateTreatmentStatus(
                                                                        item.treatmentPlans[0].id,
                                                                        'SEE_DOCTOR',
                                                                        'BEAUTY'
                                                                      )
                                                                    }
                                                                    className="bg-red-500 text-white py-1 px-3 rounded"
                                                                  >
                                                                    Deny
                                                                  </button>
                                                                </div>
                                                              )}

                                                            {/* Comments input and Save button */}
                                                            <div className="mt-4">
                                                              <label className="block">
                                                                <span className="font-semibold">Comments:</span>
                                                                <div className="mt-2 flex flex-col space-y-2">
                                                                  <input
                                                                    type="text"
                                                                    value={comments[item.id] || item.treatmentPlans[0]?.additionalComments || ''}
                                                                    onChange={(e) => handleCommentChange(item.id, e.target.value, selectedType)}
                                                                    className="border rounded p-1 w-full"
                                                                    placeholder="Add comments..."
                                                                  />
                                                                  <button
                                                                    onClick={() => {
                                                                      if (item.treatmentPlans[0]?.id) {
                                                                        saveComments(
                                                                          item.treatmentPlans[0].id,
                                                                          comments[item.id] || '',
                                                                          selectedType
                                                                        );
                                                                      }
                                                                    }}
                                                                    className="bg-teal-600 text-white px-2 py-1 rounded w-fit"
                                                                    disabled={!item.treatmentPlans[0]?.id}
                                                                  >
                                                                    Save Comments
                                                                  </button>
                                                                </div>
                                                              </label>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </td>
                                                </tr>
                                              </React.Fragment>
                                            ))
                                           : 
                                            <tr>
                                              <td colSpan="5" className="text-center py-4">
                                                No Beauty Assessments found.
                                              </td>
                                            </tr>
                                          )}
                                        </tbody>
                                    </table>
                                    <Pagination
                                      currentPage={currentPage}
                                      pageCount={pageCount}
                                      indexOfFirstItem={indexOfFirstItem}
                                      indexOfLastItem={indexOfLastItem}
                                      totalItems={filteredAssessments.length}
                                      onPageChange={handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default DocDashboard;