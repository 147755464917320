// components/shared/utils.js
export function formatField(input) {
    if (!input) return "";
    if (typeof input === "string") {
      return input
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(" ");
    }
    if (Array.isArray(input)) {
      return input.join(", ");
    }
    return String(input);
  }
  
  export function formatDate(dateString) {
    if (!dateString) return "N/A";
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }