/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://6ekmdees55h6zc3k2f35ldoa5q.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-ogezhnzoefbuzbpxodzdt4o54q",
    "aws_cognito_identity_pool_id": "us-east-2:52be7c21-50ba-469b-b32d-d01764a9d1cd",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_rxZ9ngtDe",
    "aws_user_pools_web_client_id": "6b1frht664tobb73okb8qll61a",
    "oauth": {
        "domain": "ywgieziu9rlk-dev.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://main.d1p6lt27x4dfjq.amplifyapp.com/,http://localhost:3000/",
        "redirectSignOut": "https://main.d1p6lt27x4dfjq.amplifyapp.com/,http://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "ouchrx7ed67b91e68e4f878d474605253aaf4e8faba-dev",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
