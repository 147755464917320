// import React from 'react';
// import { formatDate, formatField } from './components/shared/utils';
// import { StatusLabel } from './components/shared/StatusLabel';

// const MobileCard = ({ item, isExpanded, toggleDetails, globalIndex }) => {
//   const firstPlan = item.treatmentPlans?.[0];
  
//   return (
//     <div className="bg-white rounded-lg shadow-md p-4 mb-4">
//       {/* Card Header */}
//       <div className="flex justify-between items-start mb-4">
//         <div>
//           <p className="text-sm text-gray-500">
//             {firstPlan?.createdAt ? formatDate(firstPlan.createdAt) : "N/A"}
//           </p>
//           <h3 className="text-lg font-semibold text-gray-900">
//             {formatField(item.patient?.name ?? "Unknown")}
//           </h3>
//         </div>
//         <StatusLabel status={firstPlan?.status || "No Status"} />
//       </div>

//       {/* Card Content */}
//       <div className="mb-4">
//         <p className="text-gray-600">
//           <span className="font-medium">
//             {item.type === "PAIN" ? "Symptoms: " : "Area of Concern: "}
//           </span>
//           {item.type === "PAIN"
//             ? item.presentingSymptoms?.length
//               ? formatField(item.presentingSymptoms[0])
//               : "No Symptoms Reported"
//             : item.areaOfConcern?.length
//             ? formatField(item.areaOfConcern[0])
//             : "No Areas Specified"}
//         </p>
//       </div>

//       {/* Expand/Collapse Button */}
//       <button
//         onClick={() => toggleDetails(globalIndex)}
//         className="w-full flex items-center justify-center text-teal-600 hover:text-teal-800 transition-colors"
//       >
//         <span className="mr-2">{isExpanded ? "Show Less" : "Show More"}</span>
//         {isExpanded ? (
//           <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
//             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 15l7-7 7 7" />
//           </svg>
//         ) : (
//           <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
//             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
//           </svg>
//         )}
//       </button>

//       {/* Expanded Content */}
//       {isExpanded && (
//         <div className="mt-4 pt-4 border-t border-gray-200">
//           <div className="space-y-4">
//             {/* Patient Info */}
//             <div>
//               <h4 className="font-semibold mb-2 text-gray-900">Patient Information</h4>
//               <div className="space-y-2">
//                 <p className="text-gray-600">
//                   <span className="font-medium">Name:</span> {item.patient?.name}
//                 </p>
//                 <p className="text-gray-600">
//                   <span className="font-medium">Sex:</span> {item.patient?.sex || "N/A"}
//                 </p>
//                 <p className="text-gray-600">
//                   <span className="font-medium">Date of Birth:</span>{" "}
//                   {item.patient?.dateOfBirth || "N/A"}
//                 </p>
//               </div>
//             </div>

//             {/* Assessment Details */}
//             <div>
//               <h4 className="font-semibold mb-2 text-gray-900">
//                 {item.type === "PAIN" ? "Pain Assessment" : "Beauty Assessment"}
//               </h4>
//               <div className="space-y-2">
//                 {item.type === "PAIN" ? (
//                   <>
//                     <p className="text-gray-600">
//                       <span className="font-medium">Severity:</span>{" "}
//                       {formatField(item.severityScore) ?? "N/A"}
//                     </p>
//                     <p className="text-gray-600">
//                       <span className="font-medium">Onset:</span>{" "}
//                       {formatField(item.onset) || "Unknown"}
//                     </p>
//                     <p className="text-gray-600">
//                       <span className="font-medium">Symptoms:</span>{" "}
//                       {formatField(item.presentingSymptoms?.join(", ")) || "None reported"}
//                     </p>
//                   </>
//                 ) : (
//                   <>
//                     <p className="text-gray-600">
//                       <span className="font-medium">Areas of Concern:</span>{" "}
//                       {formatField(item.areaOfConcern?.join(", ")) || "N/A"}
//                     </p>
//                     <p className="text-gray-600">
//                       <span className="font-medium">Anti-Aging Concerns:</span>{" "}
//                       {formatField(item.antiAgingConcern?.join(", ")) || "N/A"}
//                     </p>
//                   </>
//                 )}
//               </div>
//             </div>

//             {/* Treatment Plan */}
//             <div>
//               <h4 className="font-semibold mb-2 text-gray-900">Treatment Plan</h4>
//               {firstPlan ? (
//                 <div className="space-y-2">
//                   <p className="text-gray-600">
//                     <span className="font-medium">Recommendation:</span>{" "}
//                     {firstPlan.recommendedTreatment || "N/A"}
//                   </p>
//                   <p className="text-gray-600">
//                     <span className="font-medium">Comments:</span>{" "}
//                     {firstPlan.additionalComments || "No comments"}
//                   </p>
//                   {firstPlan.status === "UNPAID" && (
//                     <PaymentRouter
//                       recommendedTreatment={firstPlan.recommendedTreatment}
//                       treatmentPlanId={firstPlan.id}
//                       onError={handlePaymentError}
//                     />
//                   )}
//                 </div>
//               ) : (
//                 <p className="text-gray-600">No treatment plan available</p>
//               )}
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// // Main content renderer that switches between table and cards
// const ContentRenderer = ({ currentItems, expandedRows, toggleDetails, indexOfFirstItem }) => {
//   return (
//     <>
//       {/* Mobile Card View */}
//       <div className="md:hidden space-y-4">
//         {currentItems.map((item, idx) => {
//           const globalIndex = indexOfFirstItem + idx;
//           return (
//             <MobileCard
//               key={globalIndex}
//               item={item}
//               isExpanded={expandedRows[globalIndex]}
//               toggleDetails={toggleDetails}
//               globalIndex={globalIndex}
//             />
//           );
//         })}
//       </div>

//       {/* Desktop Table View */}
//       <div className="hidden md:block">
//        <table className="min-w-full divide-y divide-gray-300">
//                   <thead className="bg-teal-700">
//                     <tr>
//                       <th className="px-4 py-3.5 text-lg font-bold text-left text-white">
//                         Date
//                       </th>
//                       <th className="px-4 py-3.5 text-lg font-bold text-left text-white">
//                         Patient
//                       </th>
//                       <th className="px-4 py-3.5 text-lg font-bold text-left text-white">
//                         Status
//                       </th>
//                       <th className="px-4 py-3.5 text-lg font-bold text-left text-white">
//                         {selectedType === "PAIN" ? "Presenting Symptoms" : "Area of Concern"}
//                       </th>
//                       <th className="py-3.5 px-4">
//                         <span className="sr-only">View</span>
//                       </th>
//                     </tr>
//                   </thead>
//                   <tbody className="bg-white divide-y divide-gray-300">
//                     {currentItems.length > 0 ? (
//                       renderTableRows()
//                     ) : (
//                       <tr>
//                         <td colSpan="5" className="px-4 py-8 text-center text-gray-500">
//                           No {selectedType.toLowerCase()} treatment plans found
//                         </td>
//                       </tr>
//                     )}
//                   </tbody>
//                 </table>
//       </div>
//     </>
//   );
// };

// export default ContentRenderer;


import React from 'react';
import { formatDate, formatField } from './shared/utils';
import { StatusLabel } from './shared/StatusLabel.jsx';
import PaymentRouter from '../../../utils/PaymentRouter.js';

const handlePaymentError = (error) => {
    // Handle payment errors (you can add your own error handling logic here)
    console.error('Payment error:', error);
    // Optionally show an error message to the user
  };

const MobileCard = ({ item, isExpanded, toggleDetails, globalIndex }) => {
  const firstPlan = item.treatmentPlans?.[0];
  
  return (
    <div className="bg-white rounded-lg shadow-md p-4 mb-4">
      {/* Card Header */}
      <div className="flex justify-between items-start mb-4">
        <div>
          <p className="text-sm text-gray-500">
            {firstPlan?.createdAt ? formatDate(firstPlan.createdAt) : "N/A"}
          </p>
          <h3 className="text-lg font-semibold text-gray-900">
            {formatField(item.patient?.name ?? "Unknown")}
          </h3>
        </div>
        <StatusLabel status={firstPlan?.status || "No Status"} />
      </div>

      {/* Card Content */}
      <div className="mb-4">
        <p className="text-gray-600">
          <span className="font-medium">
            {item.type === "PAIN" ? "Symptoms: " : "Area of Concern: "}
          </span>
          {item.type === "PAIN"
            ? item.presentingSymptoms?.length
              ? formatField(item.presentingSymptoms[0])
              : "No Symptoms Reported"
            : item.areaOfConcern?.length
            ? formatField(item.areaOfConcern[0])
            : "No Areas Specified"}
        </p>
      </div>

      {/* Expand/Collapse Button */}
      <button
        onClick={() => toggleDetails(globalIndex)}
        className="w-full flex items-center justify-center text-teal-600 hover:text-teal-800 transition-colors"
      >
        <span className="mr-2">{isExpanded ? "Show Less" : "Show More"}</span>
        {isExpanded ? (
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 15l7-7 7 7" />
          </svg>
        ) : (
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
          </svg>
        )}
      </button>

      {/* Expanded Content */}
      {isExpanded && (
        <div className="mt-4 pt-4 border-t border-gray-200">
          <div className="space-y-4">
            {/* Patient Info */}
            <div>
              <h4 className="font-semibold mb-2 text-gray-900">Patient Information</h4>
              <div className="space-y-2">
                <p className="text-gray-600">
                  <span className="font-medium">Name:</span> {item.patient?.name}
                </p>
                <p className="text-gray-600">
                  <span className="font-medium">Sex:</span> {item.patient?.sex || "N/A"}
                </p>
                <p className="text-gray-600">
                  <span className="font-medium">Date of Birth:</span>{" "}
                  {item.patient?.dateOfBirth || "N/A"}
                </p>
              </div>
            </div>

            {/* Assessment Details */}
            <div>
              <h4 className="font-semibold mb-2 text-gray-900">
                {item.type === "PAIN" ? "Pain Assessment" : "Beauty Assessment"}
              </h4>
              <div className="space-y-2">
                {item.type === "PAIN" ? (
                  <>
                    <p className="text-gray-600">
                      <span className="font-medium">Severity:</span>{" "}
                      {formatField(item.severityScore) ?? "N/A"}
                    </p>
                    <p className="text-gray-600">
                      <span className="font-medium">Onset:</span>{" "}
                      {formatField(item.onset) || "Unknown"}
                    </p>
                    <p className="text-gray-600">
                      <span className="font-medium">Symptoms:</span>{" "}
                      {formatField(item.presentingSymptoms?.join(", ")) || "None reported"}
                    </p>
                  </>
                ) : (
                  <>
                    <p className="text-gray-600">
                      <span className="font-medium">Areas of Concern:</span>{" "}
                      {formatField(item.areaOfConcern?.join(", ")) || "N/A"}
                    </p>
                    <p className="text-gray-600">
                      <span className="font-medium">Anti-Aging Concerns:</span>{" "}
                      {formatField(item.antiAgingConcern?.join(", ")) || "N/A"}
                    </p>
                  </>
                )}
              </div>
            </div>

            {/* Treatment Plan */}
            <div>
              <h4 className="font-semibold mb-2 text-gray-900">Treatment Plan</h4>
              {firstPlan ? (
                <div className="space-y-2">
                  <p className="text-gray-600">
                    <span className="font-medium">Recommendation:</span>{" "}
                    {firstPlan.recommendedTreatment || "N/A"}
                  </p>
                  <p className="text-gray-600">
                    <span className="font-medium">Comments:</span>{" "}
                    {firstPlan.additionalComments || "No comments"}
                  </p>
                  {firstPlan.status === "UNPAID" && (
                    <PaymentRouter
                      recommendedTreatment={firstPlan.recommendedTreatment}
                      treatmentPlanId={firstPlan.id}
                      onError={handlePaymentError}
                    />
                  )}
                </div>
              ) : (
                <p className="text-gray-600">No treatment plan available</p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

// Content renderer that switches between table and cards
const ContentRenderer = ({ 
  currentItems, 
  expandedRows, 
  toggleDetails, 
  indexOfFirstItem,
  selectedType,
  renderTableRows 
}) => {
  return (
    <>
      {/* Mobile Card View */}
      <div className="md:hidden space-y-4 p-4">
        {currentItems.length > 0 ? (
          currentItems.map((item, idx) => {
            const globalIndex = indexOfFirstItem + idx;
            return (
              <MobileCard
                key={globalIndex}
                item={item}
                isExpanded={expandedRows[globalIndex]}
                toggleDetails={toggleDetails}
                globalIndex={globalIndex}
              />
            );
          })
        ) : (
          <div className="text-center py-8 text-gray-500">
            No {selectedType?.toLowerCase()} treatment plans found
          </div>
        )}
      </div>

      {/* Desktop Table View */}
      <div className="hidden md:block">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-teal-700">
            <tr>
              <th className="px-4 py-3.5 text-lg font-bold text-left text-white">Date</th>
              <th className="px-4 py-3.5 text-lg font-bold text-left text-white">Patient</th>
              <th className="px-4 py-3.5 text-lg font-bold text-left text-white">Status</th>
              <th className="px-4 py-3.5 text-lg font-bold text-left text-white">
                {selectedType === "PAIN" ? "Presenting Symptoms" : "Area of Concern"}
              </th>
              <th className="py-3.5 px-4">
                <span className="sr-only">View</span>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-300">
            {currentItems.length > 0 ? (
              renderTableRows()
            ) : (
              <tr>
                <td colSpan="5" className="px-4 py-8 text-center text-gray-500">
                  No {selectedType?.toLowerCase()} treatment plans found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ContentRenderer;