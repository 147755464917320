/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPatientDirect = /* GraphQL */ `
  mutation CreatePatientDirect($input: CreatePatientInput!) {
    createPatientDirect(input: $input) {
      id
      name
      email
      phone
      address
      dateOfBirth
      sex
      weight
      painAssessments {
        nextToken
        startedAt
        __typename
      }
      beautyAssessments {
        nextToken
        startedAt
        __typename
      }
      prescriptionRenewals {
        nextToken
        startedAt
        __typename
      }
      painLogs {
        nextToken
        startedAt
        __typename
      }
      availabilities {
        nextToken
        startedAt
        __typename
      }
      appointments {
        nextToken
        startedAt
        __typename
      }
      user {
        id
        username
        name
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userPatientsId
      owner
      __typename
    }
  }
`;
export const createCheckoutSession = /* GraphQL */ `
  mutation CreateCheckoutSession($productName: String!, $treatmentPlanId: ID!) {
    createCheckoutSession(
      productName: $productName
      treatmentPlanId: $treatmentPlanId
    ) {
      sessionId
      url
      __typename
    }
  }
`;
export const handleStripeWebhook = /* GraphQL */ `
  mutation HandleStripeWebhook($event: AWSJSON!) {
    handleStripeWebhook(event: $event) {
      received
      __typename
    }
  }
`;
export const sendTreatmentPlanEmail = /* GraphQL */ `
  mutation SendTreatmentPlanEmail($input: SendEmailInput!) {
    sendTreatmentPlanEmail(input: $input) {
      success
      message
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      username
      name
      email
      patients {
        nextToken
        startedAt
        __typename
      }
      carts {
        nextToken
        startedAt
        __typename
      }
      doctorAppointments {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      name
      email
      patients {
        nextToken
        startedAt
        __typename
      }
      carts {
        nextToken
        startedAt
        __typename
      }
      doctorAppointments {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      username
      name
      email
      patients {
        nextToken
        startedAt
        __typename
      }
      carts {
        nextToken
        startedAt
        __typename
      }
      doctorAppointments {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createPatient = /* GraphQL */ `
  mutation CreatePatient(
    $input: CreatePatientInput!
    $condition: ModelPatientConditionInput
  ) {
    createPatient(input: $input, condition: $condition) {
      id
      name
      email
      phone
      address
      dateOfBirth
      sex
      weight
      painAssessments {
        nextToken
        startedAt
        __typename
      }
      beautyAssessments {
        nextToken
        startedAt
        __typename
      }
      prescriptionRenewals {
        nextToken
        startedAt
        __typename
      }
      painLogs {
        nextToken
        startedAt
        __typename
      }
      availabilities {
        nextToken
        startedAt
        __typename
      }
      appointments {
        nextToken
        startedAt
        __typename
      }
      user {
        id
        username
        name
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userPatientsId
      owner
      __typename
    }
  }
`;
export const updatePatient = /* GraphQL */ `
  mutation UpdatePatient(
    $input: UpdatePatientInput!
    $condition: ModelPatientConditionInput
  ) {
    updatePatient(input: $input, condition: $condition) {
      id
      name
      email
      phone
      address
      dateOfBirth
      sex
      weight
      painAssessments {
        nextToken
        startedAt
        __typename
      }
      beautyAssessments {
        nextToken
        startedAt
        __typename
      }
      prescriptionRenewals {
        nextToken
        startedAt
        __typename
      }
      painLogs {
        nextToken
        startedAt
        __typename
      }
      availabilities {
        nextToken
        startedAt
        __typename
      }
      appointments {
        nextToken
        startedAt
        __typename
      }
      user {
        id
        username
        name
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userPatientsId
      owner
      __typename
    }
  }
`;
export const deletePatient = /* GraphQL */ `
  mutation DeletePatient(
    $input: DeletePatientInput!
    $condition: ModelPatientConditionInput
  ) {
    deletePatient(input: $input, condition: $condition) {
      id
      name
      email
      phone
      address
      dateOfBirth
      sex
      weight
      painAssessments {
        nextToken
        startedAt
        __typename
      }
      beautyAssessments {
        nextToken
        startedAt
        __typename
      }
      prescriptionRenewals {
        nextToken
        startedAt
        __typename
      }
      painLogs {
        nextToken
        startedAt
        __typename
      }
      availabilities {
        nextToken
        startedAt
        __typename
      }
      appointments {
        nextToken
        startedAt
        __typename
      }
      user {
        id
        username
        name
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userPatientsId
      owner
      __typename
    }
  }
`;
export const createPrescriptionRenewal = /* GraphQL */ `
  mutation CreatePrescriptionRenewal(
    $input: CreatePrescriptionRenewalInput!
    $condition: ModelPrescriptionRenewalConditionInput
  ) {
    createPrescriptionRenewal(input: $input, condition: $condition) {
      id
      patientId
      patient {
        id
        name
        email
        phone
        address
        dateOfBirth
        sex
        weight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userPatientsId
        owner
        __typename
      }
      prescriptionFile
      notes
      status
      reviewedBy
      reviewedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updatePrescriptionRenewal = /* GraphQL */ `
  mutation UpdatePrescriptionRenewal(
    $input: UpdatePrescriptionRenewalInput!
    $condition: ModelPrescriptionRenewalConditionInput
  ) {
    updatePrescriptionRenewal(input: $input, condition: $condition) {
      id
      patientId
      patient {
        id
        name
        email
        phone
        address
        dateOfBirth
        sex
        weight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userPatientsId
        owner
        __typename
      }
      prescriptionFile
      notes
      status
      reviewedBy
      reviewedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deletePrescriptionRenewal = /* GraphQL */ `
  mutation DeletePrescriptionRenewal(
    $input: DeletePrescriptionRenewalInput!
    $condition: ModelPrescriptionRenewalConditionInput
  ) {
    deletePrescriptionRenewal(input: $input, condition: $condition) {
      id
      patientId
      patient {
        id
        name
        email
        phone
        address
        dateOfBirth
        sex
        weight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userPatientsId
        owner
        __typename
      }
      prescriptionFile
      notes
      status
      reviewedBy
      reviewedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createPainLog = /* GraphQL */ `
  mutation CreatePainLog(
    $input: CreatePainLogInput!
    $condition: ModelPainLogConditionInput
  ) {
    createPainLog(input: $input, condition: $condition) {
      id
      date
      painLevel
      location
      notes
      patient {
        id
        name
        email
        phone
        address
        dateOfBirth
        sex
        weight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userPatientsId
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      patientPainLogsId
      owner
      __typename
    }
  }
`;
export const updatePainLog = /* GraphQL */ `
  mutation UpdatePainLog(
    $input: UpdatePainLogInput!
    $condition: ModelPainLogConditionInput
  ) {
    updatePainLog(input: $input, condition: $condition) {
      id
      date
      painLevel
      location
      notes
      patient {
        id
        name
        email
        phone
        address
        dateOfBirth
        sex
        weight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userPatientsId
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      patientPainLogsId
      owner
      __typename
    }
  }
`;
export const deletePainLog = /* GraphQL */ `
  mutation DeletePainLog(
    $input: DeletePainLogInput!
    $condition: ModelPainLogConditionInput
  ) {
    deletePainLog(input: $input, condition: $condition) {
      id
      date
      painLevel
      location
      notes
      patient {
        id
        name
        email
        phone
        address
        dateOfBirth
        sex
        weight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userPatientsId
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      patientPainLogsId
      owner
      __typename
    }
  }
`;
export const createAvailability = /* GraphQL */ `
  mutation CreateAvailability(
    $input: CreateAvailabilityInput!
    $condition: ModelAvailabilityConditionInput
  ) {
    createAvailability(input: $input, condition: $condition) {
      id
      patientId
      patient {
        id
        name
        email
        phone
        address
        dateOfBirth
        sex
        weight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userPatientsId
        owner
        __typename
      }
      date
      timeSlots
      notes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      patientAvailabilitiesId
      owner
      __typename
    }
  }
`;
export const updateAvailability = /* GraphQL */ `
  mutation UpdateAvailability(
    $input: UpdateAvailabilityInput!
    $condition: ModelAvailabilityConditionInput
  ) {
    updateAvailability(input: $input, condition: $condition) {
      id
      patientId
      patient {
        id
        name
        email
        phone
        address
        dateOfBirth
        sex
        weight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userPatientsId
        owner
        __typename
      }
      date
      timeSlots
      notes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      patientAvailabilitiesId
      owner
      __typename
    }
  }
`;
export const deleteAvailability = /* GraphQL */ `
  mutation DeleteAvailability(
    $input: DeleteAvailabilityInput!
    $condition: ModelAvailabilityConditionInput
  ) {
    deleteAvailability(input: $input, condition: $condition) {
      id
      patientId
      patient {
        id
        name
        email
        phone
        address
        dateOfBirth
        sex
        weight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userPatientsId
        owner
        __typename
      }
      date
      timeSlots
      notes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      patientAvailabilitiesId
      owner
      __typename
    }
  }
`;
export const createAppointment = /* GraphQL */ `
  mutation CreateAppointment(
    $input: CreateAppointmentInput!
    $condition: ModelAppointmentConditionInput
  ) {
    createAppointment(input: $input, condition: $condition) {
      id
      patientId
      patient {
        id
        name
        email
        phone
        address
        dateOfBirth
        sex
        weight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userPatientsId
        owner
        __typename
      }
      doctorId
      doctor {
        id
        username
        name
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      date
      time
      status
      notes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      patientAppointmentsId
      owner
      __typename
    }
  }
`;
export const updateAppointment = /* GraphQL */ `
  mutation UpdateAppointment(
    $input: UpdateAppointmentInput!
    $condition: ModelAppointmentConditionInput
  ) {
    updateAppointment(input: $input, condition: $condition) {
      id
      patientId
      patient {
        id
        name
        email
        phone
        address
        dateOfBirth
        sex
        weight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userPatientsId
        owner
        __typename
      }
      doctorId
      doctor {
        id
        username
        name
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      date
      time
      status
      notes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      patientAppointmentsId
      owner
      __typename
    }
  }
`;
export const deleteAppointment = /* GraphQL */ `
  mutation DeleteAppointment(
    $input: DeleteAppointmentInput!
    $condition: ModelAppointmentConditionInput
  ) {
    deleteAppointment(input: $input, condition: $condition) {
      id
      patientId
      patient {
        id
        name
        email
        phone
        address
        dateOfBirth
        sex
        weight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userPatientsId
        owner
        __typename
      }
      doctorId
      doctor {
        id
        username
        name
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      date
      time
      status
      notes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      patientAppointmentsId
      owner
      __typename
    }
  }
`;
export const createPainAssessment = /* GraphQL */ `
  mutation CreatePainAssessment(
    $input: CreatePainAssessmentInput!
    $condition: ModelPainAssessmentConditionInput
  ) {
    createPainAssessment(input: $input, condition: $condition) {
      id
      patientId
      patient {
        id
        name
        email
        phone
        address
        dateOfBirth
        sex
        weight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userPatientsId
        owner
        __typename
      }
      currentMedication
      currentMedicationY
      healthHistory
      allergies
      redFlagSigns
      redFlagSignsOther
      presentingSymptoms
      traumaticOther
      presentingSymptomsOther
      severityScore
      onset
      onsetOther
      location
      locationOther
      bodySide
      bodySideOther
      additionalNotes
      createdAt
      updatedAt
      treatmentPlans {
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updatePainAssessment = /* GraphQL */ `
  mutation UpdatePainAssessment(
    $input: UpdatePainAssessmentInput!
    $condition: ModelPainAssessmentConditionInput
  ) {
    updatePainAssessment(input: $input, condition: $condition) {
      id
      patientId
      patient {
        id
        name
        email
        phone
        address
        dateOfBirth
        sex
        weight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userPatientsId
        owner
        __typename
      }
      currentMedication
      currentMedicationY
      healthHistory
      allergies
      redFlagSigns
      redFlagSignsOther
      presentingSymptoms
      traumaticOther
      presentingSymptomsOther
      severityScore
      onset
      onsetOther
      location
      locationOther
      bodySide
      bodySideOther
      additionalNotes
      createdAt
      updatedAt
      treatmentPlans {
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deletePainAssessment = /* GraphQL */ `
  mutation DeletePainAssessment(
    $input: DeletePainAssessmentInput!
    $condition: ModelPainAssessmentConditionInput
  ) {
    deletePainAssessment(input: $input, condition: $condition) {
      id
      patientId
      patient {
        id
        name
        email
        phone
        address
        dateOfBirth
        sex
        weight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userPatientsId
        owner
        __typename
      }
      currentMedication
      currentMedicationY
      healthHistory
      allergies
      redFlagSigns
      redFlagSignsOther
      presentingSymptoms
      traumaticOther
      presentingSymptomsOther
      severityScore
      onset
      onsetOther
      location
      locationOther
      bodySide
      bodySideOther
      additionalNotes
      createdAt
      updatedAt
      treatmentPlans {
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createBeautyAssessment = /* GraphQL */ `
  mutation CreateBeautyAssessment(
    $input: CreateBeautyAssessmentInput!
    $condition: ModelBeautyAssessmentConditionInput
  ) {
    createBeautyAssessment(input: $input, condition: $condition) {
      id
      patientId
      patient {
        id
        name
        email
        phone
        address
        dateOfBirth
        sex
        weight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userPatientsId
        owner
        __typename
      }
      pregnantOrBreastfeeding
      skinType
      areaOfConcern
      smootherSkinTone
      improvedSkinTone
      increasedHydrationAndPlumpness
      reducedAppearanceOfFineLinesAndWrinkles
      firmerAppearance
      currentBeautyProducts
      currentBeautyProductsY
      beautyAllergies
      createdAt
      updatedAt
      treatmentPlans {
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateBeautyAssessment = /* GraphQL */ `
  mutation UpdateBeautyAssessment(
    $input: UpdateBeautyAssessmentInput!
    $condition: ModelBeautyAssessmentConditionInput
  ) {
    updateBeautyAssessment(input: $input, condition: $condition) {
      id
      patientId
      patient {
        id
        name
        email
        phone
        address
        dateOfBirth
        sex
        weight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userPatientsId
        owner
        __typename
      }
      pregnantOrBreastfeeding
      skinType
      areaOfConcern
      smootherSkinTone
      improvedSkinTone
      increasedHydrationAndPlumpness
      reducedAppearanceOfFineLinesAndWrinkles
      firmerAppearance
      currentBeautyProducts
      currentBeautyProductsY
      beautyAllergies
      createdAt
      updatedAt
      treatmentPlans {
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteBeautyAssessment = /* GraphQL */ `
  mutation DeleteBeautyAssessment(
    $input: DeleteBeautyAssessmentInput!
    $condition: ModelBeautyAssessmentConditionInput
  ) {
    deleteBeautyAssessment(input: $input, condition: $condition) {
      id
      patientId
      patient {
        id
        name
        email
        phone
        address
        dateOfBirth
        sex
        weight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userPatientsId
        owner
        __typename
      }
      pregnantOrBreastfeeding
      skinType
      areaOfConcern
      smootherSkinTone
      improvedSkinTone
      increasedHydrationAndPlumpness
      reducedAppearanceOfFineLinesAndWrinkles
      firmerAppearance
      currentBeautyProducts
      currentBeautyProductsY
      beautyAllergies
      createdAt
      updatedAt
      treatmentPlans {
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createPainTreatmentPlan = /* GraphQL */ `
  mutation CreatePainTreatmentPlan(
    $input: CreatePainTreatmentPlanInput!
    $condition: ModelPainTreatmentPlanConditionInput
  ) {
    createPainTreatmentPlan(input: $input, condition: $condition) {
      id
      painAssessmentId
      painAssessment {
        id
        patientId
        currentMedication
        currentMedicationY
        healthHistory
        allergies
        redFlagSigns
        redFlagSignsOther
        presentingSymptoms
        traumaticOther
        presentingSymptomsOther
        severityScore
        onset
        onsetOther
        location
        locationOther
        bodySide
        bodySideOther
        additionalNotes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      status
      createdAt
      updatedAt
      recommendedTreatment
      additionalComments
      stripeTransactionId
      prescriptionFile
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updatePainTreatmentPlan = /* GraphQL */ `
  mutation UpdatePainTreatmentPlan(
    $input: UpdatePainTreatmentPlanInput!
    $condition: ModelPainTreatmentPlanConditionInput
  ) {
    updatePainTreatmentPlan(input: $input, condition: $condition) {
      id
      painAssessmentId
      painAssessment {
        id
        patientId
        currentMedication
        currentMedicationY
        healthHistory
        allergies
        redFlagSigns
        redFlagSignsOther
        presentingSymptoms
        traumaticOther
        presentingSymptomsOther
        severityScore
        onset
        onsetOther
        location
        locationOther
        bodySide
        bodySideOther
        additionalNotes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      status
      createdAt
      updatedAt
      recommendedTreatment
      additionalComments
      stripeTransactionId
      prescriptionFile
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deletePainTreatmentPlan = /* GraphQL */ `
  mutation DeletePainTreatmentPlan(
    $input: DeletePainTreatmentPlanInput!
    $condition: ModelPainTreatmentPlanConditionInput
  ) {
    deletePainTreatmentPlan(input: $input, condition: $condition) {
      id
      painAssessmentId
      painAssessment {
        id
        patientId
        currentMedication
        currentMedicationY
        healthHistory
        allergies
        redFlagSigns
        redFlagSignsOther
        presentingSymptoms
        traumaticOther
        presentingSymptomsOther
        severityScore
        onset
        onsetOther
        location
        locationOther
        bodySide
        bodySideOther
        additionalNotes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      status
      createdAt
      updatedAt
      recommendedTreatment
      additionalComments
      stripeTransactionId
      prescriptionFile
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createBeautyTreatmentPlan = /* GraphQL */ `
  mutation CreateBeautyTreatmentPlan(
    $input: CreateBeautyTreatmentPlanInput!
    $condition: ModelBeautyTreatmentPlanConditionInput
  ) {
    createBeautyTreatmentPlan(input: $input, condition: $condition) {
      id
      beautyAssessmentId
      beautyAssessment {
        id
        patientId
        pregnantOrBreastfeeding
        skinType
        areaOfConcern
        smootherSkinTone
        improvedSkinTone
        increasedHydrationAndPlumpness
        reducedAppearanceOfFineLinesAndWrinkles
        firmerAppearance
        currentBeautyProducts
        currentBeautyProductsY
        beautyAllergies
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      status
      createdAt
      updatedAt
      recommendedTreatment
      additionalComments
      stripeTransactionId
      prescriptionFile
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateBeautyTreatmentPlan = /* GraphQL */ `
  mutation UpdateBeautyTreatmentPlan(
    $input: UpdateBeautyTreatmentPlanInput!
    $condition: ModelBeautyTreatmentPlanConditionInput
  ) {
    updateBeautyTreatmentPlan(input: $input, condition: $condition) {
      id
      beautyAssessmentId
      beautyAssessment {
        id
        patientId
        pregnantOrBreastfeeding
        skinType
        areaOfConcern
        smootherSkinTone
        improvedSkinTone
        increasedHydrationAndPlumpness
        reducedAppearanceOfFineLinesAndWrinkles
        firmerAppearance
        currentBeautyProducts
        currentBeautyProductsY
        beautyAllergies
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      status
      createdAt
      updatedAt
      recommendedTreatment
      additionalComments
      stripeTransactionId
      prescriptionFile
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteBeautyTreatmentPlan = /* GraphQL */ `
  mutation DeleteBeautyTreatmentPlan(
    $input: DeleteBeautyTreatmentPlanInput!
    $condition: ModelBeautyTreatmentPlanConditionInput
  ) {
    deleteBeautyTreatmentPlan(input: $input, condition: $condition) {
      id
      beautyAssessmentId
      beautyAssessment {
        id
        patientId
        pregnantOrBreastfeeding
        skinType
        areaOfConcern
        smootherSkinTone
        improvedSkinTone
        increasedHydrationAndPlumpness
        reducedAppearanceOfFineLinesAndWrinkles
        firmerAppearance
        currentBeautyProducts
        currentBeautyProductsY
        beautyAllergies
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      status
      createdAt
      updatedAt
      recommendedTreatment
      additionalComments
      stripeTransactionId
      prescriptionFile
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createMedication = /* GraphQL */ `
  mutation CreateMedication(
    $input: CreateMedicationInput!
    $condition: ModelMedicationConditionInput
  ) {
    createMedication(input: $input, condition: $condition) {
      id
      name
      description
      basePrice
      schedule
      strengths
      frequencies
      sku
      dosageForm
      sideEffects
      imageUrl
      cartItems {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateMedication = /* GraphQL */ `
  mutation UpdateMedication(
    $input: UpdateMedicationInput!
    $condition: ModelMedicationConditionInput
  ) {
    updateMedication(input: $input, condition: $condition) {
      id
      name
      description
      basePrice
      schedule
      strengths
      frequencies
      sku
      dosageForm
      sideEffects
      imageUrl
      cartItems {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteMedication = /* GraphQL */ `
  mutation DeleteMedication(
    $input: DeleteMedicationInput!
    $condition: ModelMedicationConditionInput
  ) {
    deleteMedication(input: $input, condition: $condition) {
      id
      name
      description
      basePrice
      schedule
      strengths
      frequencies
      sku
      dosageForm
      sideEffects
      imageUrl
      cartItems {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createCart = /* GraphQL */ `
  mutation CreateCart(
    $input: CreateCartInput!
    $condition: ModelCartConditionInput
  ) {
    createCart(input: $input, condition: $condition) {
      id
      userId
      createdAt
      items {
        nextToken
        startedAt
        __typename
      }
      user {
        id
        username
        name
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userCartsId
      owner
      __typename
    }
  }
`;
export const updateCart = /* GraphQL */ `
  mutation UpdateCart(
    $input: UpdateCartInput!
    $condition: ModelCartConditionInput
  ) {
    updateCart(input: $input, condition: $condition) {
      id
      userId
      createdAt
      items {
        nextToken
        startedAt
        __typename
      }
      user {
        id
        username
        name
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userCartsId
      owner
      __typename
    }
  }
`;
export const deleteCart = /* GraphQL */ `
  mutation DeleteCart(
    $input: DeleteCartInput!
    $condition: ModelCartConditionInput
  ) {
    deleteCart(input: $input, condition: $condition) {
      id
      userId
      createdAt
      items {
        nextToken
        startedAt
        __typename
      }
      user {
        id
        username
        name
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userCartsId
      owner
      __typename
    }
  }
`;
export const createCartItem = /* GraphQL */ `
  mutation CreateCartItem(
    $input: CreateCartItemInput!
    $condition: ModelCartItemConditionInput
  ) {
    createCartItem(input: $input, condition: $condition) {
      id
      cartId
      medicationId
      quantity
      price
      medication {
        id
        name
        description
        basePrice
        schedule
        strengths
        frequencies
        sku
        dosageForm
        sideEffects
        imageUrl
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      cart {
        id
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userCartsId
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      medicationCartItemsId
      cartItemsId
      owner
      __typename
    }
  }
`;
export const updateCartItem = /* GraphQL */ `
  mutation UpdateCartItem(
    $input: UpdateCartItemInput!
    $condition: ModelCartItemConditionInput
  ) {
    updateCartItem(input: $input, condition: $condition) {
      id
      cartId
      medicationId
      quantity
      price
      medication {
        id
        name
        description
        basePrice
        schedule
        strengths
        frequencies
        sku
        dosageForm
        sideEffects
        imageUrl
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      cart {
        id
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userCartsId
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      medicationCartItemsId
      cartItemsId
      owner
      __typename
    }
  }
`;
export const deleteCartItem = /* GraphQL */ `
  mutation DeleteCartItem(
    $input: DeleteCartItemInput!
    $condition: ModelCartItemConditionInput
  ) {
    deleteCartItem(input: $input, condition: $condition) {
      id
      cartId
      medicationId
      quantity
      price
      medication {
        id
        name
        description
        basePrice
        schedule
        strengths
        frequencies
        sku
        dosageForm
        sideEffects
        imageUrl
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      cart {
        id
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userCartsId
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      medicationCartItemsId
      cartItemsId
      owner
      __typename
    }
  }
`;
export const createContactSubmission = /* GraphQL */ `
  mutation CreateContactSubmission(
    $input: CreateContactSubmissionInput!
    $condition: ModelContactSubmissionConditionInput
  ) {
    createContactSubmission(input: $input, condition: $condition) {
      id
      name
      email
      message
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateContactSubmission = /* GraphQL */ `
  mutation UpdateContactSubmission(
    $input: UpdateContactSubmissionInput!
    $condition: ModelContactSubmissionConditionInput
  ) {
    updateContactSubmission(input: $input, condition: $condition) {
      id
      name
      email
      message
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteContactSubmission = /* GraphQL */ `
  mutation DeleteContactSubmission(
    $input: DeleteContactSubmissionInput!
    $condition: ModelContactSubmissionConditionInput
  ) {
    deleteContactSubmission(input: $input, condition: $condition) {
      id
      name
      email
      message
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createAssessmentAndTreatmentPlan = /* GraphQL */ `
  mutation CreateAssessmentAndTreatmentPlan($input: CreateAssessmentInput!) {
    createAssessmentAndTreatmentPlan(input: $input) {
      painAssessment {
        id
        patientId
        currentMedication
        currentMedicationY
        healthHistory
        allergies
        redFlagSigns
        redFlagSignsOther
        presentingSymptoms
        traumaticOther
        presentingSymptomsOther
        severityScore
        onset
        onsetOther
        location
        locationOther
        bodySide
        bodySideOther
        additionalNotes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      beautyAssessment {
        id
        patientId
        pregnantOrBreastfeeding
        skinType
        areaOfConcern
        smootherSkinTone
        improvedSkinTone
        increasedHydrationAndPlumpness
        reducedAppearanceOfFineLinesAndWrinkles
        firmerAppearance
        currentBeautyProducts
        currentBeautyProductsY
        beautyAllergies
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      painTreatmentPlan {
        id
        painAssessmentId
        status
        createdAt
        updatedAt
        recommendedTreatment
        additionalComments
        stripeTransactionId
        prescriptionFile
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      beautyTreatmentPlan {
        id
        beautyAssessmentId
        status
        createdAt
        updatedAt
        recommendedTreatment
        additionalComments
        stripeTransactionId
        prescriptionFile
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
