// import React from 'react';
// import PaymentRouter from '../../utils/PaymentRouter';

// export const AssessmentResult = ({ 
//   assessment, 
//   type, 
//   recommendedTreatment, 
//   status,
//   treatmentPlanId
// }) => {
//   const getStatusConfig = () => {
//     switch (status) {
//       case 'UNPAID':
//         return {
//           variant: 'success',
//           icon: '✓',
//           title: 'Treatment Recommended',
//           description: 'Your assessment has been reviewed and a treatment has been recommended.'
//         };
//       case 'PENDING_REVIEW':
//         return {
//           variant: 'warning',
//           icon: '⚠️',
//           title: 'Under Review',
//           description: 'Your assessment requires additional review by our healthcare team.'
//         };
//       case 'SEE_DOCTOR':
//         return {
//           variant: 'danger',
//           icon: '⚕️',
//           title: 'Medical Consultation Required',
//           description: 'Based on your assessment, we recommend consulting with a healthcare provider.'
//         };
//       default:
//         return {
//           variant: 'secondary',
//           icon: 'ℹ️',
//           title: 'Assessment Complete',
//           description: 'Your assessment has been recorded.'
//         };
//     }
//   };

//   const config = getStatusConfig();

//   const handlePaymentError = (error) => {
//     // Handle payment errors (you can add your own error handling logic here)
//     console.error('Payment error:', error);
//     // Optionally show an error message to the user
//   };

//   return (
//     <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-md overflow-hidden">
//       {/* Header */}
//       <div className={`p-6 ${
//         config.variant === 'success' ? 'bg-green-50 border-b border-green-200' :
//         config.variant === 'warning' ? 'bg-yellow-50 border-b border-yellow-200' :
//         config.variant === 'danger' ? 'bg-red-50 border-b border-red-200' :
//         'bg-gray-50 border-b border-gray-200'
//       }`}>
//         <div className="flex items-center gap-4">
//           <span className="text-2xl">{config.icon}</span>
//           <div>
//             <h3 className={`text-lg font-semibold ${
//               config.variant === 'success' ? 'text-green-700' :
//               config.variant === 'warning' ? 'text-yellow-700' :
//               config.variant === 'danger' ? 'text-red-700' :
//               'text-gray-700'
//             }`}>
//               {config.title}
//             </h3>
//             <p className="text-gray-600">{config.description}</p>
//           </div>
//         </div>
//       </div>

//       {/* Assessment Details */}
//       <div className="p-6">
//         {type === 'BEAUTY' && (
//           <div className="space-y-4">
//             <div>
//               <h4 className="font-medium text-gray-900">Areas of Concern</h4>
//               <p className="text-gray-600">
//                 {assessment.areaOfConcern?.join(', ') || 'Not specified'}
//               </p>
//             </div>
//             <div>
//               <h4 className="font-medium text-gray-900">Anti-Aging Concerns</h4>
//               <p className="text-gray-600">
//                 {assessment.antiAgingConcern?.join(', ') || 'Not specified'}
//               </p>
//             </div>
//           </div>
//         )}

//         {type === 'PAIN' && (
//           <div className="space-y-4">
//             <div>
//               <h4 className="font-medium text-gray-900">Pain Location</h4>
//               <p className="text-gray-600">
//                 {assessment.location?.join(', ') || 'Not specified'}
//               </p>
//             </div>
//             <div>
//               <h4 className="font-medium text-gray-900">Pain Severity</h4>
//               <p className="text-gray-600">
//                 {assessment.severityScore?.replace(/_/g, ' ').toLowerCase() || 'Not specified'}
//               </p>
//             </div>
//           </div>
//         )}

//         {/* Recommended Treatment */}
//         <div className="mt-6">
//           <h4 className="font-medium text-gray-900">Recommended Treatment</h4>
//           <p className="text-gray-800">{recommendedTreatment}</p>
//         </div>
//       </div>

//       {/* Footer with Actions */}
//       <div className="px-6 py-4 bg-gray-50 border-t border-gray-200 flex justify-end items-center gap-4">
//         <button className="px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors duration-200">
//           Download Prescription
//         </button>
        
//         {status === 'UNPAID' && (
//           <PaymentRouter 
//             recommendedTreatment={recommendedTreatment}
//             treatmentPlanId={treatmentPlanId}  
//             onError={handlePaymentError}
//           />
//         )}
//       </div>
//     </div>
//   );
// };

// export default AssessmentResult;


import React from 'react';
import { getUrl } from 'aws-amplify/storage';
import PaymentRouter from '../../utils/PaymentRouter';

export const AssessmentResult = ({ 
  assessment, 
  type, 
  recommendedTreatment, 
  status,
  treatmentPlanId,
  prescriptionFile
}) => {
  const getStatusConfig = () => {
    switch (status) {
      case 'UNPAID':
        return {
          variant: 'success',
          icon: '✓',
          title: 'Treatment Recommended',
          description: 'Your assessment has been reviewed and a treatment has been recommended.'
        };
      case 'PENDING_REVIEW':
        return {
          variant: 'warning',
          icon: '⚠️',
          title: 'Under Review',
          description: 'Your assessment requires additional review by our healthcare team.'
        };
      case 'SEE_DOCTOR':
        return {
          variant: 'danger',
          icon: '⚕️',
          title: 'Medical Consultation Required',
          description: 'Based on your assessment, we recommend consulting with a healthcare provider.'
        };
      default:
        return {
          variant: 'secondary',
          icon: 'ℹ️',
          title: 'Assessment Complete',
          description: 'Your assessment has been recorded.'
        };
    }
  };

  const handleDownload = async () => {
    if (!prescriptionFile) {
      console.error('No prescription file available');
      return;
    }

    try {
      // Get the signed URL for the file
      const result = await getUrl({
        path: prescriptionFile,
        options: {
          // accessLevel: 'private',
          expiresIn: 60 // URL expires in 60 seconds
        }
      });

      // Create a temporary anchor element to trigger download
      const link = document.createElement('a');
      link.href = result.url;
      link.target = '_blank';
      // Extract filename from the S3 key for the download
      const filename = prescriptionFile.split('/').pop();
      link.download = filename || 'prescription.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading prescription:', error);
      // You might want to show an error message to the user here
    }
  };

  const config = getStatusConfig();

  const handlePaymentError = (error) => {
    console.error('Payment error:', error);
  };

  return (
    <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-md overflow-hidden">
      {/* Header */}
      <div className={`p-6 ${
        config.variant === 'success' ? 'bg-green-50 border-b border-green-200' :
        config.variant === 'warning' ? 'bg-yellow-50 border-b border-yellow-200' :
        config.variant === 'danger' ? 'bg-red-50 border-b border-red-200' :
        'bg-gray-50 border-b border-gray-200'
      }`}>
        <div className="flex items-center gap-4">
          <span className="text-2xl">{config.icon}</span>
          <div>
            <h3 className={`text-lg font-semibold ${
              config.variant === 'success' ? 'text-green-700' :
              config.variant === 'warning' ? 'text-yellow-700' :
              config.variant === 'danger' ? 'text-red-700' :
              'text-gray-700'
            }`}>
              {config.title}
            </h3>
            <p className="text-gray-600">{config.description}</p>
          </div>
        </div>
      </div>

      {/* Assessment Details */}
      <div className="p-6">
        {type === 'BEAUTY' && (
          <div className="space-y-4">
            <div>
              <h4 className="font-medium text-gray-900">Areas of Concern</h4>
              <p className="text-gray-600">
                {assessment.areaOfConcern?.join(', ') || 'Not specified'}
              </p>
            </div>
            <div>
              <h4 className="font-medium text-gray-900">Anti-Aging Concerns</h4>
              <p className="text-gray-600">
                {assessment.antiAgingConcern?.join(', ') || 'Not specified'}
              </p>
            </div>
          </div>
        )}

        {type === 'PAIN' && (
          <div className="space-y-4">
            <div>
              <h4 className="font-medium text-gray-900">Pain Location</h4>
              <p className="text-gray-600">
                {assessment.location?.join(', ') || 'Not specified'}
              </p>
            </div>
            <div>
              <h4 className="font-medium text-gray-900">Pain Severity</h4>
              <p className="text-gray-600">
                {assessment.severityScore?.replace(/_/g, ' ').toLowerCase() || 'Not specified'}
              </p>
            </div>
          </div>
        )}

        {/* Recommended Treatment */}
        <div className="mt-6">
          <h4 className="font-medium text-gray-900">Recommended Treatment</h4>
          <p className="text-gray-800">{recommendedTreatment}</p>
        </div>
      </div>

      {/* Footer with Actions */}
      <div className="px-6 py-4 bg-gray-50 border-t border-gray-200 flex justify-end items-center gap-4">
        <button 
          onClick={handleDownload}
          disabled={!prescriptionFile}
          className={`px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-lg transition-colors duration-200 ${
            prescriptionFile ? 'hover:bg-gray-50' : 'opacity-50 cursor-not-allowed'
          }`}
        >
          Download Prescription
        </button>
        
        {status === 'UNPAID' && (
          <PaymentRouter 
            recommendedTreatment={recommendedTreatment}
            treatmentPlanId={treatmentPlanId}  
            onError={handlePaymentError}
          />
        )}
      </div>
    </div>
  );
};

export default AssessmentResult;