import { motion, useScroll, useTransform } from "framer-motion";
import * as React from "react";
import { useState, useRef, useEffect } from "react";
import formImage from "../../assets/form1.png"
import pills from "../../assets/pills.png"
import canadaPost from "../../assets/canadaPost.png"
import { Link } from 'react-router-dom';
import { BenefitsSlider } from "./BenefitsSlider";

function HomePage() {
  const { scrollYProgress } = useScroll();
  const ref = useRef(null);

  const y = useTransform(scrollYProgress, [0, 1], [0, -300]);
  const opacity = useTransform(scrollYProgress, [0, 0.2, 1], [1, 0.3, 0]);

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'module';
    script.src = 'https://unpkg.com/@splinetool/viewer@1.9.54/build/spline-viewer.js';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  function FAQItem({ question, answer, isOpen, onClick }) {
      return (
          <div>
              <button
                  className="flex items-center focus:outline-none"
                  onClick={onClick}
              >
                  <svg
                      className={`flex-shrink-0 w-6 h-6 text-teal-700 transition-transform transform ${
                          isOpen ? "rotate-180" : ""
                      }`}
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d={isOpen ? "M20 12H4" : "M12 4v16m8-8H4"}
                      />
                  </svg>
                  <h1 className="mx-4 text-xl text-left text-black dark:text-white">{question}</h1>
              </button>
              <div
                  className={`overflow-hidden transition-all duration-300 ${
                      isOpen ? "max-h-40" : "max-h-0"
                  }`}
              >
                  <div className="flex mt-8 md:mx-10">
                      <span className="border border-teal-700"></span>
                      <p className="max-w-3xl px-4 text-gray-800 dark:text-gray-800">
                          {answer}
                      </p>
                  </div>
              </div>
          </div>
      );
  }
  
  const faqs = [
      {
          question: "How do I get started with OneScript?",
          answer:
              "To get started with OneScript, simply fill out our user-friendly web form with your details and symptoms. " +
              "Our healthcare professionals will review your information and provide a prescription tailored to your needs.",
      },
      {
        question: "What kind of products can I buy from OneScript?",
        answer:
            "OneScript offers a variety of pain management and beauty products, including pain creams, gels, patches, as well as " +
            "skincare solutions and beauty treatments. All products are carefully selected to provide effective results and are " +
            "available for purchase once you have a prescription.",
      },
      {
          question: "How long does it take to receive my prescription?",
          answer:
              "After you submit the web form, our healthcare professionals will review your information and typically provide a prescription within 24 hours. " +
              "You will be notified via email once your prescription is ready.",
      },
      {
        question: "Is there a consultation fee?",
        answer:
            "No, there is no consultation fee for our services. We believe in making healthcare accessible and affordable for everyone.",
      },
      {
        question: "Can I use my insurance to pay for OneScript services?",
        answer:
            "Currently, OneScript operates on a pay-and-submit model. However, we are actively working on implementing direct insurance billing " +
            "with several insurance partners. Please check with your insurance provider regarding coverage details and reimbursement options. " +
            "We're committed to making our services more accessible through insurance partnerships.",
      },
      {
          question: "How will I receive my medication?",
          answer:
              "Once you have a prescription, you can purchase your medication directly from OneScript. " +
              "Your products will be shipped to your doorstep within a few business days.",
      },
      {
          question: "Is a referral required to use OneScript?",
          answer:
              "No referral is required to use OneScript. You can directly fill out the web form and receive a prescription from our healthcare professionals.",
      },
      {
          question: "Can I get an old prescription refilled, renewed, or modified?",
          answer:
              "Yes, you can. Simply fill out the web form with the details of your previous prescription, " +
              "and our healthcare professionals will review your request and provide the necessary adjustments.",
      },
  ];
  
  function FAQSection() {
      const [openIndex, setOpenIndex] = useState(null);
   
  
  
      const handleClick = (index) => {
          setOpenIndex(openIndex === index ? null : index);
      };
  
      return (
          <section className="bg-gray-50 ">
              <div className="container px-6 py-10 mx-auto">
                  <h1 className="text-2xl font-semibold text-black lg:text-3xl dark:text-white">
                      You Have Questions. We Have Answers
                  </h1>
                  <hr className="my-6 border-gray-300 dark:border-gray-700"/>
                  <div>
                      {faqs.map((faq, index) => (
                          <div key={index}>
                              <FAQItem
                                  question={faq.question}
                                  answer={faq.answer}
                                  isOpen={openIndex === index}
                                  onClick={() => handleClick(index)}
                              />
                              <hr className="my-8 border-gray-300 dark:border-gray-700"/>
                          </div>
                      ))}
                  </div>
              </div>
          </section>
      );
  }


  return (
    <div className="relative">
        
 {/* Hero Section with Spline */}
      <motion.div 
        className="relative h-screen flex items-center bg-gradient-to-r from-teal-500 to-teal-900 shadow overflow-hidden"
      >
        {/* Spline Container with scroll-based movement */}
        <motion.div 
          className="absolute inset-0 z-0"
          style={{ y }} // Add this line to enable vertical movement on scroll
        >
          <spline-viewer 
            loading-anim-type="spinner-small-dark"
            url="https://prod.spline.design/pnCY4JUbp-DRz9gK/scene.splinecode"
          />
        </motion.div>

        {/* Hero Content with fade effect */}
        <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div 
            className="relative backdrop-blur-lg bg-black/30 p-8 rounded-xl max-w-2xl"
            style={{ opacity }} // Add this line to enable fade effect on scroll
          >
            <motion.h1 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-5xl font-bold text-white"
            >
              Your Digital Healthcare Solution
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              className="mt-4 text-xl text-teal-100"
            >
              Access professional healthcare services from the comfort of your home
            </motion.p>
          </motion.div>
        </div>
      </motion.div>

      {/* Services Section */}
      <motion.div
        ref={ref}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        className="py-24 bg-gray-50"
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <Link to="/submitRequest?type=pain">
              {/* Service Cards with Hover Animations */}
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  className="bg-white p-6 rounded-lg shadow-lg"
                >
                  <h3 className="text-2xl font-bold text-teal-600">Pain Management</h3>
                  <p className="mt-4 text-gray-600">Expert solutions for chronic and acute pain</p>
                </motion.div>
              </Link>

              <Link to="/submitRequest?type=beauty">
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="bg-white p-6 rounded-lg shadow-lg"
            >
              <h3 className="text-2xl font-bold text-yellow-500">Beauty & Anti-Aging</h3>
              <p className="mt-4 text-gray-600">Advanced skincare treatments and solutions</p>
            </motion.div>
            </Link>

            {/* <motion.div
              whileHover={{ scale: 1.05 }}
              className="bg-white p-6 rounded-lg shadow-lg"
            >
              <h3 className="text-2xl font-bold text-blue-600">Sleep Solutions</h3>
              <p className="mt-4 text-gray-600">Improve your sleep quality naturally</p>
            </motion.div> */}
          </div>
        </div>
      </motion.div>

      <BenefitsSlider />

      <motion.div 
  className="py-24 bg-gray-50"
  initial={{ opacity: 0 }}
  whileInView={{ opacity: 1 }}
  viewport={{ once: true }}
>
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <h2 className="text-4xl font-bold text-center mb-16">How It Works</h2>
    
    <div className="relative">
      {/* Progress Line */}
      <div className="absolute left-1/2 transform -translate-x-1/2 h-full w-1 bg-gray-200" />
      
      {/* Step 1 */}
      <motion.div 
        className="relative flex items-center mb-24"
        initial={{ x: -100, opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6 }}
      >
        <div className="w-1/2 pr-16 text-right">
          <h3 className="text-2xl font-bold text-teal-600 mb-4">1. Complete Assessment</h3>
          <p className="text-gray-600">Fill out our comprehensive questionnaire tailored to your needs</p>
        </div>
        <div className="absolute left-1/2 transform -translate-x-1/2 w-12 h-12 bg-teal-600 rounded-full flex items-center justify-center">
          <motion.svg 
            className="w-6 h-6 text-white"
            initial={{ scale: 0 }}
            whileInView={{ scale: 1 }}
            viewport={{ once: true }}
            transition={{ delay: 0.3 }}
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
          </motion.svg>
        </div>
        <motion.div 
            className="w-1/2 pl-16"
            initial={{ x: 100, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
        >
            <img 
            src={formImage}
            alt="Medical Assessment Form"
            className="w-96 h-96 object-contain mx-auto"
            />
        </motion.div>
      </motion.div>

      {/* Step 2 */}
      <motion.div 
        className="relative flex items-center mb-24"
        initial={{ x: 100, opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6 }}
      >
        <motion.div 
            className="w-1/2 pl-16"
            initial={{ x: 100, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
        >
            <img 
            src={pills}
            alt="pills"
            className="w-96 h-96 object-contain mb-4 -ml-12 sm:mx-auto"
            />
        </motion.div>
        <div className="absolute left-1/2 transform -translate-x-1/2 w-12 h-12 bg-teal-600 rounded-full flex items-center justify-center">
          <motion.svg 
            className="w-6 h-6 text-white"
            initial={{ scale: 0 }}
            whileInView={{ scale: 1 }}
            viewport={{ once: true }}
            transition={{ delay: 0.3 }}
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </motion.svg>
        </div>
        <div className="w-1/2 ml-auto pl-16">
          <h3 className="text-2xl font-bold text-teal-600 mb-4">2. Receive a treatment plan</h3>
          <p className="text-gray-600">Our healthcare professionals review your case within 24 hours</p>
        </div>
      </motion.div>

      {/* Step 3 */}
      <motion.div 
        className="relative flex items-center"
        initial={{ x: -100, opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6 }}
      >
        <div className="w-1/2 pr-16 text-right">
          <h3 className="text-2xl font-bold text-teal-600 mb-4">3. Get Your Medication Delivered</h3>
          <p className="text-gray-600">Your prescribed medication will be shipped directly to your doorstep</p>
        </div>
        <div className="absolute left-1/2 transform -translate-x-1/2 w-12 h-12 bg-teal-600 rounded-full flex items-center justify-center">
          <motion.svg 
            className="w-6 h-6 text-white"
            initial={{ scale: 0 }}
            whileInView={{ scale: 1 }}
            viewport={{ once: true }}
            transition={{ delay: 0.3 }}
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor"
          >
            <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth={2} 
            d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z"
        />
        <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth={2}
            d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0"
        />
        </motion.svg>
        </div>
        <motion.div 
            className="w-1/2 pl-16"
            initial={{ x: 100, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
        >
            <img 
            src={canadaPost}
            alt="canada post mailer"
            className="w-96 h-96 object-contain mx-auto"
            />
        </motion.div>
      </motion.div>
    </div>
  </div>
</motion.div>



    
      

      <FAQSection/>
    </div>
  );
}

export default HomePage;