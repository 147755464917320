import React from 'react';

const TableLoadingSkeleton = () => {
  return (
    <div className="bg-gray-50">
      <div className="flex justify-between items-center md:px-16 lg:px-[16rem]">
        <div className="py-12 mt-6">
          <div className="h-8 bg-gray-200 rounded w-48 animate-pulse"></div>
        </div>
        <div className="py-12 mt-6">
          <div className="h-10 bg-gray-200 rounded w-36 animate-pulse"></div>
        </div>
      </div>
      
      <section className="container px-4 mb-12 mx-auto">
        {/* Type Toggle Skeleton */}
        <div className="flex justify-center space-x-4 mb-8">
          <div className="h-10 bg-gray-200 rounded w-32 animate-pulse"></div>
          <div className="h-10 bg-gray-200 rounded w-32 animate-pulse"></div>
        </div>

        <div className="inline-block mb-24 min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="mx-auto max-w-screen-xl px-4 lg:px-12">
            <div className="bg-white relative shadow-md rounded-lg overflow-hidden">
              {/* Filter UI Skeleton */}
              <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
                <div className="w-full md:w-1/2">
                  <div className="h-10 bg-gray-200 rounded w-full animate-pulse"></div>
                </div>
                <div className="w-32">
                  <div className="h-10 bg-gray-200 rounded w-full animate-pulse"></div>
                </div>
              </div>

              {/* Table Header Skeleton */}
              <div className="border-t border-gray-200">
                <div className="grid grid-cols-5 bg-gray-100 p-4">
                  {[...Array(4)].map((_, i) => (
                    <div key={i} className="h-6 bg-gray-200 rounded w-24 animate-pulse"></div>
                  ))}
                </div>

                {/* Table Rows Skeleton */}
                {[...Array(5)].map((_, index) => (
                  <div key={index} className="grid grid-cols-5 p-4 border-t border-gray-200">
                    <div className="h-6 bg-gray-200 rounded w-24 animate-pulse"></div>
                    <div className="h-6 bg-gray-200 rounded w-32 animate-pulse"></div>
                    <div className="h-6 bg-gray-200 rounded w-20 animate-pulse"></div>
                    <div className="h-6 bg-gray-200 rounded w-40 animate-pulse"></div>
                    <div className="h-6 bg-gray-200 rounded w-8 animate-pulse justify-self-end"></div>
                  </div>
                ))}
              </div>

              {/* Pagination Skeleton */}
              <div className="flex items-center justify-between p-4 border-t border-gray-200">
                <div className="h-8 bg-gray-200 rounded w-48 animate-pulse"></div>
                <div className="flex space-x-2">
                  {[...Array(3)].map((_, i) => (
                    <div key={i} className="h-8 bg-gray-200 rounded w-8 animate-pulse"></div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TableLoadingSkeleton;