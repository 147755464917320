// // // In utils/emailService.js
// // import { generateClient } from 'aws-amplify/api';
// // import { sendTreatmentPlanEmail } from '../graphql/mutations';
// // import { createCheckoutSession } from '../graphql/mutations';

// // // Product mapping
// // const PRODUCT_PRICE_MAP = {
// //   'Glow & Plump-Eyes': 'price_1QiOPrKXAdmmrf6jyjvsGT9T',
// //   'Glow & Plump': 'price_1QiOMQKXAdmmrf6jCl62ib5O',
// //   'Glow & Hydrate': 'price_1QiONTKXAdmmrf6jI6HiEvxM',
// //   'Glow & Smooth': 'price_1QiOP6KXAdmmrf6jR4y0GLOX',
// //   'Glow & Bright': 'price_1QiOX2KXAdmmrf6jOGzE7vJ8',
// //   'Voltaren Prescribed': 'price_1Q4VCZKXAdmmrf6jna7JDSqp'
// // };

// // // Extract product name from treatment recommendation
// // const extractProductName = (recommendation) => {
// //   if (!recommendation) return null;

// //   const products = Object.keys(PRODUCT_PRICE_MAP)
// //     .sort((a, b) => b.length - a.length);

// //   for (const product of products) {
// //     if (recommendation.toLowerCase().includes(product.toLowerCase())) {
// //       return product;
// //     }
// //   }
// //   return null;
// // };

// // // Get payment link from Stripe
// // const getPaymentLink = async (treatmentPlan) => {
// //   try {
// //     const productName = extractProductName(treatmentPlan.recommendedTreatment);
    
// //     if (!productName) {
// //       throw new Error('Unable to determine product from treatment plan');
// //     }

// //     const client = generateClient({
// //       authMode: 'userPool',
// //     });

// //     const response = await client.graphql({
// //       query: createCheckoutSession,
// //       variables: {
// //         productName,
// //         treatmentPlanId: treatmentPlan.id
// //       }
// //     });

// //     if (response.errors) {
// //       throw new Error(response.errors[0]?.message || 'Unknown GraphQL error');
// //     }

// //     return response.data.createCheckoutSession.url;
// //   } catch (error) {
// //     console.error('Error generating payment link:', error);
// //     throw error;
// //   }
// // };

// // export const sendPlanEmail = async ({
// //   patientEmail,
// //   patientName,
// //   treatmentPlan,
// //   assessmentType
// // }) => {
// //   try {
// //     const client = generateClient({
// //       authMode: 'userPool',
// //     });

// //     // Get payment link if status is UNPAID
// //     let paymentLink = null;
// //     if (treatmentPlan.status === 'UNPAID') {
// //       paymentLink = await getPaymentLink(treatmentPlan);
// //     }

// //     // Construct email content
// //     let emailSubject = `Your ${assessmentType} Treatment Plan is Ready`;
// //     let emailContent = `
// //       Dear ${patientName},

// //       Your ${assessmentType.toLowerCase()} treatment plan has been generated and is now available for review.

// //       Treatment Recommendations:
// //       ${treatmentPlan.recommendedTreatment}

// //       ${treatmentPlan.additionalComments ? `Additional Comments:\n${treatmentPlan.additionalComments}\n` : ''}
// //     `;

// //     // Add payment information if status is UNPAID and we have a payment link
// //     if (treatmentPlan.status === 'UNPAID' && paymentLink) {
// //       emailContent += `
// //       To proceed with your treatment, please complete your payment by clicking the link below:
// //       ${paymentLink}

// //       Your payment link will expire in 24 hours. If you need assistance, please contact our support team.
// //       `;
// //     }

// //     emailContent += `
// //       You can view your full treatment plan details by logging into your account at any time.

// //       Best regards,
// //       Your Healthcare Team
// //     `;

// //     // Send email using the GraphQL mutation
// //     const response = await client.graphql({
// //       query: sendTreatmentPlanEmail,
// //       variables: {
// //         input: {
// //           to: patientEmail,
// //           subject: emailSubject,
// //           body: emailContent,
// //           treatmentPlanId: treatmentPlan.id
// //         }
// //       }
// //     });

// //     return {
// //       success: true,
// //       paymentLink,
// //       emailResponse: response.data.sendPatientEmail
// //     };
// //   } catch (error) {
// //     console.error('Error in email service:', error);
// //     throw error;
// //   }
// // };

// // // Higher-level function to handle the entire notification process
// // export const handleTreatmentPlanNotification = async ({
// //   patient,
// //   treatmentPlan,
// //   assessmentType
// // }) => {
// //   try {
// //     const emailResult = await sendTreatmentPlanEmail({
// //       patientEmail: patient.email,
// //       patientName: patient.name,
// //       treatmentPlan,
// //       assessmentType
// //     });

// //     return emailResult;
// //   } catch (error) {
// //     console.error('Error in treatment plan notification:', error);
// //     throw error;
// //   }
// // };

// // utils/emailService.js
// import { generateClient } from 'aws-amplify/api';
// import { sendTreatmentPlanEmail, createCheckoutSession } from '../graphql/mutations';

// // Product mapping
// const PRODUCT_PRICE_MAP = {
//   'Glow & Plump-Eyes': 'price_1QiOPrKXAdmmrf6jyjvsGT9T',
//   'Glow & Plump': 'price_1QiOMQKXAdmmrf6jCl62ib5O',
//   'Glow & Hydrate': 'price_1QiONTKXAdmmrf6jI6HiEvxM',
//   'Glow & Smooth': 'price_1QiOP6KXAdmmrf6jR4y0GLOX',
//   'Glow & Bright': 'price_1QiOX2KXAdmmrf6jOGzE7vJ8',
//   'Voltaren Prescribed': 'price_1Q4VCZKXAdmmrf6jna7JDSqp'
// };

// // Extract product name from treatment recommendation
// const extractProductName = (recommendation) => {
//   if (!recommendation) return null;

//   const products = Object.keys(PRODUCT_PRICE_MAP)
//     .sort((a, b) => b.length - a.length);

//   for (const product of products) {
//     if (recommendation.toLowerCase().includes(product.toLowerCase())) {
//       return product;
//     }
//   }
//   return null;
// };

// // Get payment link from Stripe
// const getPaymentLink = async (treatmentPlan) => {
//   try {
//     const productName = extractProductName(treatmentPlan.recommendedTreatment);
    
//     if (!productName) {
//       throw new Error('Unable to determine product from treatment plan');
//     }

//     const client = generateClient({
//       authMode: 'userPool',
//     });

//     const response = await client.graphql({
//       query: createCheckoutSession,
//       variables: {
//         productName,
//         treatmentPlanId: treatmentPlan.id
//       }
//     });

//     if (response.errors) {
//       throw new Error(response.errors[0]?.message || 'Unknown GraphQL error');
//     }

//     return response.data.createCheckoutSession.url;
//   } catch (error) {
//     console.error('Error generating payment link:', error);
//     throw error;
//   }
// };

// // Send the treatment plan email
// export const sendPlanEmail = async ({
//   patientEmail,
//   patientName,
//   treatmentPlan,
//   assessmentType
// }) => {
//   try {
//     const client = generateClient({
//       authMode: 'userPool',
//     });

//     // Get payment link if status is UNPAID
//     let paymentLink = null;
//     if (treatmentPlan.status === 'UNPAID') {
//       paymentLink = await getPaymentLink(treatmentPlan);
//     }

//     // Construct email content
//     let emailSubject = `Your ${assessmentType} Treatment Plan is Ready`;
//     let emailContent = `
//       Dear ${patientName},

//       Your ${assessmentType.toLowerCase()} treatment plan has been generated and is now available for review.

//       Treatment Recommendations:
//       ${treatmentPlan.recommendedTreatment}

//       ${treatmentPlan.additionalComments ? `Additional Comments:\n${treatmentPlan.additionalComments}\n` : ''}
//     `;

//     // Add payment information if status is UNPAID and we have a payment link
//     if (treatmentPlan.status === 'UNPAID' && paymentLink) {
//       emailContent += `
//       To proceed with your treatment, please complete your payment by clicking the link below:
//       ${paymentLink}

//       Your payment link will expire in 24 hours. If you need assistance, please contact our support team.
//       `;
//     }

//     emailContent += `
//       You can view your full treatment plan details by logging into your account at any time.

//       Best regards,
//       Your Healthcare Team
//     `;

//     // Send email using the GraphQL mutation
//     const response = await client.graphql({
//       query: sendTreatmentPlanEmail,
//       variables: {
//         input: {
//           to: patientEmail,
//           subject: emailSubject,
//           body: emailContent,
//           treatmentPlanId: treatmentPlan.id
//         }
//       }
//     });

//     return {
//       success: true,
//       paymentLink,
//       emailResponse: response.data.sendTreatmentPlanEmail
//     };
//   } catch (error) {
//     console.error('Error in email service:', error);
//     throw error;
// }
// };

// // Higher-level function to handle the entire notification process
// export const handleTreatmentPlanNotification = async ({
//   patient,
//   treatmentPlan,
//   assessmentType
// }) => {
//   try {
//     const emailResult = await sendPlanEmail({
//       patientEmail: patient.email,
//       patientName: patient.name,
//       treatmentPlan,
//       assessmentType
//     });

//     return emailResult;
//   } catch (error) {
//     console.error('Error in treatment plan notification:', error);
//     throw error;
//   }
// };
    

// utils/emailService.js
import { generateClient } from 'aws-amplify/api';
import { sendTreatmentPlanEmail, createCheckoutSession } from '../graphql/mutations';

// Product mapping remains the same
const PRODUCT_PRICE_MAP = {
  'Glow & Plump-Eyes': 'price_1QiOPrKXAdmmrf6jyjvsGT9T',
  'Glow & Plump': 'price_1QiOMQKXAdmmrf6jCl62ib5O',
  'Glow & Hydrate': 'price_1QiONTKXAdmmrf6jI6HiEvxM',
  'Glow & Smooth': 'price_1QiOP6KXAdmmrf6jR4y0GLOX',
  'Glow & Bright': 'price_1QiOX2KXAdmmrf6jOGzE7vJ8',
  'Voltaren Prescribed': 'price_1Q4VCZKXAdmmrf6jna7JDSqp'
};

// Extract product name function remains the same
const extractProductName = (recommendation) => {
  if (!recommendation) return null;
  const products = Object.keys(PRODUCT_PRICE_MAP)
    .sort((a, b) => b.length - a.length);
  for (const product of products) {
    if (recommendation.toLowerCase().includes(product.toLowerCase())) {
      return product;
    }
  }
  return null;
};

// Get payment link function remains the same
const getPaymentLink = async (treatmentPlan) => {
  try {
    const productName = extractProductName(treatmentPlan.recommendedTreatment);
    if (!productName) {
      throw new Error('Unable to determine product from treatment plan');
    }
    const client = generateClient({
      authMode: 'userPool',
    });
    const response = await client.graphql({
      query: createCheckoutSession,
      variables: {
        productName,
        treatmentPlanId: treatmentPlan.id
      }
    });
    if (response.errors) {
      throw new Error(response.errors[0]?.message || 'Unknown GraphQL error');
    }
    return response.data.createCheckoutSession.url;
  } catch (error) {
    console.error('Error generating payment link:', error);
    throw error;
  }
};

// HTML email template
const getEmailTemplate = ({
  patientName,
  assessmentType,
  treatmentPlan,
  paymentLink
}) => `
<!DOCTYPE html>
<html>
<head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Your Treatment Plan</title>
</head>
<body style="margin: 0; padding: 0; font-family: Arial, sans-serif; line-height: 1.6; color: #333333; background-color: #f5f5f5;">
    <div style="max-width: 600px; margin: 0 auto; padding: 20px;">
        <div style="background-color: #ffffff; border-radius: 8px; padding: 30px; box-shadow: 0 2px 4px rgba(0,0,0,0.1);">
            <!-- Header -->
            <div style="text-align: center; margin-bottom: 30px;">
                <h1 style="color: #0d9488; margin: 0; font-size: 24px; font-weight: 600;">Your Treatment Plan is Ready</h1>
                <p style="color: #718096; margin-top: 8px; font-size: 16px;">${assessmentType.toLowerCase()} Assessment Results</p>
            </div>

            <!-- Greeting -->
            <p style="font-size: 16px; margin-bottom: 20px;">Dear ${patientName},</p>

            <!-- Main Content -->
            <p style="font-size: 16px; margin-bottom: 20px;">Your ${assessmentType.toLowerCase()} treatment plan has been generated and is now available for review.</p>

            <!-- Treatment Recommendations -->
            <div style="background-color: #f7fafc; border-left: 4px solid #14b8a6; padding: 15px; margin-bottom: 25px; border-radius: 4px;">
                <h2 style="color: #2d3748; margin: 0 0 10px 0; font-size: 18px;">Treatment Recommendations</h2>
                <p style="margin: 0; color: #4a5568; font-size: 16px;">${treatmentPlan.recommendedTreatment}</p>
            </div>

            ${treatmentPlan.additionalComments ? `
            <!-- Additional Comments -->
            <div style="background-color: #f7fafc; padding: 15px; margin-bottom: 25px; border-radius: 4px;">
                <h2 style="color: #2d3748; margin: 0 0 10px 0; font-size: 18px;">Additional Comments</h2>
                <p style="margin: 0; color: #4a5568; font-size: 16px;">${treatmentPlan.additionalComments}</p>
            </div>
            ` : ''}

            ${paymentLink ? `
            <!-- Payment Section -->
            <div style="background-color: #ccfbf1; border-radius: 4px; padding: 20px; margin: 25px 0;">
                <h2 style="color: #0d9488; margin: 0 0 15px 0; font-size: 18px;">Complete Your Treatment Plan</h2>
                <p style="margin: 0 0 15px 0; color: #2d3748; font-size: 16px;">To proceed with your treatment, please complete your payment by clicking the button below:</p>
                <div style="text-align: center;">
                    <a href="${paymentLink}" style="display: inline-block; background-color: #14b8a6; color: white; padding: 12px 24px; text-decoration: none; border-radius: 4px; font-weight: 600; font-size: 16px;">Complete Payment</a>
                </div>
                <p style="margin: 15px 0 0 0; color: #718096; font-size: 14px;">Your payment link will expire in 24 hours. If you need assistance, please contact our support team.</p>
            </div>
            ` : ''}

            <!-- Account Access Reminder -->
            <p style="font-size: 16px; margin-bottom: 25px; color: #4a5568;">You can view your full treatment plan details by logging into your account at any time.</p>

            <!-- Footer -->
            <div style="margin-top: 30px; padding-top: 20px; border-top: 1px solid #e2e8f0; color: #718096;">
                <p style="margin: 0; font-size: 16px;">Best regards,</p>
                <p style="margin: 5px 0 0 0; font-weight: 600; color: #2d3748;">Your Healthcare Team</p>
            </div>
        </div>
    </div>
</body>
</html>
`;

// Updated send plan email function
export const sendPlanEmail = async ({
  patientEmail,
  patientName,
  treatmentPlan,
  assessmentType
}) => {
  try {
    const client = generateClient({
      authMode: 'userPool',
    });

    // Get payment link if status is UNPAID
    let paymentLink = null;
    if (treatmentPlan.status === 'UNPAID') {
      paymentLink = await getPaymentLink(treatmentPlan);
    }

    // Construct email subject and content using HTML template
    const emailSubject = `Your ${assessmentType.toLowerCase()} Treatment Plan is Ready`;
    const emailContent = getEmailTemplate({
      patientName,
      assessmentType,
      treatmentPlan,
      paymentLink
    });

    // Send email using the GraphQL mutation
    const response = await client.graphql({
      query: sendTreatmentPlanEmail,
      variables: {
        input: {
          to: patientEmail,
          subject: emailSubject,
          body: emailContent,
          treatmentPlanId: treatmentPlan.id,
          isHtml: true  // Make sure your backend supports HTML emails
        }
      }
    });

    return {
      success: true,
      paymentLink,
      emailResponse: response.data.sendTreatmentPlanEmail
    };
  } catch (error) {
    console.error('Error in email service:', error);
    throw error;
  }
};

// handleTreatmentPlanNotification function remains the same
export const handleTreatmentPlanNotification = async ({
  patient,
  treatmentPlan,
  assessmentType
}) => {
  try {
    const emailResult = await sendPlanEmail({
      patientEmail: patient.email,
      patientName: patient.name,
      treatmentPlan,
      assessmentType
    });
    return emailResult;
  } catch (error) {
    console.error('Error in treatment plan notification:', error);
    throw error;
  }
};