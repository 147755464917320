// PaymentRouter.js
import React, { useState } from 'react';
import { generateClient } from 'aws-amplify/api';
import { createCheckoutSession } from '../graphql/mutations';

export const PaymentRouter = ({
  recommendedTreatment,
  treatmentPlanId,
  onError
}) => {
  const [isLoading, setIsLoading] = useState(false);

  // Match the exact price IDs from the Lambda function
  const PRODUCT_PRICE_MAP = {
    'Glow & Plump-Eyes': 'price_1QiOPrKXAdmmrf6jyjvsGT9T',
    'Glow & Plump': 'price_1QiOMQKXAdmmrf6jCl62ib5O',
    'Glow & Hydrate': 'price_1QiONTKXAdmmrf6jI6HiEvxM',
    'Glow & Smooth': 'price_1QiOP6KXAdmmrf6jR4y0GLOX',
    'Glow & Bright': 'price_1QiOX2KXAdmmrf6jOGzE7vJ8'
  };

  const extractProductName = (recommendation) => {
    if (!recommendation) {
      console.log('No recommendation provided');
      return null;
    }

    console.log('Processing recommendation:', recommendation);
    
    // Sort by length to match most specific first
    const products = Object.keys(PRODUCT_PRICE_MAP)
      .sort((a, b) => b.length - a.length);

    for (const product of products) {
      if (recommendation.toLowerCase().includes(product.toLowerCase())) {
        console.log('Found matching product:', product);
        return product;
      }
    }

    console.log('No matching product found');
    return null;
  };

  const handlePayment = async () => {
    setIsLoading(true);
    try {
      console.log('Starting payment process for:', recommendedTreatment);
      
      if (!treatmentPlanId) {
        throw new Error('Treatment plan ID is required');
      }

      const productName = extractProductName(recommendedTreatment);
      console.log('Extracted product name:', productName);
      
      if (!productName || !PRODUCT_PRICE_MAP[productName]) {
        throw new Error(`Invalid product name: ${productName}`);
      }

      const client = generateClient({
        authMode: 'userPool',
      });

      // Log the exact mutation and variables being sent
      console.log('Sending GraphQL mutation with variables:', {
        productName,
        treatmentPlanId
      });

      const response = await client.graphql({
        query: createCheckoutSession,
        variables: {
          productName,
          treatmentPlanId
        }
      });

      console.log('Received response:', response);

      if (response.errors) {
        throw new Error(response.errors[0]?.message || 'Unknown GraphQL error');
      }

      const { url } = response.data.createCheckoutSession;
      
      if (url) {
        window.location.href = url;
      } else {
        throw new Error('No checkout URL received');
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
      const errorMessage = error.message || 'Failed to initiate payment';
      onError?.(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <button
      onClick={handlePayment}
      disabled={isLoading}
      className="px-6 py-2 text-white bg-teal-600 rounded-lg hover:bg-teal-700 
                transition-colors duration-200 disabled:bg-gray-400 disabled:cursor-not-allowed"
    >
      {isLoading ? 'Processing...' : 'Proceed to Payment'}
    </button>
  );
};

export default PaymentRouter;