import { useEffect, useState } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { BeautyTreatmentPlan, PainTreatmentPlan } from '../../models';

// Custom Alert component to replace shadcn/ui Alert
const CustomAlert = ({ variant = 'default', children }) => {
  const styles = {
    error: 'bg-red-50 text-red-800 border-red-200',
    success: 'bg-green-50 text-green-800 border-green-200',
    default: 'bg-gray-50 text-gray-800 border-gray-200'
  };

  return (
    <div className={`p-4 rounded-lg border ${styles[variant]}`}>
      {children}
    </div>
  );
};

export default function PaymentSuccess() {
  const [status, setStatus] = useState('updating');
  const [error, setError] = useState(null);
  const [queryParams, setQueryParams] = useState(null);

  useEffect(() => {
    // Get query parameters from URL
    const params = new URLSearchParams(window.location.search);
    setQueryParams({
      session_id: params.get('session_id'),
      plan_type: params.get('plan_type'),
      plan_id: params.get('plan_id')
    });
  }, []);

  useEffect(() => {
    async function updateTreatmentPlan() {
      if (!queryParams) return;

      try {
        const { session_id, plan_type, plan_id } = queryParams;

        if (!session_id || !plan_type || !plan_id) {
          throw new Error('Missing required parameters');
        }

        // Choose the correct model based on plan type
        const TreatmentPlan = plan_type === 'BEAUTY'
          ? BeautyTreatmentPlan
          : PainTreatmentPlan;

        // Get the current plan
        const originalPlan = await DataStore.query(TreatmentPlan, plan_id);

        if (!originalPlan) {
          throw new Error('Treatment plan not found');
        }

        // Update the plan
        await DataStore.save(
          TreatmentPlan.copyOf(originalPlan, updated => {
            updated.status = 'PAID';
            updated.stripeTransactionId = session_id;
          })
        );

        setStatus('success');

        // Redirect to dashboard after 3 seconds
        setTimeout(() => {
          window.location.href = '/dashboard';
        }, 3000);
      } catch (err) {
        console.error('Error updating treatment plan:', err);
        setStatus('error');
        setError(err.message);
      }
    }

    if (queryParams) {
      updateTreatmentPlan();
    }
  }, [queryParams]);

  if (status === 'updating') {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <CustomAlert>
          <p className="text-gray-500">Processing your payment...</p>
        </CustomAlert>
      </div>
    );
  }

  if (status === 'success') {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <CustomAlert variant="success">
          <p>Payment successful! Redirecting to dashboard...</p>
        </CustomAlert>
      </div>
    );
  }

  if (status === 'error') {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <CustomAlert variant="error">
          <p>{error || 'An error occurred processing your payment. Please contact support.'}</p>
        </CustomAlert>
      </div>
    );
  }
}