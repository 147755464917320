// import { useEffect, useState } from 'react';
// import { DataStore } from '@aws-amplify/datastore';
// import { BeautyTreatmentPlan, PainTreatmentPlan } from '../../models';

// // Custom Alert component to maintain consistency
// const CustomAlert = ({ variant = 'default', children }) => {
//   const styles = {
//     error: 'bg-red-50 text-red-800 border-red-200',
//     success: 'bg-green-50 text-green-800 border-green-200',
//     default: 'bg-gray-50 text-gray-800 border-gray-200'
//   };

//   return (
//     <div className={`p-4 rounded-lg border ${styles[variant]}`}>
//       {children}
//     </div>
//   );
// };

// export default function PaymentFailed() {
//   const [error, setError] = useState(null);
//   const [isRetrying, setIsRetrying] = useState(false);
//   const [queryParams, setQueryParams] = useState(null);

//   useEffect(() => {
//     // Get query parameters from URL
//     const params = new URLSearchParams(window.location.search);
//     setQueryParams({
//       error_message: params.get('error_message'),
//       plan_type: params.get('plan_type'),
//       plan_id: params.get('plan_id')
//     });
//   }, []);

//   const handleRetryPayment = async () => {
//     if (!queryParams?.plan_id || !queryParams?.plan_type) {
//       setError('Missing required payment information');
//       return;
//     }

//     setIsRetrying(true);
//     try {
//       const TreatmentPlan = queryParams.plan_type === 'BEAUTY'
//         ? BeautyTreatmentPlan
//         : PainTreatmentPlan;

//       // Get the current plan
//       const plan = await DataStore.query(TreatmentPlan, queryParams.plan_id);

//       if (!plan) {
//         throw new Error('Treatment plan not found');
//       }

//       // Redirect to payment page
//       window.location.href = `/checkout?plan_id=${queryParams.plan_id}&plan_type=${queryParams.plan_type}`;
//     } catch (err) {
//       console.error('Error retrying payment:', err);
//       setError(err.message);
//       setIsRetrying(false);
//     }
//   };

//   const handleReturn = () => {
//     window.location.href = '/dashboard';
//   };

//   return (
//     <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
//       <div className="max-w-md w-full space-y-8">
//         <div className="text-center">
//           <h2 className="mt-6 text-3xl font-bold text-gray-900">
//             Payment Failed
//           </h2>
//           <p className="mt-2 text-sm text-gray-600">
//             {queryParams?.error_message || 'There was an issue processing your payment'}
//           </p>
//         </div>

//         <CustomAlert variant="error">
//           <div className="flex flex-col space-y-4">
//             <p className="text-sm">
//               {error || 'Your payment could not be processed. Would you like to try again?'}
//             </p>
            
//             <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0">
//               <button
//                 onClick={handleRetryPayment}
//                 disabled={isRetrying}
//                 className="w-full sm:w-auto flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50"
//               >
//                 {isRetrying ? 'Processing...' : 'Retry Payment'}
//               </button>
              
//               <button
//                 onClick={handleReturn}
//                 className="w-full sm:w-auto flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
//               >
//                 Return to Dashboard
//               </button>
//             </div>
//           </div>
//         </CustomAlert>

//         <div className="text-center">
//           <p className="text-sm text-gray-500">
//             Need help? Please contact our{' '}
//             <a href="/support" className="font-medium text-red-600 hover:text-red-500">
//               support team
//             </a>
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// }

// import { useEffect, useState } from 'react';
// import { DataStore } from '@aws-amplify/datastore';
// import { BeautyTreatmentPlan, PainTreatmentPlan } from '../../models';

// // Custom Alert component to maintain consistency
// const CustomAlert = ({ variant = 'default', children }) => {
//   const styles = {
//     error: 'bg-red-50 text-red-800 border-red-200',
//     success: 'bg-green-50 text-green-800 border-green-200',
//     warning: 'bg-yellow-50 text-yellow-800 border-yellow-200',
//     default: 'bg-gray-50 text-gray-800 border-gray-200'
//   };

//   return (
//     <div className={`p-4 rounded-lg border ${styles[variant]}`}>
//       {children}
//     </div>
//   );
// };

// export default function PaymentFailed() {
//   const [error, setError] = useState(null);
//   const [isRetrying, setIsRetrying] = useState(false);
//   const [queryParams, setQueryParams] = useState(null);
//   const [isValidSession, setIsValidSession] = useState(true);

//   useEffect(() => {
//     // Get and validate query parameters from URL
//     const params = new URLSearchParams(window.location.search);
//     const session_id = params.get('session_id');
//     const plan_type = params.get('plan_type');
//     const plan_id = params.get('plan_id');

//     // Validate required parameters
//     if (!session_id || !plan_type || !plan_id) {
//       setIsValidSession(false);
//       setError('Invalid payment session. Missing required payment information.');
//       return;
//     }

//     // Validate plan type
//     if (plan_type !== 'BEAUTY' && plan_type !== 'PAIN') {
//       setIsValidSession(false);
//       setError('Invalid treatment plan type specified.');
//       return;
//     }

//     setQueryParams({
//       session_id,
//       plan_type,
//       plan_id
//     });
//   }, []);

//   const handleRetryPayment = async () => {
//     if (!isValidSession) {
//       setError('Cannot retry payment with missing information. Please start a new checkout session.');
//       return;
//     }

//     setIsRetrying(true);
//     try {
//       const TreatmentPlan = queryParams.plan_type === 'BEAUTY'
//         ? BeautyTreatmentPlan
//         : PainTreatmentPlan;

//       // Get the current plan
//       const plan = await DataStore.query(TreatmentPlan, queryParams.plan_id);

//       if (!plan) {
//         throw new Error('Treatment plan not found. Please create a new plan.');
//       }

//       // Redirect to payment page with all necessary information
//       window.location.href = `https://checkout.stripe.com/c/pay/${queryParams.session_id}`;
//     } catch (err) {
//       console.error('Error retrying payment:', err);
//       setError(err.message);
//       setIsRetrying(false);
//     }
//   };

//   const handleStartNewCheckout = () => {
//     window.location.href = '/treatments';
//   };

//   const handleReturn = () => {
//     window.location.href = '/dashboard';
//   };

//   return (
//     <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
//       <div className="max-w-md w-full space-y-8">
//         <div className="text-center">
//           <h2 className="mt-6 text-3xl font-bold text-gray-900">
//             Payment Cancelled
//           </h2>
//           <p className="mt-2 text-sm text-gray-600">
//             Your payment session was cancelled
//           </p>
//         </div>

//         <CustomAlert variant={isValidSession ? "warning" : "error"}>
//           <div className="flex flex-col space-y-4">
//             <p className="text-sm">
//               {error || 'Your payment session was cancelled. Would you like to try again?'}
//             </p>
            
//             <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0">
//               {isValidSession ? (
//                 <button
//                   onClick={handleRetryPayment}
//                   disabled={isRetrying}
//                   className="w-full sm:w-auto flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50"
//                 >
//                   {isRetrying ? 'Processing...' : 'Retry Payment'}
//                 </button>
//               ) : (
//                 <button
//                   onClick={handleStartNewCheckout}
//                   className="w-full sm:w-auto flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
//                 >
//                   Start New Checkout
//                 </button>
//               )}
              
//               <button
//                 onClick={handleReturn}
//                 className="w-full sm:w-auto flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
//               >
//                 Return to Dashboard
//               </button>
//             </div>
//           </div>
//         </CustomAlert>

//         <div className="text-center">
//           <p className="text-sm text-gray-500">
//             Need help? Please contact our{' '}
//             <a href="/support" className="font-medium text-red-600 hover:text-red-500">
//               support team
//             </a>
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// }


import { useEffect, useState } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { generateClient } from 'aws-amplify/api';
import { createCheckoutSession } from '../../graphql/mutations';
import { BeautyTreatmentPlan, PainTreatmentPlan } from '../../models';

const CustomAlert = ({ variant = 'default', children }) => {
  const styles = {
    error: 'bg-red-50 text-red-800 border-red-200',
    success: 'bg-green-50 text-green-800 border-green-200',
    warning: 'bg-yellow-50 text-yellow-800 border-yellow-200',
    default: 'bg-gray-50 text-gray-800 border-gray-200'
  };

  return (
    <div className={`p-4 rounded-lg border ${styles[variant]}`}>
      {children}
    </div>
  );
};

export default function PaymentCancelled() {
  const [error, setError] = useState(null);
  const [isRetrying, setIsRetrying] = useState(false);
  const [queryParams, setQueryParams] = useState(null);
  const [isValidSession, setIsValidSession] = useState(true);
  const [treatmentPlan, setTreatmentPlan] = useState(null);

  // Product mapping as defined in PaymentRouter
  const PRODUCT_PRICE_MAP = {
    'Glow & Plump-Eyes': 'price_1QiOPrKXAdmmrf6jyjvsGT9T',
    'Glow & Plump': 'price_1QiOMQKXAdmmrf6jCl62ib5O',
    'Glow & Hydrate': 'price_1QiONTKXAdmmrf6jI6HiEvxM',
    'Glow & Smooth': 'price_1QiOP6KXAdmmrf6jR4y0GLOX',
    'Glow & Bright': 'price_1QiOX2KXAdmmrf6jOGzE7vJ8',
    'Voltaren Prescribed': 'price_1Q4VCZKXAdmmrf6jna7JDSqp'
  };

  // Extract product name from treatment recommendation
  const extractProductName = (recommendation) => {
    if (!recommendation) return null;

    const products = Object.keys(PRODUCT_PRICE_MAP)
      .sort((a, b) => b.length - a.length);

    for (const product of products) {
      if (recommendation.toLowerCase().includes(product.toLowerCase())) {
        return product;
      }
    }
    return null;
  };

  useEffect(() => {
    // Get and validate query parameters from URL
    const params = new URLSearchParams(window.location.search);
    const session_id = params.get('session_id');
    const plan_type = params.get('plan_type');
    const plan_id = params.get('plan_id');

    // Validate required parameters
    if (!session_id || !plan_type || !plan_id) {
      setIsValidSession(false);
      setError('Invalid payment session. Missing required payment information.');
      return;
    }

    // Validate plan type
    if (plan_type !== 'BEAUTY' && plan_type !== 'PAIN') {
      setIsValidSession(false);
      setError('Invalid treatment plan type specified.');
      return;
    }

    setQueryParams({
      session_id,
      plan_type,
      plan_id
    });

    // Fetch treatment plan details
    const fetchTreatmentPlan = async () => {
      try {
        const TreatmentPlan = plan_type === 'BEAUTY'
          ? BeautyTreatmentPlan
          : PainTreatmentPlan;

        const plan = await DataStore.query(TreatmentPlan, plan_id);
        if (!plan) {
          throw new Error('Treatment plan not found');
        }
        setTreatmentPlan(plan);
      } catch (err) {
        console.error('Error fetching treatment plan:', err);
        setIsValidSession(false);
        setError('Unable to load treatment plan details');
      }
    };

    fetchTreatmentPlan();
  }, []);

  const handleRetryPayment = async () => {
    if (!isValidSession || !treatmentPlan) {
      setError('Cannot retry payment with missing information. Please start a new checkout session.');
      return;
    }

    setIsRetrying(true);
    try {
      const productName = extractProductName(treatmentPlan.recommendedTreatment);
      
      if (!productName) {
        throw new Error('Unable to determine product from treatment plan');
      }

      const client = generateClient({
        authMode: 'userPool',
      });

      const response = await client.graphql({
        query: createCheckoutSession,
        variables: {
          productName,
          treatmentPlanId: queryParams.plan_id
        }
      });

      if (response.errors) {
        throw new Error(response.errors[0]?.message || 'Unknown GraphQL error');
      }

      const { url } = response.data.createCheckoutSession;
      
      if (url) {
        window.location.href = url;
      } else {
        throw new Error('No checkout URL received');
      }
    } catch (err) {
      console.error('Error retrying payment:', err);
      setError(err.message);
      setIsRetrying(false);
    }
  };

  const handleStartNewCheckout = () => {
    window.location.href = '/treatments';
  };

  const handleReturn = () => {
    window.location.href = '/dashboard';
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div className="text-center">
          <h2 className="mt-6 text-3xl font-bold text-gray-900">
            Payment Cancelled
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            Your payment session was cancelled
          </p>
        </div>

        <CustomAlert variant={isValidSession ? "warning" : "error"}>
          <div className="flex flex-col space-y-4">
            <p className="text-sm">
              {error || 'Your payment session was cancelled. Would you like to try again?'}
            </p>
            
            <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0">
              {isValidSession ? (
                <button
                  onClick={handleRetryPayment}
                  disabled={isRetrying}
                  className="w-full sm:w-auto flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50"
                >
                  {isRetrying ? 'Processing...' : 'Retry Payment'}
                </button>
              ) : (
                <button
                  onClick={handleStartNewCheckout}
                  className="w-full sm:w-auto flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  Start New Checkout
                </button>
              )}
              
              <button
                onClick={handleReturn}
                className="w-full sm:w-auto flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                Return to Dashboard
              </button>
            </div>
          </div>
        </CustomAlert>

        <div className="text-center">
          <p className="text-sm text-gray-500">
            Need help? Please contact our{' '}
            <a href="/contact" className="font-medium text-red-600 hover:text-red-500">
              support team
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}