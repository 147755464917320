
/* eslint-disable */
import * as React from "react";
import {
    Button,
    Flex,
    Grid,
    SelectField,
    TextField,
} from "@aws-amplify/ui-react";
import { Patient } from "../../models";
import { fetchByPath, getOverrideProps, validateField } from "../Common/customUtils";
import { DataStore } from "aws-amplify/datastore";
export default function PatientCreateForm(props) {
    const {
        clearOnSuccess = true,
        onSuccess,
        onError,
        onSubmit,
        onValidate,
        onChange,
        overrides,
        ...rest
    } = props;
    const initialValues = {
        name: "",
        email: "",
        phone: "",
        address: "",
        dateOfBirth: "",
        sex: "",
        weight: "",
    };
    const [name, setName] = React.useState(initialValues.name);
    const [email, setEmail] = React.useState(initialValues.email);
    const [phone, setPhone] = React.useState(initialValues.phone);
    const [address, setAddress] = React.useState(initialValues.address);
    const [dateOfBirth, setDateOfBirth] = React.useState(
        initialValues.dateOfBirth
    );
    const [sex, setSex] = React.useState(initialValues.sex);
    const [weight, setWeight] = React.useState(initialValues.weight);
    const [errors, setErrors] = React.useState({});
    const resetStateValues = () => {
        setName(initialValues.name);
        setEmail(initialValues.email);
        setPhone(initialValues.phone);
        setAddress(initialValues.address);
        setDateOfBirth(initialValues.dateOfBirth);
        setSex(initialValues.sex);
        setWeight(initialValues.weight);
        setErrors({});
    };
    const validations = {
        name: [{ type: "Required" }],
        email: [
            { type: "Required" },
            {
                type: "Contains",
                strValues: ["@"],
                validationMessage: 'The value must contain "@"',
            },
        ],
        phone: [{ type: "Required" }, { type: "Phone" }],
        address: [],
        dateOfBirth: [{ type: "Required" }],
        sex: [],
        weight: [],
    };
    const runValidationTasks = async (
        fieldName,
        currentValue,
        getDisplayValue
    ) => {
        const value =
            currentValue && getDisplayValue
                ? getDisplayValue(currentValue)
                : currentValue;
        let validationResponse = validateField(value, validations[fieldName]);
        const customValidator = fetchByPath(onValidate, fieldName);
        if (customValidator) {
            validationResponse = await customValidator(value, validationResponse);
        }
        setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
        return validationResponse;
    };
    return (
        <Grid
            as="form"
            rowGap="15px"
            columnGap="15px"
            padding="20px"
            onSubmit={async (event) => {
                event.preventDefault();
                let modelFields = {
                    name,
                    email,
                    phone,
                    address,
                    dateOfBirth,
                    sex,
                    weight,
                    userPatientsId: props.userId 
                };
                const validationResponses = await Promise.all(
                    Object.keys(validations).reduce((promises, fieldName) => {
                        if (Array.isArray(modelFields[fieldName])) {
                            promises.push(
                                ...modelFields[fieldName].map((item) =>
                                    runValidationTasks(fieldName, item)
                                )
                            );
                            return promises;
                        }
                        promises.push(
                            runValidationTasks(fieldName, modelFields[fieldName])
                        );
                        return promises;
                    }, [])
                );
                if (validationResponses.some((r) => r.hasError)) {
                    return;
                }
                if (onSubmit) {
                    modelFields = onSubmit(modelFields);
                }
                try {
                    Object.entries(modelFields).forEach(([key, value]) => {
                        if (typeof value === "string" && value === "") {
                            modelFields[key] = null;
                        }
                    });
                    const savedPatient = await DataStore.save(new Patient(modelFields));
                    if (onSuccess) {
                        console.log(savedPatient);
                        onSuccess(savedPatient);
                        props.onSuccess(savedPatient);
                    }
                    if (clearOnSuccess) {
                        resetStateValues();
                    }
                } catch (err) {
                    if (onError) {
                        console.error('Error during DataStore operation:', err);
                        onError(modelFields, err.message);
                    }
                }
            }}
            {...getOverrideProps(overrides, "PatientCreateForm")}
            {...rest}
        >
            <TextField
                label={
                    <span style={{ display: "inline-flex" }}>
            <span>Full Name</span>
            <span style={{ color: "red" }}>*</span>
          </span>
                }
                isRequired={true}
                isReadOnly={false}
                value={name}
                onChange={(e) => {
                    let { value } = e.target;
                    if (onChange) {
                        const modelFields = {
                            name: value,
                            email,
                            phone,
                            address,
                            dateOfBirth,
                            sex,
                            weight,
                        };
                        const result = onChange(modelFields);
                        value = result?.name ?? value;
                    }
                    if (errors.name?.hasError) {
                        runValidationTasks("name", value);
                    }
                    setName(value);
                }}
                onBlur={() => runValidationTasks("name", name)}
                errorMessage={errors.name?.errorMessage}
                hasError={errors.name?.hasError}
                {...getOverrideProps(overrides, "name")}
            ></TextField>
            <TextField
                label={
                    <span style={{ display: "inline-flex" }}>
            <span>Email</span>
            <span style={{ color: "red" }}>*</span>
          </span>
                }
                isRequired={true}
                isReadOnly={false}
                value={email}
                onChange={(e) => {
                    let { value } = e.target;
                    if (onChange) {
                        const modelFields = {
                            name,
                            email: value,
                            phone,
                            address,
                            dateOfBirth,
                            sex,
                            weight,
                        };
                        const result = onChange(modelFields);
                        value = result?.email ?? value;
                    }
                    if (errors.email?.hasError) {
                        runValidationTasks("email", value);
                    }
                    setEmail(value);
                }}
                onBlur={() => runValidationTasks("email", email)}
                errorMessage={errors.email?.errorMessage}
                hasError={errors.email?.hasError}
                {...getOverrideProps(overrides, "email")}
            ></TextField>
            <TextField
                label={
                    <span style={{ display: "inline-flex" }}>
            <span>Phone</span>
            <span style={{ color: "red" }}>*</span>
          </span>
                }
                isRequired={true}
                isReadOnly={false}
                type="tel"
                value={phone}
                onChange={(e) => {
                    let { value } = e.target;
                    if (onChange) {
                        const modelFields = {
                            name,
                            email,
                            phone: value,
                            address,
                            dateOfBirth,
                            sex,
                            weight,
                        };
                        const result = onChange(modelFields);
                        value = result?.phone ?? value;
                    }
                    if (errors.phone?.hasError) {
                        runValidationTasks("phone", value);
                    }
                    setPhone(value);
                }}
                onBlur={() => runValidationTasks("phone", phone)}
                errorMessage={errors.phone?.errorMessage}
                hasError={errors.phone?.hasError}
                {...getOverrideProps(overrides, "phone")}
            ></TextField>
            <TextField
                label="Address"
                isRequired={false}
                isReadOnly={false}
                value={address}
                onChange={(e) => {
                    let { value } = e.target;
                    if (onChange) {
                        const modelFields = {
                            name,
                            email,
                            phone,
                            address: value,
                            dateOfBirth,
                            sex,
                            weight,
                        };
                        const result = onChange(modelFields);
                        value = result?.address ?? value;
                    }
                    if (errors.address?.hasError) {
                        runValidationTasks("address", value);
                    }
                    setAddress(value);
                }}
                onBlur={() => runValidationTasks("address", address)}
                errorMessage={errors.address?.errorMessage}
                hasError={errors.address?.hasError}
                {...getOverrideProps(overrides, "address")}
            ></TextField>
            <TextField
                label={
                    <span style={{ display: "inline-flex" }}>
            <span>Date Of Birth</span>
            <span style={{ color: "red" }}>*</span>
          </span>
                }
                isRequired={true}
                isReadOnly={false}
                type="date"
                value={dateOfBirth}
                onChange={(e) => {
                    let { value } = e.target;
                    if (onChange) {
                        const modelFields = {
                            name,
                            email,
                            phone,
                            address,
                            dateOfBirth: value,
                            sex,
                            weight,
                        };
                        const result = onChange(modelFields);
                        value = result?.dateOfBirth ?? value;
                    }
                    if (errors.dateOfBirth?.hasError) {
                        runValidationTasks("dateOfBirth", value);
                    }
                    setDateOfBirth(value);
                }}
                onBlur={() => runValidationTasks("dateOfBirth", dateOfBirth)}
                errorMessage={errors.dateOfBirth?.errorMessage}
                hasError={errors.dateOfBirth?.hasError}
                {...getOverrideProps(overrides, "dateOfBirth")}
            ></TextField>
            <SelectField
                label="Sex"
                placeholder="Please select an option"
                isDisabled={false}
                value={sex}
                onChange={(e) => {
                    let { value } = e.target;
                    if (onChange) {
                        const modelFields = {
                            name,
                            email,
                            phone,
                            address,
                            dateOfBirth,
                            sex: value,
                            weight,
                        };
                        const result = onChange(modelFields);
                        value = result?.sex ?? value;
                    }
                    if (errors.sex?.hasError) {
                        runValidationTasks("sex", value);
                    }
                    setSex(value);
                }}
                onBlur={() => runValidationTasks("sex", sex)}
                errorMessage={errors.sex?.errorMessage}
                hasError={errors.sex?.hasError}
                {...getOverrideProps(overrides, "sex")}
            >
                <option
                    children="Male"
                    value="MALE"
                    {...getOverrideProps(overrides, "sexoption0")}
                ></option>
                <option
                    children="Female"
                    value="FEMALE"
                    {...getOverrideProps(overrides, "sexoption1")}
                ></option>
                <option
                    children="Other"
                    value="OTHER"
                    {...getOverrideProps(overrides, "sexoption2")}
                ></option>
            </SelectField>
            <TextField
                label="Weight (lbs)"
                isRequired={false}
                isReadOnly={false}
                type="number"
                step="any"
                value={weight}
                onChange={(e) => {
                    let value = isNaN(parseFloat(e.target.value))
                        ? e.target.value
                        : parseFloat(e.target.value);
                    if (onChange) {
                        const modelFields = {
                            name,
                            email,
                            phone,
                            address,
                            dateOfBirth,
                            sex,
                            weight: value,
                        };
                        const result = onChange(modelFields);
                        value = result?.weight ?? value;
                    }
                    if (errors.weight?.hasError) {
                        runValidationTasks("weight", value);
                    }
                    setWeight(value);
                }}
                onBlur={() => runValidationTasks("weight", weight)}
                errorMessage={errors.weight?.errorMessage}
                hasError={errors.weight?.hasError}
                {...getOverrideProps(overrides, "weight")}
            ></TextField>
            <Flex
                justifyContent="space-between"
                {...getOverrideProps(overrides, "CTAFlex")}
            >
                <Button
                    children="Clear"
                    type="reset"
                    onClick={(event) => {
                        event.preventDefault();
                        resetStateValues();
                    }}
                    {...getOverrideProps(overrides, "ClearButton")}
                ></Button>
                <Flex
                    gap="15px"
                    {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
                >
                    <button
                        className="continue-button"
                        children="Submit"
                        type="submit"
                        variation="primary"
                        isDisabled={Object.values(errors).some((e) => e?.hasError)}
                        {...getOverrideProps(overrides, "SubmitButton")}
                    ></button>
                </Flex>
            </Flex>
        </Grid>
    );
}