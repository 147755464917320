import { useState } from 'react';

const PainAssessmentForm = ({ onChange, patientDetails = {}, onSuccess }) => {
    const [currentStep, setCurrentStep] = useState(1);
    const totalSteps = 9;
    const [formState, setFormState] = useState({
        patientId: patientDetails?.id || '',
        patientName: patientDetails?.name || '',
        currentMedication: '',
        currentMedicationY: '',
        severityScore: '',
        onset: '',
        onsetOther: '',
        location: [],
        locationOther: '',
        bodySide: [],
        bodySideOther: '',
        presentingSymptoms: [],
        presentingSymptomsOther: '',
        healthHistory: [],
        allergies: [],
        redFlagSigns: [],
        redFlagSignsOther: '',
        additionalNotes: '',
    });

    const isValidText = text => typeof text === 'string' && text.trim() !== '';
    const hasSelection = (array) => array.length > 0;

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;

        switch (type) {
            case "checkbox":
                const newArray = formState[name].includes(value)
                    ? formState[name].filter(item => item !== value)
                    : [...formState[name], value];
                setFormState(prevState => ({ ...prevState, [name]: newArray }));
                break;
            case "radio":
            case "select-one":
            case "text":
            case "textarea":
                setFormState(prevState => ({ ...prevState, [name]: value }));
                break;
            default:
                setFormState(prevState => ({ ...prevState, [name]: checked }));
                break;
        }
        onChange && onChange(formState);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('Form Data:', formState);
        if (onSuccess) {
            onSuccess(formState);
        }
    };

    const nextStep = () => setCurrentStep(currentStep < totalSteps ? currentStep + 1 : currentStep);
    const prevStep = () => setCurrentStep(currentStep > 1 ? currentStep - 1 : currentStep);

    const isStepComplete = (step) => {
        switch (step) {
            case 1:
                return formState.currentMedication === 'No' || (formState.currentMedication === 'Yes' && isValidText(formState.currentMedicationY));
            case 2:
                return formState.healthHistory.length > 0;
            case 3:
                return formState.allergies.length > 0;
            case 4:
                return hasSelection(formState.redFlagSigns) && (formState.redFlagSigns.includes('OTHER') ? isValidText(formState.redFlagSignsOther) : true);
            case 5:
                return hasSelection(formState.presentingSymptoms) && (formState.presentingSymptoms.includes('OTHER') ? isValidText(formState.presentingSymptomsOther) : true);
            case 6:
                return formState.severityScore !== '';
            case 7:
                return hasSelection(formState.onset) && (formState.onset === 'OTHER' ? isValidText(formState.onsetOther) : true);
            case 8:
                return hasSelection(formState.location) && (formState.location.includes('OTHER') ? isValidText(formState.locationOther) : true);
            case 9:
                return hasSelection(formState.bodySide) && (formState.bodySide.includes('OTHER') ? isValidText(formState.bodySideOther) : true);
            default:
                return false;
        }
    };

    const CheckboxGroup = ({ title, name, options, otherOptionName }) => (
        <div className="space-y-4">
            <h2 className="text-xl font-medium text-gray-900">{title}</h2>
            <div className="space-y-3">
                {options.map(({ label, value }) => (
                    <label
                        key={value}
                        className={`
                            flex items-center p-3 rounded-lg border-2 cursor-pointer
                            ${formState[name].includes(value)
                                ? 'border-teal-600 bg-teal-50'
                                : 'border-gray-200 hover:bg-gray-50'
                            }
                        `}
                    >
                        <input
                            type="checkbox"
                            name={name}
                            value={value}
                            checked={formState[name].includes(value)}
                            onChange={handleInputChange}
                            className="sr-only"
                        />
                        <span className="text-lg">{label}</span>
                    </label>
                ))}
            </div>
            {(formState[name].includes('OTHER') || formState[name].includes('TRAUMATIC_EVENT')) && (
                <input
                    type="text"
                    name={otherOptionName}
                    value={formState[otherOptionName]}
                    onChange={handleInputChange}
                    placeholder="Please specify"
                    className="w-full p-3 mt-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                />
            )}
        </div>
    );

    const renderStepContent = (step) => {
        switch (step) {
            case 1:
                return (
                    <div className="space-y-4">
                        <h2 className="text-xl font-medium text-gray-900">Currently on medication?</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            {["Yes", "No"].map((option) => (
                                <label
                                    key={option}
                                    className={`
                                        flex items-center justify-center p-4 rounded-lg border-2 cursor-pointer
                                        ${formState.currentMedication === option 
                                            ? 'border-teal-600 bg-teal-50 text-teal-700' 
                                            : 'border-gray-200 hover:bg-gray-50'
                                        }
                                    `}
                                >
                                    <input
                                        type="radio"
                                        name="currentMedication"
                                        value={option}
                                        checked={formState.currentMedication === option}
                                        onChange={handleInputChange}
                                        className="sr-only"
                                    />
                                    <span className="text-lg">{option}</span>
                                </label>
                            ))}
                        </div>
                        {formState.currentMedication === 'Yes' && (
                            <input
                                type="text"
                                name="currentMedicationY"
                                value={formState.currentMedicationY}
                                onChange={handleInputChange}
                                placeholder="Specify medication"
                                className="w-full p-3 mt-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                            />
                        )}
                    </div>
                );
            case 2:
                return (
                    <CheckboxGroup
                        title="Health History (Select all that apply)"
                        name="healthHistory"
                        options={[
                            {label: 'Age 12 and under', value: 'AGE_12_AND_UNDER'},
                            {label: 'Pregnancy', value: 'PREGNANCY'},
                            {label: 'Breastfeeding', value: 'BREASTFEEDING'},
                            {label: 'Cardiovascular Disease', value: 'CARDIOVASCULAR_DISEASE'},
                            {label: 'Gastrointestinal Disorder', value: 'GASTROINTESTINAL_DISORDER'},
                            {label: 'Renal Impairment', value: 'RENAL_IMPAIRMENT'},
                            {label: 'Bleeding Disorder', value: 'BLEEDING_DISORDER'},
                            {label: 'Hepatotoxicity Risk', value: 'HEPATOTOXICITY_RISK'},
                            {label: 'None of the above', value: 'NONE'}
                        ]}
                        otherOptionName="healthHistoryOther"
                    />
                );
            case 3:
                return (
                    <CheckboxGroup
                        title="Allergies (Select all that apply)"
                        name="allergies"
                        options={[
                            {label: 'Pulmonary Condition (e.g., Asthma)', value: 'PULMONARY_CONDITION_EG_ASTHMA'},
                            {label: 'Allergic to ASA/NSAID', value: 'ANAPHYLAXIS_URTICARIA_ANGIOEDEMA_ASA_NSAID'},
                            {label: 'Allergic to Acetaminophen', value: 'ANAPHYLAXIS_URTICARIA_ANGIOEDEMA_ACETAMINOPHEN'},
                            {label: 'Not Applicable', value: 'NONE'}
                        ]}
                        otherOptionName="allergiesOther"
                    />
                );
            case 4:
                return (
                    <CheckboxGroup
                        title="Do you experience any of the following"
                        name="redFlagSigns"
                        options={[
                            {label: 'Obvious Fracture', value: 'OBVIOUS_FRACTURE'},
                            {label: 'Joint Deformity', value: 'JOINT_DEFORMITY'},
                            {label: 'Functional Impairment', value: 'FUNCTIONAL_IMPAIRMENT'},
                            {label: 'Muscle Deformity', value: 'MUSCLE_DEFORMITY'},
                            {label: 'Muscle Weakness', value: 'MUSCLE_WEAKNESS'},
                            {label: 'Physical Traumatic Event', value: 'TRAUMATIC_EVENT'},
                            {label: 'Neurovascular Compromise', value: 'NEUROVASCULAR_COMPROMISE'},
                            {label: 'Increasing Pain', value: 'INCREASING_PAIN'},
                            {label: 'Persistent Pain', value: 'PERSISTENT_PAIN'},
                            {label: 'Head Trauma', value: 'HEAD_TRAUMA'},
                            {label: 'Eye Injury', value: 'EYE_INJURY'},
                            {label: 'Nosebleed', value: 'NOSEBLEED'},
                            {label: 'Perforated Eardrum', value: 'PERFORATED_EARDRUM'},
                            {label: 'Fever, Nausea, or vomiting', value: 'SYSTEMIC_SIGNS'},
                            {label: 'Abdominal Pain', value: 'ABDOMINAL_PAIN'},
                            {label: 'None', value: 'NONE'},
                            {label: 'Other', value: 'OTHER'}
                        ]}
                        otherOptionName="redFlagSignsOther"
                    />
                );
            case 5:
                return (
                    <CheckboxGroup
                        title="What brings you here today?"
                        name="presentingSymptoms"
                        options={[
                            {label: 'Pain at Rest', value: 'PAIN_AT_REST'},
                            {label: 'Pain on Movement', value: 'PAIN_ON_MOVEMENT'},
                            {label: 'Pain on Touch', value: 'PAIN_ON_PALPATION'},
                            {label: 'Swelling', value: 'SWELLING'},
                            {label: 'Bruising', value: 'BRUISING'},
                            {label: 'Muscle Spasm', value: 'MUSCLE_SPASM'},
                            {label: 'Muscle Weakness', value: 'MUSCLE_WEAKNESS'},
                            {label: 'Joint Instability', value: 'JOINT_INSTABILITY'},
                            {label: 'Physical Traumatic Event', value: 'TRAUMATIC_EVENT'},
                            {label: 'None', value: 'NONE'},
                            {label: 'Other', value: 'OTHER'}
                        ]}
                        otherOptionName="presentingSymptomsOther"
                    />
                );
            case 6:
                return (
                    <div className="space-y-4">
                        <h2 className="text-xl font-medium text-gray-900">How Severe is the Pain?</h2>
                        <select
                            name="severityScore"
                            value={formState.severityScore}
                            onChange={handleInputChange}
                            className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                        >
                            <option value="">Select Severity</option>
                            <option value="NO_PAIN">No Pain</option>
                            <option value="MILD">Mild</option>
                            <option value="MODERATE">Moderate</option>
                            <option value="SEVERE">Severe</option>
                        </select>
                    </div>
                );
            case 7:
                return (
                    <div className="space-y-4">
                        <h2 className="text-xl font-medium text-gray-900">When did the Pain Start?</h2>
                        <select
                            name="onset"
                            value={formState.onset}
                            onChange={handleInputChange}
                            className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                        >
                            <option value="">Select Onset</option>
                            <option value="LESS_THAN_48_HOURS">Less than 48 hours</option>
                            <option value="TWO_TO_14_DAYS">Two to 14 days</option>
                            <option value="MORE_THAN_14_DAYS">More than 14 days</option>
                            <option value="OTHER">Other</option>
                        </select>
                        {formState.onset === 'OTHER' && (
                            <input
                                type="text"
                                name="onsetOther"
                                value={formState.onsetOther}
                                onChange={handleInputChange}
                                placeholder="Please specify"
                                className="w-full p-3 mt-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                            />
                        )}
                    </div>
                );
            case 8:
                return (
                    <CheckboxGroup
                        title="Pain Location"
                        name="location"
                        options={[
                            {label: 'Head', value: 'HEAD'},
                            {label: 'Neck', value: 'NECK'},
                            {label: 'Back', value: 'BACK'},
                            {label: 'Shoulder', value: 'SHOULDER'},
                            {label: 'Arm', value: 'ARM'},
                            {label: 'Hand', value: 'HAND'},
                            {label: 'Leg', value: 'LEG'},
                            {label: 'Foot', value: 'FOOT'},
                            {label: 'Other', value: 'OTHER'}
                        ]}
                        otherOptionName="locationOther"
                    />
                );
            case 9:
                return (
                    <div className="space-y-8">
                        <CheckboxGroup
                            title="Which side is the Pain"
                            name="bodySide"
                            options={[
                                {label: 'Left', value: 'LEFT'},
                                {label: 'Right', value: 'RIGHT'},
                                {label: 'Central', value: 'CENTRAL'},
                                {label: 'Other', value: 'OTHER'}
                            ]}
                            otherOptionName="bodySideOther"
                        />
                        
                        <div className="space-y-4">
                            <h2 className="text-xl font-medium text-gray-900">Additional Notes</h2>
                            <textarea
                                name="additionalNotes"
                                value={formState.additionalNotes}
                                onChange={handleInputChange}
                                placeholder="Anything you would like us to know"
                                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-transparent min-h-32"
                            />
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="max-w-2xl mx-auto p-6">
            <form onSubmit={handleSubmit} className="space-y-8">
                <div className="bg-white rounded-lg shadow-sm p-6">
                    {/* Progress bar */}
                    <div className="mb-8">
                        <div className="h-2 bg-gray-200 rounded">
                            <div 
                                className="h-full bg-teal-600 rounded transition-all duration-300 ease-in-out"
                                style={{ width: `${(currentStep / totalSteps) * 100}%` }}
                            />
                        </div>
                        <div className="mt-2 text-sm text-gray-600">
                            Step {currentStep} of {totalSteps}
                        </div>
                    </div>

                    {/* Form content */}
                    <div className="min-h-[400px]">
                        {renderStepContent(currentStep)}
                    </div>

                    {/* Navigation */}
                    <div className="flex justify-between mt-8 pt-4 border-t">
                        <button
                            type="button"
                            onClick={prevStep}
                            className={`
                                px-6 py-2 rounded-lg text-gray-600 hover:bg-gray-100
                                ${currentStep === 1 ? 'invisible' : ''}
                            `}
                        >
                            Back
                        </button>
                        
                        <button
                            type={currentStep === totalSteps ? 'submit' : 'button'}
                            onClick={currentStep === totalSteps ? undefined : nextStep}
                            disabled={!isStepComplete(currentStep)}
                            className={`
                                px-6 py-2 rounded-lg text-white
                                ${isStepComplete(currentStep)
                                    ? 'bg-teal-600 hover:bg-teal-700'
                                    : 'bg-gray-300 cursor-not-allowed'
                                }
                            `}
                        >
                            {currentStep === totalSteps ? 'Submit' : 'Continue'}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default PainAssessmentForm;