// import React, { useState, useEffect } from 'react';
// import { motion, AnimatePresence } from 'framer-motion';
// import { 
//   CalendarX2, 
//   Globe2, 
//   Truck, 
//   MessageSquareMore, 
//   Package, 
//   UserRound, 
//   Stethoscope,
//   ArrowLeft,
//   ArrowRight
// } from 'lucide-react';

// const benefits = [
//   {
//     icon: CalendarX2,
//     text: "Free cancellation anytime",
//   },
//   {
//     icon: Globe2,
//     text: "100% online treatments",
//   },
//   {
//     icon: Truck,
//     text: "Free shipping on all plans",
//   },
//   {
//     icon: MessageSquareMore,
//     text: "Free unlimited follow-ups",
//   },
//   {
//     icon: Package,
//     text: "Discreet, unmarked packaging",
//   },
//   {
//     icon: UserRound,
//     text: "Personalized treatment for every patient",
//   },
//   {
//     icon: Stethoscope,
//     text: "Developed by medical specialists",
//   },
// ];

// export const BenefitsSlider = () => {
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const [direction, setDirection] = useState(0);
//   const itemsToShow = 3;

//   const nextSlide = () => {
//     setDirection(1);
//     setCurrentIndex((prevIndex) => 
//       prevIndex + itemsToShow >= benefits.length ? 0 : prevIndex + 1
//     );
//   };

//   const prevSlide = () => {
//     setDirection(-1);
//     setCurrentIndex((prevIndex) => 
//       prevIndex === 0 ? benefits.length - itemsToShow : prevIndex - 1
//     );
//   };

//   useEffect(() => {
//     const timer = setInterval(() => {
//       nextSlide();
//     }, 5000);

//     return () => clearInterval(timer);
//   }, [currentIndex]);

//   const visibleBenefits = benefits.slice(currentIndex, currentIndex + itemsToShow);
//   if (visibleBenefits.length < itemsToShow) {
//     visibleBenefits.push(...benefits.slice(0, itemsToShow - visibleBenefits.length));
//   }

//   return (
//     <div className="bg-white py-12">
//       <div className="max-w-6xl mx-auto px-4">
//         <div className="relative">
//           <div className="overflow-hidden">
//             <div className="flex justify-between items-center">
//               <button 
//                 onClick={prevSlide}
//                 className="p-2 rounded-full bg-teal-500 text-white hover:bg-teal-600 transition-colors"
//               >
//                 <ArrowLeft size={24} />
//               </button>

//               <div className="flex-1 mx-4">
//                 <AnimatePresence initial={false} mode="wait">
//                   <motion.div 
//                     className="flex justify-around items-center"
//                     key={currentIndex}
//                     initial={{ x: direction > 0 ? 1000 : -1000, opacity: 0 }}
//                     animate={{ x: 0, opacity: 1 }}
//                     exit={{ x: direction > 0 ? -1000 : 1000, opacity: 0 }}
//                     transition={{ duration: 0.5 }}
//                   >
//                     {visibleBenefits.map((benefit, index) => {
//                       const Icon = benefit.icon;
//                       return (
//                         <div 
//                           key={index} 
//                           className="flex flex-col items-center text-center px-4"
//                         >
//                           <div className="w-16 h-16 rounded-full bg-teal-100 flex items-center justify-center mb-4">
//                             <Icon size={32} className="text-teal-600" />
//                           </div>
//                           <p className="text-gray-800 font-medium">{benefit.text}</p>
//                         </div>
//                       );
//                     })}
//                   </motion.div>
//                 </AnimatePresence>
//               </div>

//               <button 
//                 onClick={nextSlide}
//                 className="p-2 rounded-full bg-teal-500 text-white hover:bg-teal-600 transition-colors"
//               >
//                 <ArrowRight size={24} />
//               </button>
//             </div>
//           </div>

//           <div className="flex justify-center mt-6 space-x-2">
//             {Array.from({ length: Math.ceil(benefits.length / itemsToShow) }).map((_, idx) => (
//               <div
//                 key={idx}
//                 className={`h-2 w-2 rounded-full transition-colors duration-300 ${
//                   Math.floor(currentIndex / itemsToShow) === idx 
//                     ? 'bg-teal-500' 
//                     : 'bg-gray-300'
//                 }`}
//               />
//             ))}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default BenefitsSlider;

import React, { useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';
import { 
  CalendarX2, 
  Globe2, 
  Truck, 
  MessageSquareMore, 
  Package, 
  UserRound, 
  Stethoscope
} from 'lucide-react';

const benefits = [
  {
    icon: CalendarX2,
    text: "Free cancellation anytime",
  },
  {
    icon: Globe2,
    text: "100% online treatments",
  },
  {
    icon: Truck,
    text: "Free shipping on all plans",
  },
  {
    icon: MessageSquareMore,
    text: "Free unlimited follow-ups",
  },
  {
    icon: Package,
    text: "Discreet, unmarked packaging",
  },
  {
    icon: UserRound,
    text: "Personalized treatment for every patient",
  },
  {
    icon: Stethoscope,
    text: "Developed by medical specialists",
  },
];

export const BenefitsSlider = () => {
  const [duplicatedBenefits, setDuplicatedBenefits] = useState([]);
  const containerRef = useRef(null);

  useEffect(() => {
    // Duplicate the benefits array to create a seamless loop
    setDuplicatedBenefits([...benefits, ...benefits, ...benefits]);
  }, []);

  // const BenefitItem = ({ icon: Icon, text }) => (
  //   <div className="flex flex-col  items-center text-center px-8 min-w-64">
  //     {/* <div className="w-16 h-16 rounded-full bg-teal-100 flex items-center justify-center mb-4"> */}
  //       <Icon size={32} className="text-teal-600 mb-4" />
  //     {/* </div> */}
  //     <p className="text-gray-800 font-medium">{text}</p>
  //   </div>
  // );
  const BenefitItem = ({ icon: Icon, text }) => (
    <div className="flex flex-col items-center text-center px-8 min-w-64">
      <Icon 
        size={32} 
        className="text-teal-600 mb-4 drop-shadow-sm" // Added shadow to icon
      />
      <p className="text-gray-800 font-medium drop-shadow-sm"> {/* Added shadow to text */}
        {text}
      </p>
    </div>
  );

  return (
    <div className="bg-gray-50 py-12 overflow-hidden">
      <div className="max-w-full">
        <motion.div
          ref={containerRef}
          className="flex"
          animate={{
            x: ["0%", "-100%"]
          }}
          transition={{
            x: {
              repeat: Infinity,
              repeatType: "loop",
              duration: 30,
              ease: "linear",
            },
          }}
        >
          {duplicatedBenefits.map((benefit, index) => (
            <BenefitItem
              key={index}
              icon={benefit.icon}
              text={benefit.text}
            />
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default BenefitsSlider;