import React, { useState, useEffect } from 'react';
import { DataStore } from 'aws-amplify/datastore';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { PrescriptionRenewal, Patient } from '../../models';
import { downloadData } from 'aws-amplify/storage';

const PrescriptionManagement = () => {
  const [renewals, setRenewals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuthenticator((context) => [context.user]);
  const [expandedRequest, setExpandedRequest] = useState(null);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('PENDING_REVIEW');

  useEffect(() => {
    if (user) {
      fetchRenewals();
    }
  }, [user, selectedFilter]);

  const fetchRenewals = async () => {
    try {
      setLoading(true);
      // Query renewals with the selected status filter
      const renewalRequests = await DataStore.query(PrescriptionRenewal, (r) => 
        r.status.eq(selectedFilter)
      );

      // Get patient details for each renewal
      const renewalsWithPatients = await Promise.all(
        renewalRequests.map(async (renewal) => {
          const patient = await DataStore.query(Patient, renewal.patientId);
          return { ...renewal, patient };
        })
      );

      setRenewals(renewalsWithPatients);
    } catch (err) {
      console.error('Error fetching renewals:', err);
      setError('Failed to load renewal requests');
    } finally {
      setLoading(false);
    }
  };

  const handleStatusUpdate = async (renewalId, newStatus) => {
    try {
      setUpdateLoading(true);
      const original = await DataStore.query(PrescriptionRenewal, renewalId);
      
      if (!original) throw new Error('Renewal request not found');

      await DataStore.save(
        PrescriptionRenewal.copyOf(original, updated => {
          updated.status = newStatus;
          updated.reviewedBy = user.userId;
          updated.reviewedAt = new Date().toISOString();
        })
      );

      // Refresh the list
      await fetchRenewals();
      setExpandedRequest(null);
    } catch (err) {
      console.error('Error updating status:', err);
      setError('Failed to update status');
    } finally {
      setUpdateLoading(false);
    }
  };

const handleDownload = async (fileKey) => {
    try {
      if (!fileKey) {
        setError('No prescription file found');
        return;
      }
  
      console.log('Attempting to download file:', fileKey);
  
      const result = await downloadData({
        path: fileKey
      }).result;
  
      console.log('Download result:', result);
  
      if (!result?.body) {
        throw new Error('No data received from download');
      }
  
      // Create blob from the response body
      const blob = new Blob([result.body], { type: result.contentType });
      const url = URL.createObjectURL(blob);
      
      // Create and trigger download
      const a = document.createElement('a');
      a.href = url;
      a.download = fileKey.split('/').pop();
      document.body.appendChild(a);
      a.click();
      
      // Cleanup
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
  
    } catch (err) {
      console.error('Error downloading file:', err);
      setError(err.message || 'Failed to download file');
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-teal-700"></div>
      </div>
    );
  }

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-semibold text-gray-900">
            Prescription Renewal Requests
          </h1>
          
          {/* Status Filter */}
          <select
            value={selectedFilter}
            onChange={(e) => setSelectedFilter(e.target.value)}
            className="bg-white border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-teal-500"
          >
            <option value="PENDING_REVIEW">Pending Review</option>
            <option value="APPROVED">Approved</option>
            <option value="DENIED">Denied</option>
          </select>
        </div>

        {error && (
          <div className="mb-4 bg-red-50 text-red-700 p-4 rounded-md">
            {error}
          </div>
        )}

        <div className="bg-white shadow rounded-lg">
          {renewals.length === 0 ? (
            <div className="p-6 text-center text-gray-500">
              No {selectedFilter.toLowerCase().replace('_', ' ')} requests found
            </div>
          ) : (
            <ul className="divide-y divide-gray-200">
              {renewals.map((renewal) => (
                <li 
                  key={renewal.id}
                  className={`p-6 ${
                    expandedRequest === renewal.id ? 'bg-gray-50' : ''
                  }`}
                >
                  <div className="flex justify-between items-start">
                    <div>
                      <h3 className="text-lg font-medium text-gray-900">
                        {renewal.patient?.name || 'Unknown Patient'}
                      </h3>
                      <p className="text-sm text-gray-500">
                        Submitted {formatDate(renewal.createdAt)}
                      </p>
                    </div>
                    <button
                      onClick={() => setExpandedRequest(
                        expandedRequest === renewal.id ? null : renewal.id
                      )}
                      className="text-teal-600 hover:text-teal-800"
                    >
                      {expandedRequest === renewal.id ? 'Show Less' : 'View Details'}
                    </button>
                  </div>

                  {expandedRequest === renewal.id && (
                    <div className="mt-4 space-y-4">
                      <div className="bg-gray-50 p-4 rounded-md">
                        <h4 className="text-sm font-medium text-gray-900 mb-2">
                          Patient Information
                        </h4>
                        <dl className="grid grid-cols-1 gap-x-4 gap-y-2 sm:grid-cols-2">
                          <div>
                            <dt className="text-sm font-medium text-gray-500">Email</dt>
                            <dd className="text-sm text-gray-900">
                              {renewal.patient?.email}
                            </dd>
                          </div>
                          <div>
                            <dt className="text-sm font-medium text-gray-500">Phone</dt>
                            <dd className="text-sm text-gray-900">
                              {renewal.patient?.phone}
                            </dd>
                          </div>
                        </dl>
                      </div>

                      <div>
                        <h4 className="text-sm font-medium text-gray-900 mb-2">
                          Additional Notes
                        </h4>
                        <p className="text-sm text-gray-600">
                          {renewal.notes || 'No additional notes provided'}
                        </p>
                      </div>

                      <div className="flex items-center space-x-4">
                        <button
                          onClick={() => handleDownload(renewal.prescriptionFile)}
                          className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                        >
                          View Prescription
                        </button>

                        {renewal.status === 'PENDING_REVIEW' && (
                          <div className="flex space-x-4">
                            <button
                              onClick={() => handleStatusUpdate(renewal.id, 'APPROVED')}
                              disabled={updateLoading}
                              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 disabled:opacity-50"
                            >
                              Approve
                            </button>
                            <button
                              onClick={() => handleStatusUpdate(renewal.id, 'DENIED')}
                              disabled={updateLoading}
                              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50"
                            >
                              Decline
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default PrescriptionManagement;